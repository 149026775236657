import React from 'react';
import {Route} from "react-router-dom";
import firebase from "../home";
import {Translate} from "react-localize-redux";

class Reason extends React.Component {


    componentDidMount() {

        if (!window.ScrollMagic) return;

        try {

            new window.ScrollMagic.Scene({triggerElement: "#parallax2"})
                .setTween("#parallax2 > div", {y: "40%"}) //, ease: Linear.easeNone
                .addTo(new window.ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: "onEnter",
                        duration: "200%"
                    }
                }));

            new window.ScrollMagic.Scene({triggerElement: "#parallax3"})
                .setTween("#parallax3 > div", {y: "40%"}) //, ease: Linear.easeNone
                .addTo(new window.ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: "onEnter",
                        duration: "200%"
                    }
                }));

        } catch {}

    }

    render() {
        return (
            <div className="ui container">

                <div className="ui vertical stripe padded segment first colored-background-alternative">
                    <div className="ui top aligned stackable grid container">
                        <div className="row">
                            <div className="sixteen wide column">
                                <h1><Translate id="reason.title">Why did we want to sail to the COP?</Translate></h1>
                            </div>
                        </div>
                        <div className="row centered">
                            <div className="fourteen wide column left aligned">
                                <Translate id="reason.first-text" options={{renderInnerHtml: true}}>
                                    <p>
                                    With a diverse group of changemakers we will travel to the UN Climate Conference
                                    COP25
                                     this fall. <strong>Our main reason for going is that we are very
                                    worried
                                    about the
                                    emissions of aviation - that is why we will not travel by plane but by sailing
                                    ship.</strong>
                                </p>
                                <p>
                                    At the conference and throughout our trip, we want to raise our concerns about
                                    the
                                    negative impact of air travel on our planet. The sail trip is part of a year
                                    long
                                    (media) campaign for policy change at national and international level. The aim
                                    is to
                                    make the transport playing field more equal. We want to make it easier, more
                                    affordable
                                    and more attractive to choose sustainable travel options like going by train,
                                    bus and
                                    bike.
                                </p>
                                <p>
                                    Changing the transport system is a complex transition that involves different
                                    stakeholders such as train companies, NGOs, governments and policymakers. This
                                    is why we
                                    want to use the time on board to do a think tank - read more about the think
                                    tank here.
                                </p>
                                </Translate>
                            </div>
                        </div>
                        <div className="row">
                            <div className="sixteen wide column">
                                <h1><Translate id="reason.flying-title">Flying is destroying our planet...</Translate></h1>

                                <div className="ui center aligned stackable grid divided">
                                    <div className="row">

                                        <div className="seven wide column large">
                                            <p>
                                                <img src="resources/images/Emissions-modalities.png"
                                                     className="ui image"
                                                     style={{width: '400px', maxWidth: '100%'}}/>
                                                <small className="source">Source: <a href="https://decorrespondent.nl/8368/toen-ik-deze-cijfers-onder-ogen-zag-besloot-ik-veel-minder-te-vliegen-en-jij-misschien-ook/922228912-0226280f" target="_blank">De Correspondent</a>, data: CVO, 2016</small>
                                            </p><br />
                                            <p>
                                                <Translate id="reason.impact-text" options={{renderInnerHtml: true}}>One return flight from Amsterdam to Santiago de Chile emits 3.800 kg
                                                CO<sub>2</sub> — a similar amount of CO<sub>2</sub> saved by eating
                                                vegetarian
                                                    for more then 8 years.</Translate>
                                            </p>
                                            <small className="source">Source: <a href="https://www.milieucentraal.nl/klimaat-en-aarde/klimaatklappers/bewust-eten/" target="_blank">Milieu Centraal</a></small>
                                        </div>

                                        <div className="seven wide column large">
                                            <p>
                                                <img src="resources/images/Global-travel-emissions-Paris.png"
                                                     className="ui image"
                                                     style={{width: '400px', maxWidth: '100%'}}/>
                                                <small className="source">Source: <a href="https://repository.tudelft.nl/islandora/object/uuid%3A615ac06e-d389-4c6c-810e-7a4ab5818e8d" target="_blank">Peeters, P. (2017)</a></small>
                                            </p><br />
                                            <p>
                                                <Translate id="reason.projection-text" options={{renderInnerHtml: true}}>Air Travel is projected to double in the next 20 years. The
                                                emissions of the
                                                travel industry alone are expected to exceed the complete
                                                global
                                                CO<sub>2</sub>&nbsp;
                                                budget to limit global warming to 1.5° &mdash; around 2050, if the
                                                aviation
                                                industry
                                                continues business as usual.
                                                </Translate>
                                            </p>
                                            <small className="source">Source: <a href="https://www.iata.org/pressroom/pr/Pages/2018-10-24-02.aspx" target="_blank">IATA</a></small>
                                        </div>

                                    </div>
                                    <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"
                                         style={{marginTop: '0px'}}/>
                                    <div className="row">
                                        <div className="five wide column large">
                                            <p>
                                                <Translate id="reason.tax-text">Aviation is exempt from many taxes, like VAT and fuel tax. In Europe
                                                alone
                                                this
                                                    leads to a shortfall of €49 billion a year.</Translate>
                                            </p>
                                            <small className="source">Source: <a href="https://www.transportenvironment.org/what-we-do/aviation-and-eu-ets" target="_blank">Transport & Environment</a></small>
                                        </div>
                                        <div className="five wide column large">
                                            <p>
                                                <Translate id="reason.population-text"options={{renderInnerHtml: true}}>It's estimated that up to now only 18% of the world population has
                                                ever set
                                                foot on an airplane. While most of these (frequent) flyers have the
                                                money
                                                and time to adapt to the effects of climate change, many of the 82%
                                                of
                                                people that have never flown are more likely to have their
                                                livelihoods
                                                    impacted by the effects of climate change.</Translate>
                                            </p>
                                            <small className="source">Source: <a href="https://www.dw.com/en/to-fly-or-not-to-fly-the-environmental-cost-of-air-travel/a-42090155" target="_blank">DW</a></small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="ui inverted vertical center aligned segment parallax parallax2" id="parallax2">

                    <div className="background"></div>
                    <div className="parallax-content">


                    </div>

                </div>


                <div className="ui vertical stripe segment padded">
                    <div className="ui top aligned stackable grid container centered">
                        <div className="row">

                            <h1><Translate id="reason.solutions-title">Global solutions are needed for a future-proof travel industry</Translate></h1>


                            <div className="ui center aligned stackable grid divided">
                                <div className="row">
                                    <div className="eight wide column large">

                                        <img src="resources/images/np_world_11354_000000.png" alt="Airplane"
                                             style={{height: '130px', maxWidth: '100%'}}/>

                                        <Translate id="reason.solutions-first-text" options={{renderInnerHtml: true}}>
                                        <p>
                                            International collaboration and agreements can stimulate a shift towards
                                            sustainable ways of transport.
                                        </p>
                                        <p>
                                            We will cooperate with existing initiatives and relevant organizations
                                            to
                                            strengthen our shared message and bring the issue to the international
                                            political
                                            agenda at COP25.
                                        </p>
                                        </Translate>
                                    </div>
                                    <div className="eight wide column large">
                                        <img src="resources/images/Cop25.jpg" className="image" alt="Train"
                                             style={{height: '130px', maxWidth: '100%'}}/>
                                        <Translate id="reason.solutions-second-text" options={{renderInnerHtml: true}}>
                                        <p>
                                            The UN climate conference (COP) is the appropriate platform to call for
                                            the
                                            required policies and showcase promising initiatives.
                                        </p>
                                        <p>
                                            COP25 will take place in Santiago Chile from 2 - 13 December 2019
                                        </p>
                                        </Translate>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="ui inverted vertical center aligned segment parallax parallax3" id="parallax3">

                    <div className="background"></div>
                    <div className="parallax-content">


                    </div>

                </div>

                <div className="ui vertical stripe segment padded demands colored-background-alternative">
                    <div className="ui top aligned stackable grid container centered">
                        <div className="row">
                            <div className="sixteen wide column" style={{textAlign: 'center'}}>
                                <h1 style={{fontSize: '30pt'}}>
                                    <Translate id="reason.sail-title">We will sail to COP25</Translate>
                                </h1>
                                <h2>
                                    <Translate id="reason.to-call-for">To call for</Translate>
                                </h2>

                                <div className="ui center aligned stackable grid divided">
                                    <div className="row">
                                        <div className="seven wide column large">

                                            <img src="resources/images/np_airplane_251783_000000.png"
                                                 className="icon"
                                                 alt="Airplane"/>

                                            <h3><Translate id="reason.fair-title">Fair taxation of aviation</Translate></h3>
                                        </div>
                                        <div className="seven wide column large left aligned">
                                            <p><Translate id="reason.fair-text">
                                                In the 1992 United Nations Framework Convention on Climate Change,
                                                the
                                                participating countries exempted international air travel from
                                                regulation.
                                                This exemption was upheld ever since. The only major government to
                                                act on
                                                air travel emissions since has been the European Union, which tried
                                                to
                                                institute a carbon tax on intercontinental flights in 2012.
                                                Right now there is still no kerosine tax, and the new climate strategy
                                                of the aviation industry (CORSIA) makes no significant changes.</Translate>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row"></div>

                                    <div className="row">
                                        <div className="seven wide column large">
                                            <img src="resources/images/np_train_1092346_000000.png" className="icon"
                                                 alt="Train"/>
                                            <h3><Translate id="reason.promotion-title">Promotion of sustainable ways of traveling</Translate></h3>
                                        </div>
                                        <div className="seven wide column large left aligned">
                                            <p><Translate id="reason.promotion-text">
                                                Why fly when you can go by train, boat, bus or bike? While aviation
                                                is
                                                exempted from taxation, more sustainable modes of transport do pay
                                                taxes.
                                                This creates an unfair playing field: how can sustainable
                                                alternatives ever
                                                compete with the heavily subsided flying? That is why we want to
                                                create an
                                                equal playing field and promote sustainable ways of traveling.
                                                Changing the
                                                system so that individuals will make a more sustainable choice.</Translate>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row"></div>

                                    <div className="row">
                                        <div className="seven wide column large">
                                            <img src="resources/images/np_networking_2426775_000000.png"
                                                 className="icon"
                                                 alt="Network"
                                                 style={{height: '80px', marginBottom: '20px'}}/>
                                            <h3><Translate id="reason.network-title">Building an international network</Translate></h3>
                                        </div>
                                        <div className="seven wide column large left aligned">
                                            <p>
                                                <Translate id="reason.network-text" options={{renderInnerHtml: true}}>
                                                Sail to the COP is the first project in our effort to build an
                                                international
                                                network that aims at making the travel industry climate positive.
                                                Through
                                                projects we’re connecting people with different backgrounds to share
                                                expertise and to campaign for sustainability in the travel industry.
                                                In
                                                these efforts we will be working together a growing movement and
                                                network of
                                                local groups fighting against the construction of new airports and
                                                for
                                                sustainable alternatives. Read more about this global network at <a
                                                    href="https://stay-grounded.org" target="_blank">Stay Grounded</a>.</Translate>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reason;

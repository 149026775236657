import React from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import {Button, Card, Embed, Header, Image, Popup, Tab} from "semantic-ui-react";
import {solutions} from "./solutions-data";
import {opportunities} from "./opportunities-data";

let categoryColors = {
    Education: "#e4b89a",
    Innovation: "#aac1d9",
    Policy: "#eed9a4",
    Media: "#0fbbd4",
    "Climate Justice": "#c0d5b2",
    Other: "#a0a0a0",
};

let demands = [
    {
        title: "Aviation in line with the Paris Agreement",
        text: "It is crucial that the aviation industry is in line with the Paris Agreement and, like other contributors, should be equally held responsible for its global warming impact.",
        image: "airplane.jpg",
    },
    {
        title: "Nationally Determined Contributions",
        text: "All NDCs should include specific and ambitious travel emission reduction targets, especially in terms of domestic and international aviation. This implies facilitating a modal shift to sustainable alternatives, such as buses and trains.",
        image: "agreement.jpg",
    },
    {
        title: "Taxes I",
        text: "Socially just ticket taxes should be enforced on all flights. For instance, in the form of a frequent flyer levy to disincentivise excessive flight behaviour.",
        image: "tickets.jpg",
    },
    {
        title: "taxes II",
        text: "A kerosene tax should be implemented for the aviation industry, with the tax revenue allocated to sustainable transport in order to make alternatives a feasible option for travellers.",
        image: "fuel.jpg",
    },
    {
        title: "State aid",
        text: "An immediate and complete ban on state aid for the aviation industry and an incremental withdrawal of subsidies to the aviation sector until 2030 should be enforced.",
        image: "money.jpg",
    },
    {
        title: "International Civil Aviation Organisation",
        text: "ICAO should install robust carbon reduction targets in CORSIA and ensure that there is no double counting of carbon credits.",
        image: "agreement-2.jpg",
    },
    {
        title: "Flight Limit",
        text: "Flights within Europe should be limited until emissions are drastically reduced; all flights to destinations reachable by train within 6.5 travel hours should be banned and saved subsidies should be used to make trains more affordable.",
        image: "target.jpg",
    },
    {
        title: "Long Distance Night Travel",
        text: "A European alliance on long distance and night travel should be formed so that corridors can  be  approached  as international  ground,  improving connections between (night) buses and trains. ",
        image: "moon.jpg",
    },
    {
        title: "European Trading System",
        text: "The EU ETS should be reformed to ensure effective emission reduction and an inclusion of all European aviation emissions within the ETS.",
        image: "agreement-2.jpg",
    },
    {
        title: "Advertisement",
        text: "Advertisement for the aviation industry (online and offline) should be restricted.",
        image: "advertisement.jpg",
    },
    {
        title: "Data sharing",
        text: "EU-level policy on data sharing across all stakeholders to enable multimodal pan-European booking systems.",
        image: "train.jpg",
    },
];

let opportunityTabs = opportunities.map(category => {
    return {
        menuItem: category.title,
        render: () => <Tab.Pane attached={false}>
            <h2>{category.title}</h2>

            <ul>
                {category.opportunities.map(opportunity => <li>
                    {opportunity.text}
                </li>)}
            </ul>
        </Tab.Pane>,
    }
})

class Output extends React.Component {

    componentDidMount() {

        if (!window.ScrollMagic) return;

        try {

            new window.ScrollMagic.Scene({triggerElement: "#parallax6"})
                .setTween("#parallax6 > div", {y: "40%"}) //, ease: Linear.easeNone
                .addTo(new window.ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: "onEnter",
                        duration: "200%"
                    }
                }));
            new window.ScrollMagic.Scene({triggerElement: "#parallax7"})
                .setTween("#parallax7 > div", {y: "40%"}) //, ease: Linear.easeNone
                .addTo(new window.ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: "onEnter",
                        duration: "200%"
                    }
                }));


        } catch (e) {

        }

    }


    render() {
        return <>
            {/*<div className="menu-container">*/}
            {/*    <div className="logo">*/}
            {/*        <NavLink to="/">*/}
            {/*            <img src="resources/images/logo-sailtocop-white.png" alt="Sail to the COP"/>*/}
            {/*        </NavLink>*/}
            {/*    </div>*/}


            {/*    <ul className="menu actions">*/}
            {/*        <li>*/}
            {/*            <NavLink to="/project">Vision</NavLink>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <NavLink to="/railtothecop">The Problem</NavLink>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <NavLink to="/support">Opportunities</NavLink>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <NavLink to="/partners">Solutions</NavLink>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <NavLink to="/blog">Sail to the COP</NavLink>*/}
            {/*        </li>*/}
            {/*    </ul>*/}


            {/*</div>*/}

            <div className="output summary">

                <div className="ui vertical stripe padded first fullHeight vision">
                    <div className="ui top stackable grid container" style={{minHeight: '100%'}}>
                        <div className="row">
                            <img src="/resources/images/output/sun.jpg" className="sun"/>
                            <div className="column ten wide">

                                <br/><br/>

                                <h1 className="small">Our vision</h1>

                                <div className="huge">
                                    Fair and sustainable travel
                                </div>


                                <div className="quote">
                                    We imagine a
                                    future where people travel with respect for our earth and all those who live on it —
                                    supported by ambitious travel policies, forward thinking institutions and a culture
                                    of conscious travel.
                                </div>

                                <Link to="/thinktank/vision"><Button primary>Learn more</Button></Link>

                                <br/>
                            </div>

                            <div className="column six wide right-column">
                                <div className="space">&nbsp;</div>
                                <div className="onepager">
                                    <h3>Read our report</h3>
                                    <a href="https://www.sailtothecop.com/report" target="_blank">
                                        <Button primary>Download report</Button>
                                    </a><br />
                                    <a href="https://www.sailtothecop.com/executive-summary" target="_blank">
                                        <Button primary style={{marginTop: '6px'}}>Executive Summary</Button>
                                    </a>
                                    <h3>Read our demands for COP25</h3>
                                    <Link to="/resources/SailtotheCOP_onepager_20191203.pdf" target="_blank">
                                        <Button primary>Download onepager</Button>
                                    </Link>
                                </div>
                            </div>

                            {/*<div className="column six wide video mobile hidden">*/}

                            {/*    <Embed id='8vqwzwazX-s' allowfullscreen*/}
                            {/*           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                            {/*           placeholder='/resources/images/Sail to the COP Participants Video 2.jpg'*/}
                            {/*           source='youtube'/>*/}

                            {/*</div>*/}


                        </div>

                    </div>

                </div>

                <br /><br /><br />

                <div className="ui top center aligned stackable grid container centered part">
                    <div className="row">
                        <div className="column ten wide center aligned left-column">

                            <h1 className="small">The problem</h1>

                            <h1>Flying is destroying our
                                planet...</h1>

                            <p>
                                The current travel sector is unsustainable and unfair in multiple ways. It is
                                unsustainable due to the focus and reliance on aviation; an extremely
                                environmentally harmful mode of transport. Aviation has undergone fast pace
                                growth, despite becoming much more efficient and technologically advanced, it
                                has doubled its emissions in 20 years.</p>
                            <p>
                                Within the next 20 years the industry is expected to again double, significantly
                                affecting all life on earth – as this growth cannot occur while attempting to
                                limit global warming to 1.5° C. Shockingly, these facts are hardly included when
                                discussing aviation, not in political or societal debate or in portrayal in the
                                media. Perhaps more importantly, the travel sector itself is not transparent in
                                its communication about emissions.
                            </p>

                            <Link to="/thinktank/problem"><Button primary>Learn more</Button></Link>

                            <br/><br/>
                            <br/><br/>
                            <br/><br/>

                        </div>

                        <div className="six wide column" style={{paddingTop: '160px'}}>

                            <Image src={"/resources/images/output/planeemit.jpg"} size="medium" floated="right"/>


                        </div>

                    </div>


                    {/*<div className="row">*/}
                    {/*    <Image src={"/resources/images/output/scribble-1.jpg"}/>*/}
                    {/*</div>*/}

                </div>


                <div className="ui inverted vertical center aligned segment parallax parallax6" id="parallax6">
                    <div className="background"></div>
                    <div className="parallax-content"></div>
                </div>


                <div className="ui top center aligned stackable grid container centered part">
                    <div className="row">


                        <div className="six wide column" style={{paddingTop: '160px'}}>

                            <div className="bordered-image" style={{
                                width: '100%',
                                backgroundImage: "url('/resources/images/output/DSC05742.jpg')"
                            }}>
                                <Image src={"/resources/images/output/image-cutout-4.png"}/>
                            </div>

                        </div>

                        <div className="column ten wide center aligned left-column">

                            <h1 className="small">Opportunities</h1>

                            <h1>The opportunities we identified</h1>

                            <p>
                                The first two parts of the think tank were mainly about problem analysis and vision
                                forming. In the third part the results of the problems analysis were combined with the
                                outcomes of our vision exercise which led to multiple interesting opportunities.
                            </p>
                            <p>
                                The opportunities were obtained as a result of projecting elements of our vision on the
                                problem analysis. We asked ourselves the question: Where do we see opportunities for
                                change? This led to over forty interesting opportunity directions, which are all
                                presented in this chapter. Since the problems we discussed were on different levels and
                                formed visions as well, the opportunities also range from rather abstract ones to very
                                concrete solution directions. Due to limited time and resources it was decided to not
                                follow up on all forty of those opportunities. After several rounds of voting and
                                stickering these forty opportunity directions were narrowed down to twenty directions to
                                pursue further.
                            </p>

                        </div>


                    </div>

                </div>
                <div className="ui container">
                    <div className="ui grid celled">

                        <div className="row opportunities centered">

                            <div className="column fourteen wide">

                                <Tab menu={{secondary: true}} panes={opportunityTabs}/>

                            </div>
                            {/*{opportunities.map(category => <div className="column four wide">*/}
                            {/*    <h2>{category.title}</h2>*/}

                            {/*    <ul>*/}
                            {/*        {category.opportunities.map(opportunity => <li>*/}
                            {/*            {opportunity.text}*/}
                            {/*        </li>)}*/}
                            {/*    </ul>*/}
                            {/*</div>)}*/}

                        </div>

                    </div>
                </div>


                {/*<Image src={"/resources/images/output/scribble-1.jpg"}/>*/}


                <div className="ui top center aligned stackable grid container centered part">
                    <div className="row">
                        <div className="column ten wide center aligned left-column">

                            <h1 className="small">Solutions</h1>
                            <p>
                                From the opportunities phase, we dove into our solution-based output phase. For this, we
                                created think-tank working groups to cluster several opportunities under a single theme:
                                Policy, Innovation, Education, Media, Culture and Climate Justice. We hand-picked the
                                opportunities that we deemed most relevant and inspiring. The solutions presented are
                                still a work in progress, some of which might be transformed into long-term projects.
                            </p>

                        </div>

                    </div>

                    {[0,4,8,12].map(i =>
                    <div className="row solutions-new">
                        {solutions.slice(i, i+4).map(solution =>
                            <div className="column four wide">
                                <Popup content={solution.summary} position='bottom center' trigger={
                                    <div className="solution" style={{backgroundImage: 'url(/resources/images/output/' + solution.wg + '.jpg'}}>
                                        <div className="category">{solution.wg}</div>
                                        <div className="text-container">
                                            <h2>{solution.title}</h2>
                                            <h3>{solution.heading}</h3>
                                        </div>
                                    </div>
                                } />
                            </div>
                        )}
                    </div>)}

                    {/*
                    <div className="row solutions">

                        <div className="column eight wide left">
                            {solutions.slice(0, Math.floor(solutions.length / 2)).map(solution =>
                                <div className="solution">
                                    <img src={"/resources/images/output/line-2.png"} className="line"/>
                                    <div className="image-container">
                                        {solution.wg}
                                    </div>
                                    <div className="text-container">
                                        <h2>{solution.title}</h2>
                                        <h3>{solution.heading}</h3>
                                        <p>{solution.summary}</p>
                                    </div>

                                </div>
                            )}
                        </div>

                        <div className="column eight wide right">
                            {solutions.slice(Math.floor(solutions.length / 2)).map(solution =>
                                <div className="solution right">
                                    <img src={"/resources/images/output/line-2.png"} className="line"/>
                                    <div className="text-container">
                                        <h2>{solution.title}</h2>
                                        <h3>{solution.heading}</h3>
                                        <p>{solution.summary}</p>
                                    </div>
                                    <div className="image-container">
                                        {solution.wg}
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>*/}


                </div>

                <Image src={"/resources/images/output/bike.jpg"} size="huge"/>


                <div className="ui top center aligned stackable grid container centered part">
                    <div className="row">
                        <div className="column ten wide center aligned left-column">

                            <h1 className="small">Our demands</h1>

                            <p>
                                During our two-month sailing think tank we explored numerous ways in which we can push
                                for fair and sustainable travel. A specific part of our output is meant to be presented
                                at COP25 in Madrid. Next to drawing attention to the issues surrounding the travel
                                industry, we pose demands to country delegates at the Conference Of the Parties and
                                European countries in particular.
                            </p>

                            <br/>

                        </div>

                    </div>


                    <div className="row demands">

                        <div className="column eight wide left">

                            {demands.slice(0, 6).map(demand =>
                                <div className="demand">
                                    <img src={"/resources/images/output/demand-line-left.jpg"} className="line"/>
                                    <div className="image-container">
                                        <Image src={"/resources/images/output/" + demand.image}/>
                                    </div>
                                    <div className="text-container">
                                        <h3>{demand.title}</h3>
                                        <p>{demand.text}</p>
                                    </div>

                                </div>
                            )}

                        </div>

                        <div className="column eight wide right">

                            {demands.slice(6).map(demand =>
                                <div className="demand right">
                                    <img src={"/resources/images/output/demand-line-right.jpg"} className="line"/>
                                    <div className="text-container">
                                        <h3>{demand.title}</h3>
                                        <p>{demand.text}</p>
                                    </div>
                                    <div className="image-container">
                                        <Image src={"/resources/images/output/" + demand.image}/>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>
                </div>


                <div className="ui inverted vertical center aligned segment parallax parallax7" id="parallax7">
                    <div className="background"></div>
                    <div className="parallax-content"></div>
                </div>


                <div className="ui top center aligned stackable grid container centered part">
                    <div className="row">
                        <div className="column ten wide center aligned left-column">

                            <h1 className="small">Sail to the COP</h1>
                            <p>Sail to the COP is a group of 36 young changemakers from all over Europe sailing to the
                                UN conference in Chile to call for fair and sustainable travel. With our sailing think
                                tank and campaign, we are creating a vision, a roadmap and a portfolio of solutions to
                                transform the travel industry to become fair and sustainable.</p>

                        </div>

                    </div>

                    <div className="row">
                        <div className="column eight wide center aligned">
                            <Image src={"/resources/images/DSC09915.jpg"} size="huge"/>
                        </div>
                        <div className="column eight wide center aligned">
                            <Image src={"/resources/images/Sail to the COP - Map new.png"} size="huge"/>
                        </div>


                    </div>
                </div>

            </div>

        </>;
    }
}

export default Output;

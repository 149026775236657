import React from 'react';
import 'firebase/database';
import {Button, Container, Form, Grid, Image, Input, Message} from "semantic-ui-react";

class Railtothecop extends React.Component {

    render() {

        return (
            <div className="railtothecop">

                <div className="ui vertical stripe segment padded first colored-background">
                    <Container>
                        <Grid stackable>
                            <Grid.Row centered>
                                <Grid.Column width={4} textAlign="left">
                                    <Image src="/resources/images/railtothecop-logo-train.jpg" style={{maxWidth: '250px'}}/>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <Image src="/resources/images/output/train-2.jpg" style={{marginTop: '50px'}} floated="right" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={6} textAlign="center">

                                    <h2>COP25 onepager</h2>
                                    <p>
                                        In our mission for fair and sustainable travel we’re dedicated to improve the European travel system. Curious what demands we have, and what we want to achieve in the upcoming year? Read our one pager!
                                    </p>
                                    <a href="/resources/railtothecop-onepager.pdf" className="letter">
                                        <Image src="resources/images/railtothecop-onepager.jpg" size="small"/>
                                    </a>
                                    <br/>
                                    <a href="https://www.sailtothecop.com/resources/railtothecop.pdf">
                                        railtothecop-onepager.pdf
                                    </a>

                                    <br/><br/>
                                </Grid.Column>
                                <Grid.Column width={10}>

                                    <h2>Sign up to #railtothecop</h2>
                                    <p>
                                        Write your email address here to learn more about or be part of our campaign for the next year.
                                    </p>

                                    <div id="mc_embed_signup">
                                        <Form action="https://railtothecop.us4.list-manage.com/subscribe/post?u=2f57303ba0864c71ddbfa2faf&amp;id=86d3818282" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                            <div id="mc_embed_signup_scroll">

                                                <div className="mc-field-group">
                                                    <Input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="E-mail address" style={{minWidth: '300px', marginRight: '10px'}} />
                                                    <Button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button">Subscribe</Button>
                                                </div>
                                                <div id="mce-responses" className="clear">
                                                    <div className="response" id="mce-error-response" style={{display: "none"}}/>
                                                    <div className="response" id="mce-success-response" style={{display: "none"}}/>
                                                </div>
                                                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_2f57303ba0864c71ddbfa2faf_86d3818282" tabIndex="-1" value="" /></div>
                                                <div className="clear">
                                                </div>
                                            </div>
                                        </Form>
                                    </div>

                                    {/*<Form className="ui form" onSubmit={(e) => this.submit(e)}>*/}
                                    {/*    <Input style={{minWidth: '300px', marginRight: '10px'}} type="text" name="email" placeholder="E-mail address"/>*/}
                                    {/*    <button className="ui button " type="submit">Submit</button>*/}
                                    {/*</Form>*/}

                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>

                                <Grid.Column width={6} />
                                <Grid.Column width={16}>

                                    <h2>Open letter</h2>
                                    <p>
                                    Halfway on our journey from Europe to South America, heading to COP25 in Chile, we heard that COP25 had been relocated to Spain. Unfortunately the winds did not allow us to turn around in time, so we addressed all attendees of COP25 from Europe to #railtothecop. Read our open letter, written on the middle of the Atlantic:
                                    </p>
                                    <br /><br />
                                    <a href="/resources/railtothecop.pdf" className="letter">
                                        <Image src="resources/images/railtothecop.png" size="medium"/>
                                    </a>
                                    <br/><br/>
                                    <a href="https://www.sailtothecop.com/resources/railtothecop.pdf">
                                        sailtothecop.com/resources/railtothecop.pdf
                                    </a>

                                </Grid.Column>

                            </Grid.Row>

                        </Grid>
                    </Container>

                </div>

            </div>
        );
    }
}

export default Railtothecop;

import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Form, Input} from "semantic-ui-react";

class Newsletter extends React.Component {

    state = {
        loading: false,
        sent: false,
        email: "",
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    submit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let _this = this;

        let db = firebase.firestore();
        let values = {...this.state};
        values.createdAt = new Date();
        values.source = "sailtothecop.com";

        db.collection("forms").add(values)
            .then(function () {
                _this.setState({loading: false, sent: true});
            })
            .catch(function (error) {
                alert("Something went wrong, please let us know through info@yfst.org");
            });

        return false;
    }

    render() {

        const {loading, email, sent} = this.state;

        return (

            <div className="row">
                <div className="eight wide column center aligned">
                    <h2>Sign up to our newsletter</h2>
                    <p>We will keep you updated on our mission, actions and results.</p>

                    {sent ?
                        <div className="ui message green">
                            <div className="header">
                                Thank you!
                            </div>
                        </div>
                        :
                        <Form className="ui form" onSubmit={(e) => this.submit(e)} loading={loading}>
                                <Input style={{minWidth: '300px', marginRight: '10px'}} type="text" name="email" placeholder="E-mail address"
                                       value={email} onChange={this.handleChange.bind(this)}/>
                                <button className="ui button " type="submit">Submit</button>
                        </Form>
                    }

                </div>

            </div>

        );
    }
}

export default Newsletter;

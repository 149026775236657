import React from 'react';

function FAQ() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column sixteen wide">
                            <h1>FAQ</h1>



                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default FAQ;

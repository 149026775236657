import React from 'react';
import Newsletter from "../../newsletter";

function Apply() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first  colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column fourteen wide">
                            <h1>Apply</h1>


                            <div className="ui segment">
                                <h3>Applications closed...</h3>
                                <p>
                                    Unfortunately our applications have closed. But don't worry, this Sail to the COP will most likely not be the last of its kind. Sign up for our newsletter for all our updates!
                                </p>
                                <p>
                                    Want to get involved in a diferent way? We can still use your help, in preparation of the trip or during the COP for example. Don't hesitate to send us an e-mail if you might be able to support us in any way!
                                </p>

                            </div>

                        </div>
                    </div>


                    <Newsletter />

                </div>
            </div>

        </div>
    );
}

export default Apply;

import React from 'react';
import {Grid, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";

export const organizers = [
    {
        name: "Lena Hartog",
        image: "Lena-3.jpg",
        quote: "The climate and ecological crises ask for a global movement and for every industry to make bold an fast changes. I believe we can build a world where sustainable travel is the standard and not the exception. I am very excited to work together to create a shared vision, hold our leaders accountable and take action.",
        bio: <>Lena is a freelance action-researcher working part-time for social enterprise <a
            href="https://www.projectcrossover.nl/" target="_blank">CrossOver</a> in Amsterdam. For
            this project she is building on her experience being part of the <a
                href="https://nationale-denktank.nl/" target="_blank">National Think</a> tank in 2017,
            where she did research on the future of the labour market.
            As active member of the climate movement in the Netherlands she was part of the
            organizing team of the first <a href="https://www.lcoy.nl/" target="_blank">Local
                Conference of Youth</a> in the Netherlands in
            2018 and she is currently board member of <a href="https://gofossilfree.org/nl/"
                                                         target="_blank">Fossielvrij Netherlands</a>.
            She studied sociology
            and psychology at University College Utrecht and graduated with a masters in Sociology
            from the University of Amsterdam.</>,
        nationality: "🇳🇱",
        age: 27,
        function: "Impact & Program",
        mail: "lena@sailtothecop.com",
    },
    {
        name: "Mara de Pater",
        image: "Mara.jpeg",
        quote: "I am personally often challenged in combining my adventurous side with my ideals when it comes to climate change. With Sail to the COP I want to explore this while taking the public debate about unfair regulation of aviation to a global stage.",
        bio: <>Mara has an MSc degree in Cultural Anthropology from Utrecht University and is currently graduating for
            her second MSc in Environmental Science at Wageningen University & Research. In her studies she focuses on
            linkages between (de-)democratic processes and climate advocacy. For example, by researching energy
            democracy challenges in Colorado (USA) and illiberalism and climate policy in Hungary. Besides studying she
            volunteers for climate and nature organizations such as <a href="https://www.njn.nl/" target="_blank">Nederlandse
                Jeugdbond voor Natuur</a> and<a href="https://gofossilfree.org/nl/" target="_blank">Fossielvrij</a>.
            Recently she participated in <a
                href="https://www.clean2antarctica.nl/en/explore/challenging-companies-and-millenials" target="_blank">Quest
                for Change</a>, a sailing think tank on the Dutch energy transition.</>,
        nationality: "🇳🇱",
        age: 25,
        function: "Events & Grants",
        mail: "mara@sailtothecop.com",
    },
    {
        name: "Moon Weijens",
        image: "Moon.jpg",
        quote: "Always up for adventures, I'm very excited to develop a positive travel movement. As an international water management student, I know how devastating the impact of the current greenhouse gas emission is for our earth, and how it's largely affecting people who never set foot on a plane. Let's change that!",
        bio: <>Moon studies International Land and Water Management at Wageningen University & Research and she is a
            water ambassador appointed by the Ministry of Infrastructure and Water Management. Water is of vital
            importance for our ecosystems and society but climate change is rapidly changing water systems. With
            enthusiasm Moon shares her (water)knowledge and addresses the urgency of the climate change crisis during
            guest lectures, her internship as a geography teacher and as a <a href="https://www.dutchwavemakers.nl/"
                                                                              target="_blank">Dutch Wavemaker</a>. Last
            winter Moon worked together with people from different backgrounds on innovative ideas to push the textile
            industry towards a circular economy for the project <a
                href="https://www.clean2antarctica.nl/en/explore/challenging-companies-and-millenials" target="_blank">Quest
                for Change</a>. She was grateful to be selected for this sailing think tank which dropped her of on the
            other side of the Atlantic enabling her to do research on Integrated Water Resource Management and climate
            change resilience in Suriname.</>,
        nationality: "🇳🇱",
        age: 25,
        function: "Partnerships",
        mail: "moon@sailtothecop.com",
    },
    {
        name: "Jeppe Bijker",
        image: "Jeppe-BU130.jpg",
        quote: <>With my start-up Green Tickets I'm helping people to find the most sustainable ways to travel&mdash;but
            to improve the current options and stop the unfair subsidies on flying we really have to look at a global
            level: the COP.</>,
        bio: <>Freelance designer and software developer. As founder of <a href="https://www.greentickets.app"
                                                                           target="_blank">Green Tickets</a> Jeppe is
            building a platform to stimulate sustainable traveling, by making it easier to find, compare and book
            alternatives for flying. To facilitate sustainable long-distance traveling he recently co-founded the
            website <a href="https://www.sailscanner.org" target="_blank">Sail Scanner</a>.<br/>He is campaigner and web
            developer at the <a href="https://www.greentickets.app" target="_blank">Jonge Klimaatbeweging</a> and
            organized a trip with 35 young people to COP24 in Katowice, Poland last year &mdash; by bus and train.<br/>Jeppe
            studied Industrial Design Engineering at Delft University of Technology with an MSc degree in Design for
            Interaction.</>,
        nationality: "🇳🇱",
        age: 28,
        function: "Communication",
        mail: "jeppe@sailtothecop.com",
    }

];

export const homeTeam = [
    {
        name: "Sarah Kunze",
        image: "SarahK.jpg",
        bio: <>Sarah studies Multilingual Communication in English and Spanish with emphasis on Media and Journalism in
            Cologne. She is currently writing her thesis about the impact of language in the Climate Debate. Besides
            life as a language cameleon Sarah has a true obsession for anything food related, whether it’s <a href="https://foodsharing.de" target="_blank">foodsharing</a>,
            experimenting with new vegan recipes or packing snacks for her next adventure. Her dream is to combine it
            all one day: travelling around the world as slowly as possible and writing a cookbook with dishes sourced
            from the local foods available. </>,
        age: 23,
        nationality: "🇩🇪🇳🇱",
    },
    {
        name: "Isabel Seeger",
        image: "Isabel.jpg",
        bio: <>Isabel is a graduate student in Environmental Policy & Politics at Sciences Po Paris and FU Berlin and
            works at the <a href="https://www.prog-ocean.org/our-work/strong-high-seas/"
                            target="_blank">IASS</a> Potsdam on high seas conservation and governance. Berlin is far
            from the sea, but she is still glad to have recently moved here and excited to connect with local
            environmental movements. You can really get her talking by asking for advice on planning complex train
            travel endeavours or for a podcast recommendation.</>,
        nationality: "🇩🇪",
        age: 25,
    },
    {
        name: "Alexandre Muller",
        image: "Alexandre.jpg",
        bio: <>Growing up in Rio de Janeiro, Alex worked on political campaigns close to environmental and indigenous
            movements. Later he moved to France and studied Development Studies, focused on agricultural public
            policies. Alex is currently coordinating different agroforestry projects in Peru. After playing volleyball
            on Ipanema beach for years, he is looking forward to a new challenge: indoor beach-volleyball in freezing
            Berlin, where he is about to move at the beginning of winter. </>,
        nationality: "🇧🇷🇫🇷🇺🇸",
        age: 27,
    },
    {
        name: "Susana Bastos dos Santos",
        image: "Susana.jpg",
        bio: "Susana was born in the sunny Portuguese capital, but soon traded the ocean breeze for landlocked New Mexico to attend United World College - a two year international high school program working towards a peaceful and sustainable future. The early exposure to such a utopian world made her focus on social justice throughout her studies, particularly in human and cultural rights, violence and migration. With the ever increasing urgency to change towards sustainable livelihoods, Susana is interested in ensuring this is done so in a socially and culturally just way. \n" +
            "She has worked on the inclusion of local and indigenous knowledge systems on global climate science and policy processes at UNESCO, and is currently finishing her M.A in Human Rights. Temporarily back in her hometown, Susana has spent the last months between climate activism groups and learning how to dj!",
        age: 25,
        nationality: "🇵🇹",
    },
    {
        name: "Talia Berriman",
        image: "Talia.jpg",
        bio: "Talia is currently studying for her MSc in Carbon Management from the University of Edinburgh, after completing her BA in Arabic and French from the University of Durham this year. Her desire to work in the field of climate change was ignited after seeing the rubbish crisis in Beirut, Lebanon, where she spent her year abroad, and being frustrated with the amount of plastic waste and pollution across Lebanon and in other countries in the Middle East. Talia is passionate about finding and sharing creative solutions to the climate crisis, and regularly volunteers at conferences and events related to climate change, innovation and entrepreneurship. In her spare time, Talia enjoys dancing, aerial arts, volunteering, activism and travelling.",
        age: 23,
        nationality: "🇬🇧",
    },
    {
        name: "Lieke Brackel",
        image: "Lieke.jpg",
        quote: "Climate adaptation and climate mitigation are inseparable: quick action is required to prevent extremely dangerous circumstances. I believe Sail to the COP’s message to promote sustainable and fair travel alternatives is a great tangible start.",
        bio: "Lieke is a PhD candidate at the TU Delft with a double background in water management and philosophy. Her current research is dedicated to questions of justice and democracy that arise as societies’ have to cope with extreme weather events becoming more frequent and intense. Earlier, she conducted an internship with the Dutch Ministry of Infrastructure and Water on international cooperation in the Rhine river, researched environmental (in)justice alongside Manila Bay, and worked for the European Press Prize. There is little that makes Lieke more happy than being on the water... but she’ll have to cross that ocean another time. First bussing to Madrid! She’s excited to work together with such an energetic group of people to spread Sail to the COP’s message during COP 25!",
        age: 24,
        nationality: "🇳🇱",
    },
    {
        name: "Daphne Cronin",
        image: "Daphne.jpg",
        bio: "Daphne is a french and irish nature and music lover who grew up in France and Japan. She studied Electrical and Electronic Engineering in Scotland but soon realised she wanted to use her technical skills for more than just designing better earphones and be useful in creating climate change solutions. From there she went to test aquaponics systems in Cambodia, design electric fences in Peru with Engineers Without Borders and be an active member of Fridays For the Future in Spain. She is passionate about upholding more voices from the global south and including indigenous people in climate negotiations. Her hobbies include open water swimming and writing songs, so who knows she might swim to the next COP and write a song about it? But for now, she is ready to advocate for an accountable travel industry and bold reduction targets at COP25!",
        age: 23,
        nationality: "🇫🇷"
    },
    {
        name: "Anika Dafert",
        image: "Anika.jpg",
        bio: "Anika grew up in a small village in the Austrian alps that is only known for its famous skiers. Since school striking is a good way for a high school student to show politicians that young people do care about the climate crisis, she launched Fridays For Future Salzburg in February alongside with her little sister Luisa. This gave Anika the opportunity to go to New York for the Youth Climate Summit and the Climate Action Summit as an official part of the delegation of the President of Austria. So whenever she is not somewhere representing Fridays For Future or organising strikes, she likes to sing, dance, ride horses, walk her dog Wilma or play the violin. She really looks forward to meeting new people and to representing such an amazing project! ",
        age: 17,
        nationality: "🇦🇹",
    },
    {
        name: "Moritz Dreyer",
        image: "Moritz.jpg",
        quote: "A world trip after school - something I always dreamed of - has shaped me very much. Flown back home, I walked more than 1,000 km on the Camino the Santiago. Retrospectively it was the latter experience, i.e. slow travelling, that had a longer-lasting effect on me.",
        bio: "Within the master thesis of his studies Environment and Bio-Resource Management focussing on climate mitigation and adaptation at BOKU Vienna, Moritz conducted a sustainability assessment on the production of edible mealworms. Convinced that insects will play a role in future food systems if reared on side-streams, he raises awareness for overcoming culture-bound disgust. As his passion is ascending rocks (and trees), he’ll need to pull himself together not to escape into a climbing hall during COP25. Besides, he devotes himself to activism – as being part of a red line representing ecological limits that we shouldn’t but have already crossed, e.g. in the aviation sector️. Needless to say that today, if he travelled the world again, he’d take trains instead of planes. However, flying less doesn’t mean that you can’t have adventures anymore – on the contrary! ",
        age: 28,
        nationality: "🇦🇹"
    },
    {
        name: "Max Fuchslueger",
        image: "Max.jpg",
        bio: "After Highschool was finished Max decided to get active and joined the Clim’school team within the NGO CliMates Austria teaching students about climate policies.\n" +
            "With studying agriculture he tries to find solutions to one of the big sectors needing urgent change from industrial farming to more organic. After Greta Thunberg’s speech at COP25 Max realized it was not about a lack of solutions but rather a lack of political will to stop the climate crisis. So he participated in the very first climate strike in Austria and during the next 6 months helped organizing the Fridays for Future movement to put pressure on politicians to act. \n" +
            "During the same time Max was part of starting Extinction Rebellion in Austria, acknowledging that the climate movement needs to work with all democratic tools, including peaceful civil-disobedience, to stop this crisis.",
        age: 19,
        nationality: "🇦🇹",
    },
    {
        name: "Maria Galí Reniu",
        image: "Maria.jpg",
        bio: "Maria comes from the Mediterranean coast of Barcelona, where she grew up loving both mountainous and maritime worlds.\n" +
            "She studied Environmental Sciences in Barcelona and later pursued a more climate focused degree by graduating from the European Master’s program EnvEuro, held in Copenhagen and Vienna. Since she moved back home, she has worked as an environmental educator and currently she is working in the waste sector. While also trying to get involved in exciting environmental projects in her region.\n" +
            "She believes in the importance of local and participatory solutions as the way to go, and strongly thinks of degrowth as an alternative to our hectic way of life. That is why she is very excited to have the chance to help spread the important message on sustainable travel that #sailtothecop has to share.",
        age: 27,
        nationality: "🇪🇸",
    },
    {
        name: "Samantha Kristensen",
        image: "Samantha.jpg",
        bio: "Samantha was born in Malaysia, but also has roots from Denmark. She grew up in Singapore and Hong Kong and eventually found herself a cosy environment in the Netherlands. She studies Global Sustainability Science at Utrecht University and has worked on sustainability projects such as a sustainable holiday travel guidebook and the GlobalGoals project at the Copernicus Institute of Sustainable Development, where she is still currently a research intern at. Her passion for sustainability was founded in HK as she lived in the mountains, by the beach which unfortunately, was covered in trash from the ocean. This led to her participating in beach cleanups as a young teen and eventually finding her way towards environmental policy. In her free time she likes to read books/articles and experiment with vegan recipes. And with all that reading comes knowledge which she hopes to bring to COP25 in Madrid!",
        age: 22,
        nationality: "🇩🇰",
    },
    {
        name: "Thomas Mohring",
        image: "Thomas.jpg",
        bio: "Thomas is from the Netherlands and works for ProRail, the Dutch rail infrastructure manager. After finishing his msc in Public Administration at Leiden University in the Hague he worked about 4 years for an NGO that gives Dutch youth from underprivileged neighborhoods equal opportunities by preparing them for the labour market. \n" +
            "For the past 2 years he has been working on environment and sustainability of the Dutch railway sector. Equality and sustainability have been core values throughout Thomas’ working life. That’s why he joins Sail to the COP in Madrid. To thrive for a more fair and more sustainable travel industry.",
        age: 28,
        nationality: "🇳🇱",
    },
    {
        name: "Jorge Sánchez Hernández",
        image: "Jorge.jpg",
        bio: "Jorge works on Sustainable Development in the NGO sector. He’s been passionate about the environment since he joined the scouts at the age of 8. He has organized Europe-wide projects involving thousands of people on topics of Climate Change and Environmental Sustainability and volunteering for movements for the last 6 years. He’s been lucky to attend COP23 and COP24 and he’s very excited to attend COP25 with #SailToTheCOP.\n"+
            "Jorge enjoys cooking vegan  dishes and playing Ukulele. He’s a non-formal education trainer and loves everything politics. He lives in Brussels, but after COP he’s going on a trip from Southern Spain to Japan by train, bus and hitchhiking - without an end date.",
        age: 26,
        nationality: "🇪🇸"
    },
    {
        name: "Lars van Dorsselaer",
        image: "Lars.jpg",
        bio: "Lars is currently working with fair future generators an initiative by Milieudefensie ( Friends of the Earth Netherlands), like SailtotheCOP’ers Sofie and Kisjonah. He grew up in the southwest of the Netherlands, a province called Zeeland, which was hit by the floods in 1953. He studied International management in Germany and graduated a master’s in crisis and security management from Leiden University, with a semester exchange at SciencesPo. He is excited about bottom-up, community led initiatives like eco-villages and permaculture farms, which pose as a sustainable alternative to the large scale destructive agriculture. He volunteered with several of these initiatives around Europe and biked from the Netherlands to the European Parliament in Strasbourg in may 2018 to inspire young people at the European Youth Event, together with a youth branch of Climate Action Network Europe.",
        age: 27,
        nationality: "🇳🇱",
    },
    {
        name: "Pippi van Ommen",
        image: "Pippi.jpg",
        bio: "Pippi (indeed named after Pippi Longstocking) is currently finishing her thesis on environmental justice and Dutch foreign policy for the master International Land and Water Management at Wageningen University. Her interests lie wherever natural resources and human rights come together. She did research in Ecuador on the impacts of the rose industry on local communities and he worked in the Philippines with an environmental grassroots organization which convinced her of the need for more direct activism in the Global North, where environmental defenders do not face as many death threats. Which is why she is now involved in Extinction Rebellion. Pippi was born on a boat in Amsterdam and honoured to represent Sail to the COP in Madrid.",
        age: 23,
        nationality: "🇳🇱"
    },

    {
        name: "Namitha Vivek",
        image: "Namitha.jpg",
        quote: "I want us to rethink what it means to be affluent. I want people in the developing world to stop demeaning the use of public transport; for people to afford the luxury of time, and experience the charm in slowing down.",
        bio: "Namitha grew up in India and is an International Relations and Physics graduate. She is interested in climate change and forced migration. She spent most of 2019 working at the UNFCCC gaining knowledge on climate change mitigation mechanisms and is now a fellow at Social Science Works. She lives in Berlin, and in her free time enjoys marathon walks in forests, rowing, meeting people over numerous cups of ginger tea ☕ and hopes to have hiked the Pacific Crest Trail before she hits 30.",
        age: 27,
        nationality: "🇮🇳",
    },
    {
        name: "Lander Wanters",
        image: "Lander.jpg",
        bio: "Lander is from Aalst, the carnival city in Belgium. \n" +
            "Since he's young he is very passioned form everything that surrounds nature and climate.\n" +
            "He studied environmental awareness and works for Natuurpunt, the biggest nature organisation in Flanders.\n" +
            "Also, he loves cycling and traveling, especially in mountain areas. He also used his passion for cycling in favour for the climate: biked in one day from Brussels to Paris to create awareness for the COP in 2016 together with climate experts.",
        age: 28,
        nationality: "🇧🇪",
    },
    {
        name: "Ariane Wrumnig",
        image: "Ari.jpg",
        bio: "Ari was born and raised in Vienna, where she is currently doing her Master's degree in Wildlife Ecology and Management at the University of Natural Resources and Life Science. The animal species that poses the most questions for her is definitely the Homo sapiens. That's why she's trying to observe and research their individuals daily in their urban habitat. In addition, she has been an environmental activist for many years and is a member of the youth network Generation Earth where she co-organised several projects and participated in international exchange programs. Ari is going to the COP25 as a representative because she feels the urgency with which the message of #sailtothecop has to be spread. And beyond that she is convinced that the time has come for the power of the people (or rather it has to come NOW).",
        age: 25,
        nationality: "🇦🇹",
    },
    {
        name: "Matthias Zaussinger",
        image: "Matthias.jpg",
        quote: "Keeping our skies free for birds to roam and staying on the ground, by train or bus is not only a step to drastically reduce our carbon footprint, but also sends a message for future generations. For too long we have been abusing our environment for our own greed and comfort. By taking the train to the COP25 I want to be part of a movement for sustainable travel, but also show how difficult land transport is in comparison to flying and that change needs to happen NOW.",
        bio: "Matthias is a student for sustainable resourcing management in Vienna. Travelling and volunteering in animal rescue stations in Thailand, Guatemala and Cameroon got him very involved with wildlife protection. Witnessing the effects of deforestation, wildlife trafficking and oil harvesting made him change his views about our habits and the way society deals with our current problems.",
        nationality: "🇦🇹",
        age: 27,
    },
];

export const participants = [

    {
        name: "Inès Bakhtaoui",
        image: "Ines.jpg",
        quote: "With Sail to the COP, I want to send a strong message to the world. I want to say that every single action counts and contributes to big changes.",
        bio: "Ines is a renewable energy engineer, passionate about systemic and multidisciplinary solutions to environmental challenges. She is graduating from her MSc in Climate Change Management and Finance at Imperial College London. She volunteers for the youth organization CliMates where she is involved in climate change advocacy and contributes to youth representation in international climate negotiations. She is currently working as an intern for the UNFCCC secretariat, on issues of loss and damage related to climate change.",
        nationality: "🇫🇷",
        age: 22,
    },
    {
        name: "Stephan Charalampopoulos",
        image: "Stephan.jpg",
        quote: "I strongly believe that Sail to the COP has the potential to influence the future of transportation. Be it at the COP directly or through the media and all the people that are following us on this journey! Sailing there, we are setting a living example of sustainable mobility.",
        bio: "Stephan studied environmental management at the University of Natural Resources and Life Sciences in Vienna. He gained some experience in the fields of entrepreneurship, education, communication and corporate social responsibility, always working towards the Sustainable Development Goals. Recently he started pursuing his own projects to raise awareness for sustainability, ranging from blogging, videography to poetry slams and a media platform for positive news, that he is currently building.",
        nationality: "🇬🇷🇦🇹",
        age: 26,
    },
    {
        name: "Adélaïde Charlier",
        image: "Adelaide.jpg",
        quote: "Besides bringing the youth  message on the climate crisis to the COP, this journey will enable us to  strengthen the international network of Youth  for Climate. The Climate crisis indeed requires concerted international actions of the youth of the world. We  will also take this opportunity to visit the Amazon on an invitation from the the amazonian traditional chiefs  and contribute to the mobilisation to protect the Amazon forest, the lungs of our planet.",
        bio: "Adélaïde is an  18 years old  climate and environment activist from Belgium. She  just completed  High School in Namur, Belgium. Adélaïde is engaged with Amnesty International and the Youth City Council among others.  Inspired by Greta Thunberg, together with Anuna De Wever she initiated Youth for Climate Belgium  and organised  weekly school strikes  to put the climate crisis at the centre of the political and citizen attention and debate. They gathered tens of thousands of youth in Belgium  and in Brussels, the heart of Europe. \n" +
            "They have been invited to the COP and have been looking for the most environmental friendly way to cross the Atlantic. \"Sail to the COP\" provided the answer. ",
        nationality: "🇧🇪",
        age: 18,
    },
    {
        name: "Natalie Craig",
        image: "Natalie.jpg",
        quote: "Learning from other cultures is vital in building a sustainable and fair society. Developing and supporting alternative forms of travel is a key component in decarbonising our economy.",
        bio: "Natalie is studying an MSc in Sustainable Development at the University of Exeter Cornwall Campus. Her thesis is focused on the UK youth climate justice social movement which led her to hear about Sail to the COP! With prior experience in global corporate communications and marketing strategy she is grateful for the opportunity to work on a future focused initiative with the protection of people and the planet at the forefront.",
        nationality: "🇬🇧",
        age: 27,
    },
    {
        name: "Eva Dijkema",
        image: "Eva.jpg",
        quote: "As an industrial designer I have always been very interested in people’s needs, hopes and dreams which make why people behave a certain way. I like to research those needs, hopes and dreams to see what elements can be used to get people to consider making different decisions.",
        bio: "The train is more comfortable, takes you across beautiful sceneries, you can work, walk around, take as many luggage a you want and get out wherever you want. Yet, still many people opt for the plane. I know that still a lot needs to change in the European train track system to make seamless European travelling possible. However, I also know it is not just a matter of technical challenges but as much so a social and psychological one. I feel super excited that during sail to the COP I can also work on building a new story that we can tell each other. A positive story about a greener society with clean air, lot of job opportunities and innovation in which we celebrate the advantages of clean travel alternatives, like the train.",
        nationality: "🇳🇱",
        age: 28,
    },
    {
        name: "Annewiets Douma",
        image: "Annewiets.jpg",
        quote: "We are living in challenging times. Our planet has suffered so much under human influence. More than ever, it is time to unite within communities and act in any way possible to protect and restore life on earth. We are all in this together and I believe we can move towards a more beautiful world if we give the best of us. As we sail to the COP, we are part of the solution and I am very excited to see what our combined young minds can create while brainstorming at sea.",
        bio: "Annewiets studied medicine in Leuven and Health Education and Promotion at the Maastricht University. While taking a gap year she fell in love with Patagonia. It is now her dream to built a life there. She hopes to contribute to promoting the health of the people, but also of the environment that they are part of. Since she finds that prevention is much better than cure, she plans to start with a small permaculture project and combine her passion for cooking and nutrition with medicine. Patagonia still has complete eco-systems, vast lands of wild nature and a wealth of sweet water reservoirs. Therefore she believes it is crucial to protect this valuable region and prevent that we make the same mistakes that have led to harm nature in other parts of the world. She intents to help this cause in any way she can.",
        nationality: "🇳🇱",
        age: 27,
    },
    {
        name: "Jan Feist",
        image: "Jan.jpg",
        bio: "Jan studied earth & environmental sciences at the Amsterdam University College with a strong interest in urban agriculture and has been involved with numerous climate action groups throughout his studies. After graduation, he was part of the programme team of the Local Conference of Youth NL and completed an internship with the circular vertical farming startup OneFarm in Amsterdam. After sailing to the COP, Jan aims prolong his stay in South America to gain practical experience with regenerative agriculture and continue studying sustainable food production academically in fall 2019.",
        nationality: "🇩🇪🇵🇱",
        age: 23,
    },
    {
        name: "Frederike Freitag",
        image: "Frederike.jpg",
        quote: "Even in my study which is focused on sustainability, I see the struggle all around me. So many of us want to experience, live, and work in new places and cultures while at the same time not wanting to harm the environment. Yet, often we still do. I believe we have to start looking at alternatives. And if we want to go far, we have to go together.",
        bio: "Frederike has been passionate about the topic of flying for the last two years, researching, and trying to find a solution to the flyer’s dilemma for herself. During a year abroad, working on a dive boat, for the tourism industry, and in hospitality, she reflected on a lot of her values and perspectives of the world. Eventually, she gained a new awareness for global challenges and soon found herself motivated to find solutions and take action. From there, she decided to move to the Netherlands to study ‘Global Project and Change Management’. Her main fields of interest remain to be any issues linked to overconsumption as well as systemic issues such as waste management, plastic in the environment, or – as for this matter - our behavior around flying.",
        nationality: "🇩🇪",
        age: 21,
    },
    // {
    //     name: "Max Fuchslueger",
    //     image: "Max.jpg",
    //     quote: "There is already a flight-shaming movement and we really have to question our behaviour in times like these, whether it is our nutrition or transportation. But the climate crisis is not something we can solve on an individual level anymore. We can no longer let politicians get away with completely failing to reach the 1,5°C goal and their responsibility to initiate the transformations leading us to fossil fuel free and sustainable world.",
    //     bio: "After Highschool was finished Max decided to get active and joined the Clim’school team within the NGO CliMates Austria teaching students about climate policies. With studying agriculture he tries to find solutions to one of the big sectors needing urgent change from industrial farming to more organic. After Greta Thunberg’s speech at COP 24 Max realized it was not about a lack of solutions but rather a lack of political will to stop the climate crisis. So he participated in the very first climate strike in Austria and during the next 6 months helped organizing the Fridays for Future movement to put pressure on politicians to act. During the same time Max was part of starting Extinction Rebellion in Austria, acknowledging that the climate movement needs to work with all democratic tools, including peaceful civil-disobedience, to stop this crisis.",
    //     nationality: "🇦🇹",
    //     age: 19,
    // },
    {
        name: "Clara von Glasow",
        image: "Clara.jpg",
        quote: "I feel priviledged to be part of adventure and committed to make the most out of it to finally draw more attention to the enourmous effects of travel in terms of emissions. We need to make our voices heard and strongly claim for more sustainable travel and an acknowledgement of responsibility from the aviation and shipping sector.",
        bio: <>Clara recently finished her law degree and is in the final stage of her Master in Business at EBS
            University for Business and Law. She is on the legal board of the organisation Klimadelegation e.V. (<a
                href="http://www.klimadelegation.de" target="_blank">www.klimadelegation.de</a>), a youth organisation
            that has been participating in the COPs since 2012, aims to communicate youth voices into the process and
            transparently show what is happening in the conference halls. One of their main topics is intergenerational
            equity. Clara was part of the organising team of the 13th Global Conference of Youth (<a
                href="http://www.coy13.org" target="_blank">www.coy13.org</a>)and participated in the German Fijian
            Youth exchange for COP23. Clara is also an active member of YOUNGO (<a href="http://www.youngo.uno"
                                                                                   target="_blank">www.youngo.uno</a>),
            the official youth constituency to the United Nations Framework Convention on Climate Change (UNFCCC).</>,
        nationality: "🇩🇪",
        age: 25,
    },
    {
        name: "Jacinta Hamley",
        image: "Jacinta.jpg",
        quote: "Changing our lifestyles to be more harmonious with the environment and the future of our world doesn’t need to be disheartening. We all need to adopt more sustainable travel habits. We need more feasible and appealing alternatives. We need both a mindset and a system shift!",
        bio: "Jacinta has completed a BSc at Leiden University College, majoring in Earth, Energy and Sustainability. Her study topics ranged from the causes and impacts of climate disruption to various sustainable solutions which take into account environmental, economic and social perspectives. Jacinta’s thesis, on engaging the Irish public through communication on the approaching energy transition, is currently being prepared for publication. \n" +
            "Jacinta is passionate about travel as she appreciates the value it brings to people’s lives. She wholeheartedly believes that her travel and lifestyle choices should not be at the detriment of others, which is why she proactively minimises air travel. Originally from Northern Ireland, this can be very challenging considering the lack of feasible alternatives. Undeterred, she will travel from Belfast to Budapest this summer by ferry and bus.",
        age: 23,
        nationality: "🇬🇧🇮🇪",
    },
    {
        name: "Catalina von Hildebrand",
        image: "Catalina.jpg",
        quote: "Climate breakdown is the biggest crisis we face, and we need action to push for ambitious policies at the international level. I am thrilled to make this journey across the atlantic, calling for change on every level: at the COP all the way to our personal travel habits.",
        bio: <>Catalina studied international development, with a focus on natural resources and social conflicts. She
            set up a fossil free campaign at the university of Amsterdam and then took part in establishing <a
                href="https://gofossilfree.org/nl/" target="_blank">FossielVrij NL</a>. Through her work at <a
                href="https://www.banktrack.org/" target="_blank">BankTrack</a>, she advocated for commercial banks to
            drop coal investments in the lead up to COP21. Over the last two years she has been working with a Dutch
            platform promoting access to finance as a way to empower people living in poverty.</>,
        age: 29,
        nationality: "🇵🇪🇩🇪",
    },
    {
        name: "Josefien Hoerée",
        image: "Josefien.jpg",
        bio: <>Josefien Hoerée is at the end of her studies of Civil Engineering/Architecture at the University of Gent
            (Belgium). Being the elder sister of Anuna De Wever who started the Climate school strike in Belgium, she
            was active right from the start in the core team of the movement. Not only did they organise school strikes
            for the climate every week for 20 weeks on end - making it the biggest youth civil movement of the 21st
            century - she also organised a climate festival (May 24th, 2019) and attended numerous activities and civil
            disobedience actions to raise awareness and pressure the Belgian government into developing a climate law
            and climate action plan. In 2014, Josefien spend one year on an intercultural exchange program in Panama,
            and therefore speaks fluent Spanish, which will make her the Spanish spokesperson for the Youth for Climate
            delegation at the COP meeting in Chili. </>,
        age: 23,
        nationality: "🇧🇪",
    },
    {
        name: "Rosa Hofgärtner",
        image: "Rosa.jpg",
        bio: <>Rosa is a graduate student in Environmental Policy at Sciences Po Paris, where she also follows courses
            in media & journalism. She aspires to combine these two fields as a researcher and environmental journalist.
            The link between the climate crisis, our economic system and inequality is of particular interest to her,
            one that can be found within realm of mobility as well. Before starting her master’s degree, she attended a
            foundation year at the Royal Academy of the Art to explore new ways of bringing art and academia closer
            together. She already had the chance to put some of her aspirations into practice: her research was
            published in <a
                href="http://labos.ulg.ac.be/hugo/wp-content/uploads/sites/38/2017/11/01-SEM2018_30oct_18h_HD_Final-version_compressed.pdf#page=71"
                target="_blank">State of Environmental Migration</a>, and she has written, illustrated and produced TV
            and radio items for several media outlets, such as <a
                href="https://nos.nl/artikel/2245101-groen-geloven-zonnepanelen-in-kruisvorm-en-zuinige-kranen-in-moskeeen.html"
                target="_blank">NOS</a>, <a
                href="https://www.vpro.nl/programmas/tegenlicht/lees/bijlagen/2018-2019/We-moeten-niet-zo-bang-zijn-voor-klimaatangst0.html"
                target="_blank">VPRO Tegenlicht</a> and <a href="https://www.vn.nl/de-jongeren-die-nederland-niet-wil/"
                                                           target="_blank">Vrij Nederland</a>, mostly on topics related
            to the climate crisis, environmental pollution or migration. She also works as a researcher for the <a
                href="https://www.stadsleven.nu/" target="_blank">talkshow Stadsleven</a>. </>,
        age: 25,
        nationality: "🇳🇱",
    },
    {
        name: "Lukas Kiefer",
        image: "Lukas.jpg",
        quote: "The way we travel needs to change. I am sailing to Chile to raise worldwide awareness for sustainable mobility and to show alternatives.",
        bio: "Lukas studies Renewable Energies in Cologne, Germany. Climate protection has become increasingly important to him due to his studies and proximity to the Rhineland Open Pit mines. After several visits to the opencast mine and the surrounding areas he developed the desire to actively fight for more climate protection. Furthermore he is concerned about sustainable living and tries to keep his own carbon footprint as low as possible in his daily life.\n" +
            "In 2018 he became a member of Klimadelegation e.V. It is a group of young people that participates in climate conferences and represents the voice of young and future generations.",
        nationality: "🇩🇪",
        age: 23,
    },
    {
        name: "Rob Jacobs",
        image: "Rob.jpg",
        // quote: "The way we travel needs to change. I am sailing to Chile to raise worldwide awareness for sustainable mobility and to show alternatives.",
        bio: "Rob Jacobs studied psychology & visual culture. Today he makes films and organises events as part of “Collectif Faire-Part”, a Belgo-Congolese film ensemble that focusses on questions of decolonization. He’s interested in the ways that artists and activists use public space to tell stories that challenge dominant discourses. He loves living in Brussels, but enjoys the occasional escape to nearby seashores & riversides. He's a mediocre bass player with a love for jazz & hip hop.",
        nationality: "🇧🇪",
        age: 30,
    },
    {
        name: "Justus Konneker",
        image: "Justus.jpg",
        quote: "Although being deeply humbled by the vast the journey ahead of us, I am eager to exercise this opportunity by synthesizing my long love for sailing and sustainability. On the horizon I can see a new world in which each of us hold deeper reverence for the environment and we are all the better for it.",
        bio: "Justus has just spent the last three years studying politics, philosophy and sustainability in the liberal arts programme of Leiden University College The Hague. He has a deep love for sailing, often spending his summers teaching young children how to sail in a sailing school in Ploen, Germany. In the summer of 2017, he had the luck of being able to join three of his friends in a journey across the Baltic sea from Gdansk, Poland, to the capital of Finland, Helsinki. More recently, he has taken part in the formation of the environmental social movement, “Extinction Rebellion Netherlands”, especially in the rebellion week, where a series of direct actions took shape in the form of building occupations, street parties, marches, and other forms of creative protest.",
        age: 21,
        nationality: "🇩🇪",
    },
    {
        name: "Santeri Lehtonen",
        image: "Santeri.jpg",
        quote: "While governments and corporates struggle with keeping their word on climate action, citizens are rising to the occasion with the necessary ambition to fight the climate crisis. We will trumpet our message from the Regina Maris, our floating home and platform, to show that travel can be transformed to become respectful of the future generations. I am excited to join forces with such an impressive league of extraordinary young minds",
        bio: <>Santeri is a field-tested ecologist, having spent two years with an Arctic ecology research group <a
            href="https://teamshrub.com/" target="_blank">Team Shrub</a> in the Canadian Arctic. After completing his
            Master in Environmental Science he pivoted to embed himself more in the climate movement, and now works with
            Impact Entrepreneurship, Life Cycle Assessments and Climate Action, with a special interest in Sustainable
            Food Systems, Sustainable Travel and Communication. Santeri also gives talks and facilitates workshops in <a
                href="https://climate.impactforecast.org" target="_blank">Climate Impact Forecast</a> and Sustainable
            Business. His current favourite child: <a href="https://www.vojo.me/" target="_blank">vojo</a>, has a
            mission to empower people to choose the most climate conscious travel options in Europe. Santeri represents
            Climate-KIC Alumni at Sail-to-the-COP, and will host the first-ever "Atlantic Ocean Climathon" onboard the
            Regina Maris.</>,
        age: 29,
        nationality: "🇫🇮",
    },
    {
        name: "Ayoub Makhloufi",
        image: "Ayoub.jpg",
        quote: "With Sail to COP, MIPAI wishes to show that it is possible to travel differently. The symbol Light Us and myself will travel from Katowice to Casablanca by electric cars and train before joining our partner Sail To COP to cross the Atlantic with the \"Regina Maris\".",
        bio: "Ayoub studies Communication and Marketing in ISEG Business School, after his Master Degrees, he wanted to participate actively in the preservation of our planet. Before COP22 in Marrakech, Ayoub and his team create MIPAI, Non-profit organization that aims to inform, raise awareness and mobilize around sustainable development. Light Us is the first project carried out by MIPAI. Just like the Olympics’ Flame, Light Us makes the transition between each COP’s organizing country. In the last edition in 2018, for the COP24, MIPAI and MYCN want to innovate! In fact, sustainable mobility isn’t limited to electric cars. We have decided for this 3rd edition, to make the journey by public transportation, parallel to the electric cars relay, allowing nearly 50 youngsters that are committed to climate to cross over 5000 Km and show the world that it’s possible to travel long distances by taking public transportation while paying attention to their carbon footprint.",
        age: 29,
        nationality: "🇲🇦🇫🇷",
    },
    {
        name: "Symke Nieboer",
        image: "Symke.jpg",
        quote: "As an environmental student, I learned about how humanity is affecting the planet and therefore I am trying to minimize my own daily footprint more and more each day. I am super excited about this adventure with Sailing to the COP, where we should create the awareness that is needed to change our ways, making more sustainable travel decisions every day.",
        bio: "Symke is finalising her bachelor in Global Sustainability Sciences at the University of Utrecht. Within this bachelor, Symke focused on water, climate & ecosystems and combined this with governance and societal transformation. She also combined both topics in her bachelor thesis where she researched possible grazing practices for a mountain village in Morocco and looked into ways to implement these practices with public participation. This thesis was done in collaboration with the NGO Perma Atlas, which gave her the chance to help them implement her research in Morocco. Next to her studies, she works in the largest vegan restaurant in Europe.",
        nationality: "🇳🇱",
        age: 21,
    },
    {
        name: "Jiske van Oeffelt",
        image: "Jiske.jpg",
        quote: "",
        bio: <>Jiske just finished her BSc in Earth, Energy and Sustainability at Leiden University College. In this
            programme, she studied topics ranging from environmental sciences and the energy transition to economic
            perspectives on sustainability issues. She is especially interested in climate change communication,
            environmental education and the transition to a circular economy. In her thesis, she reviewed best practices
            in effectively communicating climate science and implementing climate policies. Together with three other
            students, she has been working on a lesson plan on plastic pollution for high school students around the
            world (<a href="http://www.unplastic.me" target="_blank">www.unplastic.me</a>) which won this year’s first
            prize in the Dopper Changemaker Challenge.</>,
        nationality: "🇳🇱",
        age: 22,
    },
    {
        name: "Mark van der Poel",
        image: "Mark.jpg",
        quote: "At a young age I perceived travel to only come at an economic cost. But the environmental costs are now fundamental to my decision-making on travels. I increasingly try to achieve my goals through an environmentally conscious approach, a way of life that is worth spreading! And as a seasoned sailor on smaller boats, I am extremely excited to embark on the Sail to the COP voyage and spread this message!",
        bio: "With a background in land and water management at Wageningen University, Mark now studies Environmental Sciences for his Master's degree, specializing in Water Systems and Global Change. He started studying global climate change during an exchange semester abroad to UBC in Canada, and has not stopped since. His interest in natural resources and sustainability was fundamental in his choice for an internship in Ethiopia that focussed on Water Resources Management. As a former chair of RUW Foundation Wageningen, he opened the floor to critical views on green growth and sustainable development through events, lectures, workshops and other activities. During summers he is a volunteer sailing instructor for children and young adults. After COP25 in Chile, he plans to commence his MSc. field research in South America on Agroecological Systems and Climate Smart Agriculture. ",
        nationality: "🇳🇱",
        age: 24,
    },
    {
        name: "Kisjonah Roos",
        image: "Kisjonah.jpg",
        quote: "Kisjonah studied Korean studies at Leiden University, as part of her studies she lived and studied in South Korea for six months which inspired a gap year to re-evaluate her direction in life. She began with small changes within her own life and realized she had a passion for “going green” and spreading this idea. This urged her to join the Fair Future Generators project, a project that helps youths learn new skills within the frame of creating a sustainable future.",
        bio: "I believe that to save the earth we all have to change our ways together, it is not a temporary phase. We have to commit to saving our future.I don’t have a lot of experience yet but I am ready to learn and take action. Through the Fair Future Generators Project I got the amazing opportunity to join the Sail to the COP, by documenting my experience I hope I can inspire change to the people around me.” ",
        nationality: "🇳🇱",
        age: 25,
    },
    {
        name: "Naila",
        image: "Naila.jpg",
        bio: <>Naila studies physics at the Université Libre de Bruxelles in Brussels, Belgium. As a grassroots activist, she's been involved in building a varied set of projects, campaigns, civil disobedience actions and mass protests (mainly for social, democratic and environmental justice). She has co-launched <a href="https://extinctionrebellion.be/" target="_blank">Extinction Rebellion</a> in Belgium in 2018 and has also been involved in the European Citizens Assembly for Climate Justice and the <a href="https://report.gndforeurope.com/" target="_blank">Green New Deal for Europe</a> projects. Next to activism on the ground, she's also focused on using data visualization and systemic thinking to make scientific knowledge accessible for everyone and to map and structure solutions per domain so people are directly tooled to take action at all different levels.</>,
        nationality: "🇲🇦",
        age: 21,
    },
    {
        name: "Sarah Siemers",
        image: "Sarah.jpg",
        quote: "I had the privilege to see many impressive and remote places in the world, but with the consequence of having left a huge carbon footprint that I caused by taking the plane several times. I not only want to prove myself now that it is also possible to enjoy the beauty of this earth by traveling more sustainable, but also want to inspire others to reconsider their means of transportation.",
        bio: "Sarah has been involved in various NGOs and think tanks related to climate change over the past few years. After she occupied the position of the Advocacy Co-Director at CliMates Austria last year, she is currently in the same position at CliMates International with the purpose to empower especially young people to raise their voice for climate justice. She has a BA from University Vienna in International Development and is currently at her final stages of her Master studies in Environment and Bio-Resources Management at the University of Natural Resources and Life Sciences Vienna. Her research interests include leadership in energy transition, and international energy and climate policies in general.",
        nationality: "🇦🇹🇩🇪",
        age: 29,
    },
    {
        name: "Sofie Steffens",
        image: "Sofie.jpg",
        quote: "During my time in Nepal, I became acutely aware of the inequality in the world. The contrasts made me feel what a privileged background I actually have, growing up in Dutch ´suburbia´. Off course, the effects of the climate crisis are also felt unequally. For the rich amongst us, it is still something abstract and far away, whereas for the poor its a daily struggle. Flying everywhere for holidays being the prime example. I am extremely happy to join Sail to the COP, and I’m filled with energy to be part of the solution, and not the problem. Hopefully our efforts will bring us a closer to finding a way to travel sustainably, and therefore closer to equality.",
        bio: "Sofie spent the last few years mainly in Nepal working on several projects. In the aftermath of the big earthquake in 2015, which she personally experienced, she founded Kusala Carpets; a small company aimed to make victims of the earthquake independent again. During her time abroad she started to realise the situation humanity is facing. Knowing this she really wanted to invest her time in making a difference, so she joined Fair Future Generators, which gave her the opportunity to get onboard with Sail To The COP.",
        nationality: "🇳🇱",
        age: 27,
    },
    {
        name: "Jorien Timmers",
        image: "Jorien.jpg",
        quote: "When it comes to our reaction to the deterioration of the environment, we are, in many respects, comparable to the frogs in the famous experiment: when placed into hot water, they immediately jumped out but when put into cold water that was slowly heated, they did not react and boiled to death. With my project, I hope to stimulate people to react.",
        bio: "After completing her bachelor in Psychology at the Vrije Universiteit Amsterdam, Jorien spent two and a half years abroad where she developed her project ‘Eco Bus America’, a project which aims at creating awareness of the problem of plastic through interactive workshops, talks and through music performances, which she will be giving at schools, companies, hostels and so on. Having completed a pilot of the project in Europe last year, she is now ready to embark on her project in South America. With her project she aims to inspire children and adults and stimulate their pro-environmental behaviour. Jorien wants to strengthen people’s internal locus of control and show them that their actions can bring about change. Many political and governmental decisions are made due to the general public demanding change from their policy-makers and the businesses they engage with. \"‘Sail to the COP’ is a bottom-up movement as well which shares my vision on how to tackle the deterioration of the environment and therefore I am glad that with my project 'Eco Bus America' I am a partner and a participant on board and that I can take part of the 'Think-tank' to think about solutions together with the others!\"",
        nationality: "🇳🇱",
        age: 28,
    },
    {
        name: "Tori Tsui",
        image: "Tori.jpg",
        quote: "Sail To The Cop sets the tone for conversations around sustainable travel in the time of a climate emergency. To be part of this is a huge opportunity to raise the profile of much-needed conversations surrounding travel.", //as a representative of the Stella McCartney Foundation is
        bio: "Tori is an environmental and wildlife filmmaker, photographer and activist from Hong Kong based in the UK.\n" +
            "In 2015 she graduated with a Masters of Research in Ecology, Evolution and Conservation from Imperial College London which laid the groundwork for her interest in climate science and sustainability. \n",
        // "She was recently featured as one of Stella McCartney’s environmental voices for change in her 2019 Winter Campaign, with a film narrated by Jane Goodall. She will be joining Sail To The Cop as a partner of the Stella McCartney Foundation which supports environmental initiatives around the world.",
        nationality: "🇬🇧🇭🇰",
        age: 26,
    },
    {
        name: "Peter Paul Vossepoel",
        image: "Peter Paul.jpg",
        bio: <>Peter Paul is founder of the Belgium no-fly campaign <a href="https://zomerzondervliegen.be/"
                                                                       target="_blank">Zomer Zonder Vliegen</a>, which
            inspires to travel in a more sustainable way.. After completing his master's degree in environmental
            sciences at the University of Antwerp, where he wrote his thesis on awareness campaigns, Peter Paul started
            working in environmental education. As a project manager for the organization <a
                href="https://www.goodplanet.be/" target="_blank">GoodPlanet</a> he promotes sustainable mobility (with
            a focus on bicycles, his Dutch roots show) at schools, organizations and for the general public. On behalf
            of the federal government he teaches as a ‘<a
                href="https://www.klimaat.be/nl-be/federale-dienst/activiteiten/educatie/klimaatcoaches/"
                target="_blank">Climate Coach</a>’ about the transition to a carbon neutral future at high schools
            across Belgium. In his spare time Peter Paul is involved in several organizations in the climate movement
            and aims to challenge the status quo and push for a more ambitious climate policies.</>,
        nationality: "🇧🇪",
        age: 31,
    },
    {
        name: "Eline Vranken",
        image: "Eline.jpg",
        quote: "Our spaceball Earth is a magical place. Travelling consciously is an important change we need to make if we want to continue admiring this place. I am deeply excited to start the adventurous process of building a better world, adding some art to this irresistible revolution!",
        bio: <>Eline is the initiator of <a href="https://www.facebook.com/stemopjemoeder/" target="_blank">Je
            moeder</a>; an initiative making EarthPropaganda using upcycled shirts. She’s using civil disobedience and
            direct action as powerful tools to change the world, adding beauty and fun. She is experimenting and mixing
            various elements such as magical symbolic street theater, ceremonies that tap into the relationship with
            ourselves and Earth, creating interactive artistic installations, costumes, social experiments & music, all
            preferably with an absurdistic twist and dose of ice-breaking humour.
            Eline is also involved in setting up artspaces that serve as a community hub around the COPs. These are
            informal spaces/oases to connect the movement, to gain and teach skills with which we reframe the narrative
            and speak to the imagination. During COP24 she set up the <a href="https://weartivistsatsea.wordpress.com"
                                                                         target="_blank">Jakarta JungleHouse</a>, as
            part of the expansion of the <a href="https://artivistnetwork.org/" target="_blank">Artivist Network</a>, a
            collective of autonomous artivists.</>,
        nationality: "🇳🇱",
        age: 32,
    },
    {
        name: "Anuna de Wever",
        image: "Anuna.jpg",
        quote: "This sailing trip to the COP25 is extremely important because firstly we are making a statement saying it's unbelievable aviation is the only way of transport worldwide and it's globally exepted although it's harming our world and future. Apart from that we'll visit the amazon forest trying to put international pressure and support the indigenous tribes. The COP will be the last stop where the message of the youth and our future will finally be delivered. ",
        nationality: "🇧🇪",
        age: 18,
    },
    {
        name: "Hannah Wiemers",
        image: "Hannah.jpg",
        quote: "Travel expands the horizon. When sailing, in the truest sense of the word. With Sail to the COP I want to use this constantly expanding horizon to spin ideas with others, overcome borders, stand up for my values and raise my voice.",
        bio: "Hannah is studying in Eberswalde, Germany, for a Master's degree in Sustainable Management. After completing her Bachelor's degree in Business Administration, she gained experience in human resources and volunteered at a youth farm. She is particularly moved by the climate crisis and the social injustice it causes all over our planet. Travel should not be a question of privileges and, above all, should not happen on the backs of the less privileged. However, it is important to her not to blame individuals, but much more get to the bottom of structural injustice and draw attention to it.",
        nationality: "🇩🇪",
        age: 28,
    },
    {
        name: "Constanze Wouters",
        image: "Constanze.jpg",
        bio: "Constanze Wouters is a filmmaker. Her sensitivity to rhythm makes that she feels most at ease in the editing studio. Aside from filmmaking, she likes educational work. She has been giving ecology classes to kids, and performance workshops to youngsters. With her own collective she organises events on gender & sexuality. She is a frequent runner who likes to dance, but who also appreciates the peace & quiet of a camping site.",
        nationality: "🇧🇪",
        age: 26,
    },
    // {
    //     name: "",
    //     image: "",
    //     quote: "",
    //     bio: "",
    // },

];

let participantRows = [];
let participantRowsMobile = [];
let row = -1;
let rowMobile = -1;
for (let i = 0; i < participants.length; i++) {
    if (i % 4 === 0) {
        row++;
        participantRows[row] = [];
    }
    participantRows[row].push(participants[i]);

    if (i % 3 === 0) {
        rowMobile++;
        participantRowsMobile[rowMobile] = [];
    }
    participantRowsMobile[rowMobile].push(participants[i])
}

let homeTeamRows = [];
let homeTeamRowsMobile = [];
let homeTeamRow = -1;
let homeTeamRowMobile = -1;
for (let i = 0; i < homeTeam.length; i++) {
    if (i % 4 === 0) {
        homeTeamRow++;
        homeTeamRows[homeTeamRow] = [];
    }
    homeTeamRows[homeTeamRow].push(homeTeam[i]);

    if (i % 3 === 0) {
        homeTeamRowMobile++;
        homeTeamRowsMobile[homeTeamRowMobile] = [];
    }
    homeTeamRowsMobile[homeTeamRowMobile].push(homeTeam[i])
}

class ParticipantsList extends React.Component {

    state = {
        open: null,
    };

    render() {
        return <div className="about">

            <div className="ui vertical stripe segment padded first ">
                <div className="ui top center aligned grid container centered">
                    <div className="row">
                        <div className="column fourteen wide center aligned">

                            <Image src={"/resources/images/DSC09915.jpg"} size="huge" />

                            <h1 className="bright">The Sail to the COP participants</h1>
                        </div>
                    </div>

                    {/*<div className="row">*/}

                    {/*    {participantRows.map((row, j) =>*/}
                    {/*        participantRows[j].map((participant, i) =>*/}
                    {/*            <div*/}
                    {/*                className={"three wide column large person" + ((this.state.open === participant.name) ? " active" : "")}*/}
                    {/*                key={"participantPerson" + j + "-" + i}*/}
                    {/*                onMouseEnter={() => this.setState({open: participant.name})}>*/}
                    {/*                <h3>&nbsp;</h3>*/}
                    {/*                <img src={"resources/images/participants/" + participant.image}*/}
                    {/*                     />*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*</div>*/}

                </div>
                <div className="mobile or lower hidden">
                    <div className="ui top center aligned grid container centered">
                        {participantRows.map((row, j) =>
                            [<div className="row persons" key={"participantRow" + j}>

                                {participantRows[j].map((participant, i) =>
                                    <div
                                        className={"four wide column large person" + ((this.state.open === participant.name) ? " active" : "")}
                                        key={"participantPerson" + j + "-" + i}
                                        onMouseEnter={() => this.setState({open: participant.name})}>
                                        <h3>&nbsp;</h3>
                                        <img src={"resources/images/participants/" + participant.image}
                                             className="person"/><br/>
                                        <h3>{participant.name}</h3>
                                        <span>
                                            {participant.nationality}
                                            ・
                                            {participant.age && participant.age + " years"}
                                        </span>
                                    </div>
                                )}

                            </div>,
                                <div className="row persons" key={"participantRowSecond" + j}>
                                    <div className="column fourteen wide participantDetails">
                                        {participantRows[j].map((participant, i) =>
                                            this.state.open === participant.name &&
                                            <div className="person" key={"participantPersonDetails" + j + "-" + i}>
                                                {participant.quote && <blockquote className="large">
                                                    "{participant.quote}"
                                                </blockquote>
                                                }
                                                <p>
                                                    {participant.bio}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>])}
                    </div>
                </div>

                <div className="mobile only">
                    <div className="ui top center aligned grid container centered">
                        {participantRowsMobile.map((row, j) =>
                            [<div className="row persons mobile" key={"participantRow" + j}>

                                {participantRowsMobile[j].map((participant, i) =>
                                    <div
                                        className={"five wide column large person" + ((this.state.open === participant.name) ? " active" : "")}
                                        key={"participantPerson" + j + "-" + i}
                                        onMouseEnter={() => this.setState({open: participant.name})}>
                                        <h3>&nbsp;</h3>
                                        <img src={"resources/images/participants/" + participant.image}
                                             className="person"/><br/>
                                        <h3>{participant.name}</h3>
                                        <span>
                                            {participant.nationality}
                                            ・
                                            {participant.age && participant.age + " years"}
                                        </span>
                                    </div>
                                )}

                            </div>,
                                <div className="row persons mobile" key={"participantRowSecond" + j}>
                                    <div className="column fourteen wide participantDetails">
                                        {participantRowsMobile[j].map((participant, i) =>
                                            this.state.open === participant.name &&
                                            <div className="person" key={"participantPersonDetails" + j + "-" + i}>
                                                {participant.quote && <blockquote className="large">
                                                    "{participant.quote}"
                                                </blockquote>
                                                }
                                                <p>
                                                    {participant.bio}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>])}
                    </div>
                </div>

                <div className="ui top center aligned grid doubling container centered">

                    <div className="row">
                        <div className="column fourteen wide center aligned">
                            <h1 className="bright">Organizing Team</h1>
                        </div>
                    </div>

                    <div className="row persons">

                        {organizers.map((participant, i) =>
                            <Grid.Column mobile={6} largeScreen={4}
                                         className={" large person" + ((this.state.open === participant.name) ? " active" : "")}
                                         key={"participantPersonOrg-" + i}
                                         onMouseEnter={() => this.setState({open: participant.name})}>
                                <h3>&nbsp;</h3>
                                <img src={"resources/images/" + participant.image}
                                     className="person"/><br/>
                                <h3>{participant.name}</h3>
                                <br/>
                                {/*<h5>{participant.function}</h5>*/}
                                <a href={"mailto:" + participant.mail}
                                   className="mail">{participant.mail}</a><br/>
                                <span>
                                    {participant.nationality}
                                    ・
                                    {participant.age && participant.age + " years"}
                                </span>
                            </Grid.Column>
                        )}

                    </div>

                    <div className="row persons">
                        <div className="column fourteen wide participantDetails">
                            {organizers.map((participant, i) =>
                                this.state.open === participant.name &&
                                <div className="person" key={"participantPersonDetailsOrg-" + i}>
                                    {participant.quote && <blockquote className="large">
                                        "{participant.quote}"
                                    </blockquote>
                                    }
                                    <p>
                                        {participant.bio}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="row">
                        <div className="column fourteen wide center aligned">
                            <Link to="/project/about" className="ui button primary">
                                Read more&nbsp;
                                <i className="ui icon arrow right"/>
                            </Link>
                        </div>
                    </div>

                </div>


                <div className="ui top center aligned grid doubling container centered">

                    <div className="row">
                        <div className="column fourteen wide center aligned">
                            <h1 className="bright">Madrid Representatives</h1>
                            <p>
                                After the relocation of COP25 from Chile to Madrid, Spain, the Sail to the COP team has been expanded with a team of representatives from Europe that will bring our story to the COP in Madrid.
                            </p>
                        </div>
                    </div>
                </div>


                <div className="mobile or lower hidden">
                    <div className="ui top center aligned grid container centered">
                        {homeTeamRows.map((row, j) =>
                            [<div className="row persons" key={"participantRow" + j}>

                                {homeTeamRows[j].map((participant, i) =>
                                    <div
                                        className={"four wide column large person" + ((this.state.open === participant.name) ? " active" : "")}
                                        key={"participantPerson" + j + "-" + i}
                                        onMouseEnter={() => this.setState({open: participant.name})}>
                                        <h3>&nbsp;</h3>
                                        <img src={"resources/images/participants/" + participant.image}
                                             className="person"/><br/>
                                        <h3>{participant.name}</h3>
                                        <span>
                                            {participant.nationality}
                                            ・
                                            {participant.age && participant.age + " years"}
                                        </span>
                                    </div>
                                )}

                            </div>,
                                <div className="row persons" key={"participantRowSecond" + j}>
                                    <div className="column fourteen wide participantDetails">
                                        {homeTeamRows[j].map((participant, i) =>
                                            this.state.open === participant.name &&
                                            <div className="person" key={"participantPersonDetails" + j + "-" + i}>
                                                {participant.quote && <blockquote className="large">
                                                    "{participant.quote}"
                                                </blockquote>
                                                }
                                                <p>
                                                    {participant.bio}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>])}
                    </div>
                </div>

                <div className="mobile only">
                    <div className="ui top center aligned grid container centered">
                        {homeTeamRowsMobile.map((row, j) =>
                            [<div className="row persons mobile" key={"participantRow" + j}>

                                {homeTeamRowsMobile[j].map((participant, i) =>
                                    <div
                                        className={"five wide column large person" + ((this.state.open === participant.name) ? " active" : "")}
                                        key={"participantPerson" + j + "-" + i}
                                        onMouseEnter={() => this.setState({open: participant.name})}>
                                        <h3>&nbsp;</h3>
                                        <img src={"resources/images/participants/" + participant.image}
                                             className="person"/><br/>
                                        <h3>{participant.name}</h3>
                                        <span>
                                            {participant.nationality}
                                            ・
                                            {participant.age && participant.age + " years"}
                                        </span>
                                    </div>
                                )}

                            </div>,
                                <div className="row persons mobile" key={"participantRowSecond" + j}>
                                    <div className="column fourteen wide participantDetails">
                                        {homeTeamRowsMobile[j].map((participant, i) =>
                                            this.state.open === participant.name &&
                                            <div className="person" key={"participantPersonDetails" + j + "-" + i}>
                                                {participant.quote && <blockquote className="large">
                                                    "{participant.quote}"
                                                </blockquote>
                                                }
                                                <p>
                                                    {participant.bio}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>])}
                    </div>
                </div>


            </div>
        </div>;
    }
}

export default ParticipantsList;

import React from 'react';
import Newsletter from "../../newsletter";

function Act() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column sixteen wide center aligned">
                            <h1>Act</h1>

                            <p>
                                Want to get involved in Sail to the COP? We can always use your help, in preparation of the trip or during the COP for example. Don't hesitate to send us an e-mail if you might be able to support us in any way!
                            </p>


                        </div>
                    </div>


                    <Newsletter />

                </div>
            </div>

        </div>
    );
}

export default Act;

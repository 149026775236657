
export const solutions = [

    {
        title: "Ridiculously Cheap Flights",
        heading: "Positive Framing instead of shaming.",
        summary: "By highlighting the positive aspects of fair and sustainable travel instead of shaming certain behaviour, we contribute to upcoming travel narratives. With the social and environmental impacts of travel choices at their core, these narratives embrace the gains of responsible travel. We support travellers in their transition toward conscious travel decisions.",
        wg: "Culture",
    },
    {
        title: "NDC Enhancement",
        heading: "It’s time for ambition.",
        summary: "National climate commitments, formally called Nationally Determined Contributions (NDCs), barely include measures on emission reduction in the travel and aviation industries. We have developed proposals for the travel sector to be included in every NDC for 2020.",
        wg: "Policy",
    },
    {
        title: "ICAO - CORSIA",
        heading: "ICAO's ‘Green Growth Strategy’.",
        summary: "The Carbon Offsetting and Reduction Scheme for International Aviation (CORSIA) by the UN-body ICAO, is not in line with the 1.5°C goal of the Paris Agreement. We demand reforms to CORSIA and inform citizens through a podcast.",
        wg: "Policy",
    },
    {
        title: "Tikls",
        heading: "Travel unique – be surprised.",
        summary: "Tikls presents an app and a website allowing users to book surprise holiday packages for authentic, local travel experiences.",
        wg: "Innovation",
    },
    {
        title: "Rail to the COP",
        heading: "From sail to rail.",
        summary: "An idea born on the Atlantic, Rail to the COP returns home to ramp up day and night trains in Europe. Next stop: COP26 in Glasgow.",
        wg: "Innovation",
    },
    {
        title: "Stories of Resilience and Resistance",
        heading: "Hear the unheard.",
        summary: "Uncovering personal experiences, each story in this handbook relates to the injustices of the travel industry. Hear the voices of the marginalized, learn about the exploitative practices of the industry, and get inspired by stories of resilience and resistance.",
        wg: "Climate Justice",
    },
    {
        title: "Lobby Against Air Travel Advertisement",
        heading: "Change what we see.",
        summary: "Mass-advertisement on air travel is currently shaping our travel narratives. Our lobby against air travel advertisements, incentive schemes, and frequent flyer programmes will help create and support new narratives for responsible travel.",
        wg: "Media",
    },
    {
        title: "EU Facilitation",
        heading: "EU – Stop Favouring Aviation!",
        summary: "Through lenient policies and state aid, the EU favours the aviation industry over its public transportation counterparts. Benefits through tax exemptions and free carbon emission allowances need to be halted.",
        wg: "Policy",
    },
    {
        title: "ExPlane",
        heading: "ExPlane your travel policies.",
        summary: "We believe universities should serve as role models within our society when it comes to fair and sustainable travel. ExPlane is a platform that includes a toolkit, a network, and a mentorship program to support staff and students demanding change within their own institution.",
        wg: "Education",
    },
    {
        title: "- Just Travel? - Campaign",
        heading: "It’s not just travel.",
        summary: "Just Travel? combines urgent climate action with movements of social justice. By campaigning for a Europe-wide kerosene tax and using the funds for investments in public transportation, Just Travel? urges the development of a socially-just travel industry.",
        wg: "Climate Justice",
    },
    {
        title: "Confession Sessions",
        heading: "Sail to the COP exposed.",
        summary: "In the video series Confession Sessions, the participants of Sail to the COP open up and ‘get naked’ about past travel behaviour. They share personal stories and reflect on their experiences.",
        wg: "Culture",
    },
    {
        title: "Massive Open Online Course",
        heading: "Learn More – Travel Less.",
        summary: "Behaviour change starts with awareness. We will make this first step accessible through a Massive Open Online Course (MOOC). Through a partnership with a university, we will create an educational programme on fair and sustainable travel, available for everyone.",
        wg: "Education",
    },
    {
        title: "Night Train Research",
        heading: "Night travel is the new travel.",
        summary: "Night trains can be part of the solution to decrease the emissions of the transport sector. But what needs to be done to meet the increasing demand? This research aims to discover bottlenecks and come up with potential solutions.",
        wg: "Education",
    },
    {
        title: "GoWare",
        heading: "Plan – Book – Go Green.",
        summary: "GoWare is a sustainable work-travel tool for planning, monitoring and reporting business trips. The software assists searching for travel itineraries and monitors various metrics (such as distance, emissions, cost, time, efficient working time, and comfort). A reporting functionality helps companies to stay on track with their sustainability goals.",
        wg: "Innovation",
    },
    {
        title: "VirtualCOP",
        heading: "Game to the COP.",
        summary: "VirtualCOP or ‘vCOP’ is an RPG (Role Playing Game)-style virtual conference experience, designed to increase the accessibility, inclusivity and positive impact of UNFCCC events. vCOP allows virtual access to events, negotiations, expositions, etc., and is accessible from any laptop or desktop computer with an adequate internet connection.",
        wg: "Innovation",
    },
    {
        title: "Aeroship",
        heading: "Zeppelins of the future.",
        summary: "Airships largely disappeared due to the emergence of, and market take-over by, airplanes. After a number of tragic accidents, the demand ceased completely in the 1940s. However, a new era for airships has arrived! With increased interest in sustainable travel alternatives and advances in new and safer materials, airships may once again rise to the occasion.",
        wg: "Innovation",
    },



];
import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Button, Card, Form, Input} from "semantic-ui-react";

class Contact extends React.Component {

    state = {
        loading: false,
        loading2: false,
        sent: false,
        sent2: false,

        name: "",
        email: "",
        email2: "",
        phone: "",
        comments: "",

    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    submit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let _this = this;

        let db = firebase.firestore();
        let values = {...this.state};
        values.createdAt = new Date();
        values.source = "sailtothecop.com";

        db.collection("forms").add(values)
            .then(function () {
                _this.setState({loading: false, sent: true});
            })
            .catch(function (error) {
                alert("Something went wrong, please let us know through info@yfst.org");
            });

        return false;
    }

    submit2(e) {
        e.preventDefault();
        this.setState({loading2: true});
        let _this = this;

        let {email2} = this.state;

        let db = firebase.firestore();
        let values = {email: email2};
        values.createdAt = new Date();
        values.source = "sailtothecop.com";

        db.collection("forms").add(values)
            .then(function () {
                _this.setState({loading2: false, sent2: true});
            })
            .catch(function (error) {
                alert("Something went wrong, please let us know through info@yfst.org");
            });

        return false;
    }

    render() {

        const {loading, name, email, phone, comments, sent, loading2, sent2, email2} = this.state;

        return (
            <div className="contact">

                <div className="ui vertical stripe segment padded first white-background">
                    <div className="ui top aligned stackable grid container centered">
                        <div className="row">
                            <div className="fourteen wide column">
                                <h1>Contact Sail to the COP</h1>
                            </div>
                        </div>
                        <div className="row centered">
                            <div className="twelve wide column center aligned">
                                <a href="mailto:info@sailtothecop.com"
                                   className="ui button basic primary non-capitalized">
                                    <i className="ui icon mail outline"></i>
                                    info@sailtothecop.com
                                </a>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <a href="tel:0031633149435" className="ui button basic primary">
                                    <i className="ui icon phone"></i>
                                    +31 6 33 14 94 35
                                </a>
                            </div>
                        </div>
                        <br/>
                        <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"
                             style={{marginTop: '-10px', marginBottom: '-10px'}}/>
                        <div className="row">
                            <div className="ten wide column">

                                <Card fluid>
                                    <Card.Content>

                                        <h3>Sign up to our newsletter</h3>
                                        <p>We will keep you updated on our mission, actions and results.</p>

                                        {sent2 ?
                                            <div className="ui message green">
                                                <div className="header">
                                                    Thank you!
                                                </div>
                                            </div>
                                            :
                                            <Form className="ui form" onSubmit={(e) => this.submit2(e)} loading={loading2}>
                                                <Input style={{minWidth: '300px', marginRight: '10px'}} type="text" name="email2" placeholder="E-mail address"
                                                       value={email2} onChange={this.handleChange.bind(this)}/>
                                                <button className="ui button " type="submit">Submit</button>
                                            </Form>
                                        }
                                {/*        <h3>Subscribe to our newsletter</h3>*/}
                                {/*        <div id="mc_embed_signup">*/}
                                {/*            <Form*/}
                                {/*                action="https://yfst.us7.list-manage.com/subscribe/post?u=bc7e1c7362ae5e7d5573e4deb&amp;id=7422f03a67"*/}
                                {/*                method="post" id="mc-embedded-subscribe-form"*/}
                                {/*                name="mc-embedded-subscribe-form"*/}
                                {/*                className="validate" target="_blank" noValidate>*/}
                                {/*                <div id="mc_embed_signup_scroll">*/}

                                {/*                    <Input type="email" name="EMAIL" className="email"*/}
                                {/*                           id="mce-EMAIL" style={{width: '60%'}}*/}
                                {/*                           placeholder="E-mail address" required/>&nbsp;*/}
                                {/*                    <Button type="submit" value="Subscribe" name="subscribe"*/}
                                {/*                            id="mc-embedded-subscribe"*/}
                                {/*                            className="button">Subscribe</Button>*/}

                                {/*                    <div style={{position: "absolute", left: "-5000px"}}*/}
                                {/*                         aria-hidden="true">*/}
                                {/*                        <input type="text" name="b_bc7e1c7362ae5e7d5573e4deb_7422f03a67"*/}
                                {/*                               tabIndex="-1" value=""/>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </Form>*/}
                                {/*        </div>*/}
                                    </Card.Content>
                                </Card>


                                <div className="ui segment active" data-tab="first">

                                    <h3>Contact Us</h3>

                                    <p>We will keep you updated on our mission, actions and results. We would also love
                                        to
                                        hear how you
                                        can
                                        support us.</p>

                                    {sent ?
                                        <div className="ui message green">
                                            <div className="header">
                                                Thank you!
                                            </div>
                                            <p>We'll be in touch.</p>
                                        </div>
                                        :
                                        <Form className="ui form" onSubmit={(e) => this.submit(e)} loading={loading}>
                                            <div className="field">
                                                <div className="fields">
                                                    <div className="seven wide field">
                                                        <label>Name</label>
                                                        <input type="text" name="name" placeholder="Name" value={name}
                                                               onChange={this.handleChange.bind(this)}/>
                                                    </div>
                                                    <div className="nine wide required field">
                                                        <label>E-mail address</label>
                                                        <input type="text" name="email" placeholder="E-mail address"
                                                               value={email} onChange={this.handleChange.bind(this)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field">
                                                <div className="fields">
                                                    <div className="seven wide field">
                                                        <label>Phone number</label>
                                                        <input type="text" name="phone"
                                                               placeholder="Phone number (optional)" value={phone}
                                                               onChange={this.handleChange.bind(this)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*<div className="inline field">*/}
                                            {/*    <div className="ui checkbox">*/}
                                            {/*        <input type="checkbox" tabIndex="0" className="hidden" name="help" />*/}
                                            {/*        <label>Call me back</label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            {/*<div className="inline field">*/}
                                            {/*    <div className="ui checkbox">*/}
                                            {/*        <input type="checkbox" tabIndex="0" className="hidden" name="sponsor" />*/}
                                            {/*        <label>I might be interested in sponsoring Sail to the COP</label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div className="field">
                                                <label>Comments, questions or ideas?</label>
                                                <textarea rows="2" name="comments" value={comments}
                                                          onChange={this.handleChange.bind(this)}/>
                                            </div>
                                            <button className="ui button primary" type="submit">Submit</button>
                                        </Form>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Contact;

import React from 'react';
import {Image} from "semantic-ui-react";

function OutputVision() {
    return (
        <div className="output">


            <div className="ui vertical stripe padded ">
                <div className="ui top stackable grid">
                    <div className="row">
                        <div className="column fourteen wide">
                            <h1>The Future of Travel - Our Vision & Values</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column one wide">
                        </div>
                        <div className="column nine wide">
                            <h2>Intro/Disclaimer</h2>
                            <p>
                                In order to avoid an irreversible climate crisis, the travel industry needs to
                                drastically change course within the next decade. We from Sail to the COP are confident
                                that this transformation is not only very possible, but that it entails great
                                opportunities, innovation potential and a wide variety of benefits for society.
                            </p>
                            <p>
                                Our vision and values are the result of a carefully designed process, meant to represent
                                the collective mindset of our group. Through these, we aim to illustrate our ideal
                                travel industry and habits, while considering the interests of all travel-related
                                stakeholders. We want to propose a set of goals to strive for and create a guiding
                                framework for decision making and to take action.
                            </p>
                            <p>
                                We are aware of the fact that, as a group of primarily European people, our position in
                                the world is a highly privileged one. Though our concern is a global one, our vision and
                                solutions have been created with Europe in mind and we are keen to learn from other
                                ideas on reshaping travel from around the world. Nevertheless we intend to be a source
                                of inspiration, an accelerating impulse, to unite behind the science underlying the
                                Paris Agreement and to collectively reshape the future of travel.

                            </p>

                        </div>

                        <div className="column six wide">
                            <Image src="/resources/images/output/sailfullimage.jpg" />
                        </div>

                    </div>


                    <div className="row">
                        <div className="column one wide">
                        </div>
                        <div className="column five wide">
                            <Image src="/resources/images/output/holdworld.jpg" />
                        </div>
                        <div className="column nine wide">

                            <h2>Vision</h2>
                            <p className="bold">
                                We imagine a world, in which travel demand is reduced and travelling is always
                                meaningful. We dream of a reality in which all relevant actors cooperate and take bold
                                action, fostering a fair and sustainable travel industry. We envision a future in which
                                travel is a force for good and does no harm to the travelers themselves, the communities
                                that welcome them and the environment.

                            </p>

                            <h3>Governments & Institutions:</h3>
                            <p>
                                We envision a reality, in which governments and institutions follow the scientific evidence and facilitate a global system change. Educational institutions should be the role models in making balanced travel choices and guide the change required within society. Ambitious policies, financial instruments and incentives are introduced and implemented. Travelling is restrictively regulated until low-impact alternatives and fair practices are adopted that are affordable for all. More transparency within the travel industry leads to a re-evaluation and rebalancing of all stakeholders’ interests. The most affected, such as frontline communities or the natural environment, receive rights, a voice in decision-making processes and compensation for past damages. Sufficient funding for research, innovation and development of sustainable solutions and (digital) infrastructure is secured. Governments and institutions recognize and act upon the intimate relationship between their (in)actions, the health of the natural world and the wellbeing of people.
                            </p>




                        </div>
                        <div className="column one wide" />
                    </div>

                    <div className="row">
                        <div className="column one wide" />

                        <div className="column nine wide">


                            <h3>Organizations & Companies:</h3>
                            <p>
                                We believe in a new age of business travel, that relies on the principle of
                                indispensability and utilizes remote attendance technologies. Travel options which
                                prioritize the mental and physical health of employees and the environment become the
                                new standard. Organizations and companies appreciate the benefits of alternative travel
                                options, such as productive time on trains. They acknowledge the external effects of
                                business travel and realize their responsibility and act accordingly in becoming the
                                pioneers, innovators and investors of solutions for a fair and sustainable travel
                                industry.
                            </p>

                            <p>
                                Travel businesses pay for the polluting damages of the past, fairly distribute their
                                profits among their employees and ensure that the interests of local communities in
                                popular travel destinations are considered in their strategy. Furthermore, the necessary
                                information for booking and planning sustainable trips is made accessible to the public,
                                driving an increased demand for future-proof travelling.
                            </p>

                            <h3>Travellers:</h3>

                            <p>
                                We envision a future where individuals travel sustainably and with purpose. Unnecessary
                                travel is avoided while virtual, local and low impact alternatives gain popularity.
                                Conscious travellers inspire each other to act responsibly, out of compassion for the
                                most affected and gratitude for the abundance of life on this planet. People choose to
                                travel in ways that enable ecosystems to heal, cultures to stay authentic and employees
                                of the travel industry to be treated fairly. Based on these new standards, future
                                generations earn the chance to also experience the wonders of nature and our societies.
                            </p>

                        </div>
                        <div className="column five wide">
                            <Image src="/resources/images/output/train-2.jpg" />
                        </div>
                        <div className="column one wide" />
                    </div>

                    <div className="row">
                        <div className="column sixteen wide">
                            <img src="/resources/images/output/ship-small.jpg" className="sun"/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default OutputVision;

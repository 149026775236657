import React, {Component} from 'react';
import {Image, Label, Loader} from "semantic-ui-react";
import firebase from 'firebase/app';
import 'firebase/firestore';
import Markdown from "react-markdown";
import {Link} from "react-router-dom";

let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class Blog extends React.Component {

    state = {
        loading: true,
        articles: null,
    };

    componentDidMount() {

        let _this = this;
        let db = firebase.firestore();
        db.collection("articles")
            .orderBy("date", "desc")
            .where("visible", "==", true)
            .get().then(snapshot => {
            let articles = [];
            snapshot.forEach(doc => {
                // console.log(doc.data().date);
                articles.push({
                    ...doc.data(),
                    id: doc.id,
                    date: new Date(doc.data().date.seconds * 1000),
                    // date: new Date(doc.data().createdAt.seconds * 1000)
                })
            });

            _this.setState({
                articles,
                loading: false,
            })
        });
    }

    render() {

        const {loading, articles} = this.state;

        return <div className="blogs">


            <div className="ui vertical segment first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column sixteen wide center aligned">
                            {/*<h1>Blog</h1>*/}
                        </div>
                    </div>

                </div>
            </div>
            <div className="ui vertical stripe segment first colored-background" style={{clear: "both"}}>
                <div className="ui top aligned stackable grid centered">
                    <div className="row">
                        {articles ? articles.map((item, i) => {

                                const images = (item && item.images) || [];
                                let imageUrls = images.map((image, nr) => image.url);

                                return <Link className="column four wide center aligned" to={"/blog/" + item.slug} key={"blogItem" + i} as={"div"}>
                                    <div className="imageContainer">
                                        {imageUrls.length > 0 && <img src={imageUrls[0]}/>}
                                    </div>
                                    {item.author &&
                                    <Label basic
                                           className="author">{item.author}</Label>
                                    }
                                    <Label basic
                                           className="date">{item.date.getDate()} {months[item.date.getMonth()]} {item.date.getFullYear()}</Label>

                                    <h2>{item.title}</h2>
                                </Link>;
                            })
                            :
                            <Loader active={loading}/>
                        }
                    </div>
                </div>

            </div>

        </div>;
    }
}

export default Blog;

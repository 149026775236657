
export const opportunities = [

    {
        title: "Education", //checked
        color: "#e4b89a",
        opportunities: [
            {
                text: "Flight free Erasmus programs for students",
            },
            {
                text: "Incorporate sustainable travel in education",
            },
        ]
    },

    {
        title: "Innovation", //Checked
        color: "#aac1d9",
        opportunities: [
            {
                text: "Ramping up night travel (bus and train)",
            },
            {
                text: "Rethink business models of sustainable alternative travel modes ",
            },
            {
                text: "Redesigning night-train carriages ",
            },
            {
                text: "Incentivise people to stay closer to their homes and enjoy local holidays",
            },
            {
                text: "Incentivising sustainable travel (or non-travel) in organizations",
            },
            {
                text: "Exploring the viability of zeppelins (airships) ",
            },
        ]
    },

    {
        title: "Policy", //checked
        color: "#eed9a4",
        opportunities: [
            {
                text: "Shifting government budget allocation toward sustainable travel modes",
            },
            {
                text: "Reform of EU Emissions Trading System (ETS) to include aviation ",
            },
            {
                text: "Creation of a lobby group to implement our solutions in EU NDC enhancement",
            },
            {
                text: "Taxes: implementation of kerosene tax",
            },
            {
                text: "Make the UN’s framework to address emissions of aviation, CORSIA, much more ambitious",
            },
        ]
    },


    {
        title: "Media", //checked
        color: "#0fbbd4",
        opportunities: [
            {
                text: "Banning incentive air travel advertisement and frequent flyer programs",
            },
            {
                text: "Create awareness for consumers and promote alternative mindsets",
            },
        ]
    },
    {
        title: "Climate Justice", //checked
        color: "#c0d5b2",
        opportunities: [
            {
                text: "Making the injustices of the travel industry visible and transparent",
            },
            {
                text: "Spreading awareness on the link between climate justice and the travel industry within the climate movement",
            },
            {
                text: "Support local communities who are dependent on tourists and suffer from climate change",
            },
            {
                text: "Local communities create their own conditions on regulating travel",
            },
        ]
    },

    {
        title: "Other", //checked
        color: "#a0a0a0",
        opportunities: [
            {
                text: "Improve infrastructure for shared transportation",
            },
            {
                text: "Improve \"work while travelling facilities\" in sustainable transport modes (like trains)",
            },
            {
                text: "Inspire more young people to vote",
            },
            {
                text: "Cruise ships action: stay docked; and the refurbishment of cruise ships",
            },
            {
                text: "Shifting government budget allocations towards sustainable travel",
            },
            {
                text: "Holistic system thinking for a single European transport area, and integrated standards for sustainable travel",
            },
            {
                text: "Design educational travel programs and meaningful holidays",
            },
            {
                text: "Improve data sharing for sustainable travel modes, and thereby improve booking systems.",
            },
            {
                text: "Design and improve decision-making processes",
            },
            {
                text: "Creating a culture of meaningful exchange between visitors and local community ",
            },
            {
                text: "Eliminate the need of escape as a desire to travel",
            },
            {
                text: "Challenge and change existing narratives that perpetuate the desire of travel as a need",
            },
            {
                text: "Channel attention on mental health to change travel attitudes",
            },
            {
                text: "Make sustainable travel tv shows (like 3-op-reis-fair)",
            },
            {
                text: "Introduction of a point systems for sustainable travelling (e.g. eco-miles instead of airmiles)",
            },
            {
                text: "Setting up a ministry of travel where everyone who wants to fly should pass by to get a flight visa",
            },
        ]
    },

];
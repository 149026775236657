import React from "react";
import firebase from "firebase";

export default class Countdown extends React.Component {

    state = {
        diff: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    componentDidMount() {
        this.countdown.bind(this);
        this.timer = window.setInterval(this.countdown.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    countdown() {

        let t1 = new Date(2019, 9, 2, 14, 0, 0, 0);
        let t2 = new Date();
        let diff = (t1.getTime() - t2.getTime()) / 1000;
        let days = Math.floor(diff / (24*3600));
        let hours = Math.floor((diff - days * 24*3600) / 3600);
        let minutes = Math.floor((diff - days * 24*3600 - hours * 3600) / 60);
        let seconds = Math.floor((diff - days * 24*3600 - hours * 3600 - minutes * 60));

        this.setState({
            diff,
            days, hours, minutes, seconds
        });

    }

    render() {

        const {diff, days, hours, minutes, seconds} = this.state;

        let show = days > 0 && hours > 0 && minutes > 0 && seconds > 0;

        return diff && show && <div className="countdown">
            <div className="numbers">
                <div className="number">
                    <span className="digits">{days}</span>
                    <span>days</span>
                </div>
                <div className="number">
                    <span className="digits">{hours}</span>
                    <span>hours</span>
                </div>
                <div className="number">
                    <span className="digits">{minutes}</span>
                    <span>minutes</span>
                </div>
                <div className="number">
                    <span className="digits">{seconds}</span>
                    <span>seconds</span>
                </div>
            </div>

            <div className="description">
                <a href="https://www.facebook.com/events/889246751442406/" target="_blank">
                    Until departure from Amsterdam at October 2nd.
                </a>
            </div>
        </div>;
    }
}
import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import {Link, NavLink} from "react-router-dom";
import {Button, Card, Dimmer, Embed, Grid, Image, Label, Loader, Message, Popup, Progress} from "semantic-ui-react";
import {AmbassadorsRow, PartnersRow, SponsorsRow} from "./partners/ambassadors";
import DonatePage from "./support";
import Newsletter from "../newsletter";
import Countdown from "./Countdown";
import {Translate} from "react-localize-redux";

class Home extends Component {

    state = {
        loading: true,
        value: 0,
        total: 25000,
    };

    shipIconRef = React.createRef();
    mapBoxJourney = React.createRef();
    mapBoxDestination = React.createRef();
    casablancaRef = React.createRef();
    tenerifeRef = React.createRef();
    caboVerdeRef = React.createRef();
    recifeRef = React.createRef();
    rioRef = React.createRef();

    componentDidMount() {

        let _this = this;

        if (!window.ScrollMagic) return;

        try {

            // var widget = new window.Curator.Waterfall({
            //     container: '#curator-feed',
            //     feedId: "88030c50-1778-4e71-9d92-de4cb77b5f86"
            // });

            // new window.ScrollMagic.Scene({triggerElement: "#parallax1"})
            //     .setTween("#parallax1 > div", {y: "40%"}) //, ease: Linear.easeNone
            //     .addTo(new window.ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}}));

            // const flightpath = {
            //     first: { //below UK
            //         left: 560 - 40 - 280,
            //         top: 435 - 280 + 50,
            //     },
            //     second: { // Casablanca
            //         left: 560 - 40 - 290,
            //         top: 280,
            //     },
            //     // second: { // next to Africa
            //     //     left: 430 - 280,
            //     //     top: 704 - 210 - 50,
            //     // },
            //     third: { //Next to S-A
            //         left: 380 - 280,
            //         top: 550,
            //     },
            //     fourth: { // Montevideo --> Rio
            //         left: 360 - 280,
            //         top: 550,
            //     },
            //     fifth: { //Over land to Chile
            //         left: -50,
            //         top: 610,
            //     }
            // };
            //
            //
            // const controller2 = new window.ScrollMagic.Controller();
            //
            // const tween = new window.TimelineMax()
            //     .add(window.TweenMax.to(this.shipIconRef.current, 0.7, {
            //         css:
            //         flightpath.first,
            //         // ease: Power1.easeInOut
            //     }))
            //     .set(this.mapBoxJourney.current, {opacity: 1})
            //     .add(window.TweenMax.to(this.shipIconRef.current, 0.3, {
            //         css:
            //         flightpath.second,
            //         // ease: Power1.easeInOut
            //     }))
            //     .add(window.TweenMax.to(this.shipIconRef.current, 1, {
            //         css:
            //         flightpath.third,
            //         // ease: Power1.easeInOut
            //     }))
            //     .set(this.mapBoxDestination.current, {opacity: 1})
            //     .add(window.TweenMax.to(this.shipIconRef.current, 0.1, {
            //         css:
            //         flightpath.fourth,
            //         // ease: Power1.easeInOut
            //     }))
            //     // .set(this.shipIconRef.current, {})
            //     .add(window.TweenMax.to(this.shipIconRef.current, 1, {
            //         css:
            //         flightpath.fifth,
            //         // ease: Power1.easeInOut
            //     }));
            // // .set(document.getElementById("crowdfunding-button"), {opacity: 1});
            //
            // // build scene
            // new window.ScrollMagic.Scene({triggerElement: "#trigger", duration: 500, offset: 400})
            //     .setPin("#map-atlantic")
            //     .setTween(tween)
            //     .addTo(controller2)
            //     .on("progress", (e) => {
            //
            //         if (this.casablancaRef.current) this.casablancaRef.current.style.opacity = (e.progress > 0.32) ? 1 : 0.1;
            //         if (this.tenerifeRef.current) this.tenerifeRef.current.style.opacity = (e.progress > 0.34) ? 1 : 0.1;
            //         if (this.caboVerdeRef.current) this.caboVerdeRef.current.style.opacity = (e.progress > 0.38) ? 1 : 0.1;
            //         if (this.recifeRef.current) this.recifeRef.current.style.opacity = (e.progress > 0.48) ? 1 : 0.1;
            //         if (this.rioRef.current) this.rioRef.current.style.opacity = (e.progress > 0.61) ? 1 : 0.1;
            //         if (this.shipIconRef.current) this.shipIconRef.current.src = (e.progress > 0.7) ? "resources/images/bus.png" : "resources/images/np_boat_621434_003E78.png";
            //
            //     })

            //
            // const controller = new window.ScrollMagic.Controller({globalSceneOptions: {duration: 100}});
            //
            // // build scenes
            // new window.ScrollMagic.Scene({triggerElement: "#home-content-container"})
            //     .setClassToggle("#crowdfunding-button", "visible") // add class toggle
            //     .addTo(controller);


            new window.ScrollMagic.Scene({triggerElement: "#parallax1"})
                .setTween("#parallax1 > div", {y: "40%"}) //, ease: Linear.easeNone
                .addTo(new window.ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: "onEnter",
                        duration: "200%"
                    }
                }));


        } catch {
        }

    }


    render() {

        return (
            <div className="home">

                <div className="ui grid mobile only">
                    <div className="row centered">
                        {/*<Card style={{minWidth: '350px'}}>*/}

                        {/*    <Card.Content>*/}
                        {/*    <div className="header">*/}
                        {/*        /!*<Link to="/support"><h2 className="bright">Crowdfunding</h2></Link>*!/*/}
                        {/*    </div>*/}

                        {/*    <Dimmer active={loading} inverted>*/}
                        {/*        <Loader active={loading} inverted/>*/}
                        {/*    </Dimmer>*/}
                        {/*    <Progress value={value} total={total} indicating>*/}
                        {/*        {formatter.format(value)} / {formatter.format(total)}*/}
                        {/*    </Progress>*/}

                        {/*    {value ?*/}
                        {/*        <div className="crowdfunding-numbers">*/}
                        {/*            <div className="large-number">*/}
                        {/*                <span className="number">{Math.round(value / total * 100)}%</span>*/}
                        {/*                of goal*/}
                        {/*            </div>*/}
                        {/*            <div className="large-number">*/}
                        {/*                <span className="number">{hours}</span>*/}
                        {/*                hour{hours !== 1 ? "s" : ""} to go*/}
                        {/*            </div>*/}
                        {/*            <div className="large-number">*/}
                        {/*                <span className="number">{backers}</span>*/}
                        {/*                supporters*/}
                        {/*            </div>*/}
                        {/*        </div> : <div>*/}
                        {/*            <br/><br/>*/}
                        {/*        </div>}*/}

                        {/*    <div style={{clear: "both", marginTop: '6px'}}>*/}
                        {/*        <iframe width="315" height="315" src="https://www.youtube.com/embed/Jp-9Hb1xIHQ"*/}
                        {/*                frameBorder="0"*/}
                        {/*                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*                allowFullScreen/>}*/}
                        {/*    </div>*/}

                        {/*    <div style={{textAlign: "center", marginTop: '6px'}}>*/}
                        {/*        <Link to="/support" className="ui button primary">Support us!</Link>*/}
                        {/*    </div>*/}
                        {/*    </Card.Content>*/}

                        {/*</Card>*/}
                    </div>
                </div>

                <div className="masthead ui grid stackable">

                    <div className="background-image">

                        <div className="background"/>

                        {/*<Image alt="Our route to Chile" src="resources/images/atlantic-map-line.png" className="map-image" />*/}

                        {/*<div className="crowdfunding mobile hidden">*/}

                        {/*    <Card className="main-card">*/}

                        {/*        <Card.Content>*/}
                        {/*            <div className="header">*/}
                        {/*                <Link to="/support"><h2 className="bright">Crowdfunding</h2></Link>*/}
                        {/*            </div>*/}

                        {/*            <Dimmer active={loading} inverted>*/}
                        {/*                <Loader active={loading} inverted/>*/}
                        {/*            </Dimmer>*/}
                        {/*            <Progress value={value} total={total} indicating>*/}
                        {/*                {formatter.format(value)} / {formatter.format(total)}*/}
                        {/*            </Progress>*/}

                        {/*            {value ?*/}
                        {/*                <div className="crowdfunding-numbers">*/}
                        {/*                    <div className="large-number">*/}
                        {/*                        <span className="number">{Math.round(value / total * 100)}%</span>*/}
                        {/*                        of goal*/}
                        {/*                    </div>*/}
                        {/*                    <div className="large-number">*/}
                        {/*                        <span className="number">{days}</span>*/}
                        {/*                        days to go*/}
                        {/*                    </div>*/}
                        {/*                    <div className="large-number">*/}
                        {/*                        <span className="number">{backers}</span>*/}
                        {/*                        supporters*/}
                        {/*                    </div>*/}
                        {/*                </div> : <div>*/}
                        {/*                    <br/><br/>*/}
                        {/*                </div>}*/}

                        {/*                <div style={{clear: "both", marginTop: '6px'}}>*/}
                        {/*                    <iframe width="315" height="315" src="https://www.youtube.com/embed/Jp-9Hb1xIHQ"*/}
                        {/*                            frameBorder="0"*/}
                        {/*                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*                            allowFullScreen/>}*/}
                        {/*                </div>*/}

                        {/*            <div style={{textAlign: "center", marginTop: '6px'}}>*/}
                        {/*                <Link to="/support" className="ui button primary">Support us!</Link>*/}
                        {/*            </div>*/}

                        {/*        </Card.Content>*/}

                        {/*    </Card>*/}
                        {/*</div>*/}

                        <Grid className="homeGridNew" stackable>
                            <Grid.Row centered>
                                <Grid.Column width={7}>
                                    <div className="padding">
                                        {/*<Translate id="home.title" />*/}
                                        <h1>Change Course</h1>
                                        <div className="line" />
                                        <h2>
                                            <Translate id="home.title">towards fair and sustainable
                                                travel for all</Translate><br/>
                                        </h2>
                                        <p>
                                            The results of a youth-led sailing think tank – crossing the Atlantic Ocean towards COP25
                                        </p>

                                        <Button as={NavLink} to="/thinktank" size="big">Learn More</Button>

                                        <a href="https://www.sailtothecop.com/report" target="_blank" style={{float: "right"}}>
                                            <Button primary size="big">Download our report</Button>
                                        </a>

                                        <br /><br /><br />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <Image src="/resources/images/DSC05732.jpg" />
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src="/resources/images/DSC05811.jpg" />
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src="/resources/images/DSC09972.jpg" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <br style={{clear: "both"}} />


                        {/*<div id="home-gya" >*/}
                        {/*    <a href="http://www.gapyearatlantic.com" target="_blank"><img*/}
                        {/*        src="resources/images/190306-gapyearatlantic_logo-transparant-1280x288.png"*/}
                        {/*        className="logo"*/}
                        {/*        style={{maxWidth: '80%'}}/></a>*/}
                        {/*</div>*/}

                    </div>
                </div>


                {/*<div id="home-gya2" >*/}
                {/*    <a href="http://www.gapyearatlantic.com" target="_blank"><img*/}
                {/*        src="resources/images/190306-gapyearatlantic_logo-transparant-1280x288.png"*/}
                {/*        className="logo"*/}
                {/*        style={{maxWidth: '80%'}}/></a>*/}
                {/*</div>*/}

                {/*<DonatePage/>*/}

                <div id="trigger" className="mobile hidden"></div>
                <div id="map-atlantic" className="mobile hidden" style={{display: "none"}}>
                    <div id="map">

                        <a href="https://www.facebook.com/events/889246751442406/" target="_blank">
                            <Label className="map-label departure">Departure: 2 October 2019<br/>Amsterdam, The
                                Netherlands</Label>
                        </a>

                        <Popup position="bottom center"
                               trigger={<div className="map-point casablanca" ref={this.casablancaRef}/>}
                               content={"Casablanca - Mipai Light Us Conference"} inverted/>

                        <Popup position="bottom center"
                               trigger={<div className="map-point tenerife" ref={this.tenerifeRef}/>}
                               content={"Tenerife"} inverted/>

                        <Popup position="bottom center"
                               trigger={<div className="map-point caboVerde" ref={this.caboVerdeRef}/>}
                               content={"Cabo Verde"} inverted/>

                        <Popup position="bottom center"
                               trigger={<div className="map-point recife" ref={this.recifeRef}/>}
                               content={"Recife"} inverted/>

                        <Popup position="bottom center"
                               trigger={<div className="map-point rio" ref={this.rioRef}/>}
                               content={"Rio de Janeiro"} inverted/>


                        <Label className="map-label arrival">Destination:<br/>COY15 & COP25, Chile</Label>

                        <img id="moving-unit" src="resources/images/np_boat_621434_003E78.png" ref={this.shipIconRef}/>

                    </div>
                    {/*<div className="logo-container">*/}
                    {/*    <img src="resources/images/logo-sailtocop.png" className="logo"/>*/}
                    {/*</div>*/}

                    <div className="map-box start">
                        <div className="padding">
                            <h2 className="bright"><Translate id="home.starting-point">Starting Point</Translate></h2>
                            <p><Translate id="home.starting-point-text">The climate and ecological crisis is demanding a
                                fast shift to a just and sustainable
                                transport
                                system. It should be easy, affordable and attractive to travel without damaging the
                                planet. </Translate></p>
                        </div>
                    </div>
                    <div className="map-box trip" id="map-box-journey" ref={this.mapBoxJourney}>
                        <div className="padding">
                            <h2 className="bright"><Translate id="home.the-journey">The Journey</Translate></h2>
                            <p><Translate id="home.the-journey-text">With 36 people we will sail in 6 to 8 weeks from
                                Europe to South America. Along
                                the
                                way we
                                will be working on a vision of and roadmap to a sustainable future of
                                travel.</Translate></p>
                        </div>
                    </div>
                    <div className="map-box cop" id="map-box-destination" ref={this.mapBoxDestination}>
                        <div className="padding">
                            <h2 className="bright">COP25</h2>
                            <p><Translate id="home.cop25-text">With our trip we want to raise the voice of youth at the
                                COP25 climate negotiations from
                                2 -
                                13
                                December 2019 in Chile.</Translate></p>
                        </div>
                    </div>
                </div>


                {/*<div className="ui vertical stripe segment large padded colored-background alternative">*/}
                {/*    <div className="ui top aligned stackable grid container centered">*/}
                {/*        <div className="row">*/}
                {/*            <div className="column sixteen wide center aligned">*/}
                {/*                <h1>Travel with the wind and sun</h1><br/>*/}
                {/*                <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>*/}
                {/*                <h2>We believe in climate positive traveling</h2>*/}
                {/*            </div>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <br/>


                {/*<div className="ui container" id="home-content-container">*/}
                {/*    <div className="ui vertical stripe padded segment first colored-background-alternative">*/}
                {/*        <div className="ui top aligned stackable grid container">*/}
                {/*            <div className="row">*/}
                {/*                <div className="sixteen wide column center aligned">*/}
                {/*                    <div>*/}
                {/*                        <div id="curator-feed"><a href="https://curator.io" target="_blank"*/}
                {/*                                                  className="crt-logo crt-tag">Powered by Curator.io</a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="ui container" id="home-content-container">
                    <div className="ui vertical stripe padded segment first colored-background-alternative">
                        <div className="ui top aligned stackable grid container">
                            <div className="row">
                                <div className="sixteen wide column center aligned">
                                    <h1><Translate id="home.why">Why did we sail to the COP?</Translate></h1>
                                    {/*<h2>The Journey</h2>*/}
                                </div>
                            </div>
                            <div className="row centered">
                                <div className="ten wide column center aligned">
                                    <p>
                                        <Translate id="home.why-text">
                                            With a diverse group of changemakers we travelled to the UN Climate
                                            Conference
                                            COP25 in 2019.
                                        </Translate>
                                        &nbsp;
                                        <strong>
                                            <Translate id="home.why-text-bold">Our main reason for going is that we are
                                                largely
                                                worried
                                                about the
                                                emissions of aviation - that is why we will not travel by plane but by
                                                sailing
                                                ship.</Translate></strong>
                                    </p>
                                </div>
                            </div>


                            <div className="row centered">
                                <div className="four wide column large center aligned metric">
                                    <p className="number">
                                        2x
                                    </p>
                                    <p className="description">
                                        <Translate id="home.air-travel">air travel is projected to double in the next 20
                                            years</Translate>
                                    </p>
                                </div>
                                <div className="four wide column large center aligned metric">
                                    <p className="number">
                                        18%
                                    </p>
                                    <p className="description">
                                        <Translate id="home.world-population">of the world population have ever flown so
                                            far</Translate>
                                    </p>
                                </div>
                            </div>

                            {/*<div className="row centered">*/}

                            {/*    <div className="five wide column large center aligned">*/}
                            {/*        <p>*/}
                            {/*            <img src="resources/images/Emissions-modalities.png"*/}
                            {/*                 className="ui image"*/}
                            {/*                 style={{maxWidth: '100%'}}/>*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="row centered">
                                <div className="five wide column large center aligned">
                                    <p>
                                        <Link to="/project" className="ui button primary">
                                            <Translate id="read-more">Read more</Translate>&nbsp;
                                            <i className="ui icon arrow right"/>
                                        </Link>
                                    </p>
                                    <p>
                                        <a className="ui labeled icon button facebook"
                                           href="https://www.facebook.com/pg/sailtothecop"
                                           target="_blank"
                                           rel="noopener noreferrer">
                                            <i className='ui icon facebook'/> Follow us on Facebook
                                        </a>
                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


                {/*<div className="ui vertical stripe segment large padded first colored-background alternative topics">*/}
                {/*    <div className="ui top aligned stackable grid container centered">*/}

                {/*        <div className="row">*/}
                {/*<div className="column five wide">*/}
                {/*    <div className="ui card fluid">*/}
                {/*        /!*<Image src="resources/images/55447004_590012131477236_2063544336175333376_o.jpg"/>*!/*/}
                {/*        <div className="content">*/}
                {/*            <h2 style={{textAlign: "center"}}>Join the trip</h2>*/}
                {/*            <p>*/}
                {/*                Do you want to be part of our project by sailing with us and striving for*/}
                {/*                fair and sustainable traveling? Join us to challenge yourself in campaigning*/}
                {/*                and working together with a diverse team.*/}
                {/*            </p>*/}
                {/*            <Link to="/join" className="ui button primary">Sail with us <i*/}
                {/*                className="ui icon arrow right"/></Link>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="column five wide">*/}
                {/*    <div className="ui card fluid">*/}
                {/*        /!*<Image src="resources/images/55447004_590012131477236_2063544336175333376_o.jpg"/>*!/*/}
                {/*        <div className="content">*/}
                {/*            <h2 style={{textAlign: "center"}}><Translate id="home.get-involved">Get involved</Translate></h2>*/}
                {/*            <p>*/}
                {/*                <Translate id="home.get-involved-text">Support our mission by becoming a theme partner, helping us reach the COP*/}
                {/*                    and spreading our message. Get in touch to see how you can help.</Translate>*/}
                {/*            </p>*/}
                {/*            <Link to="/support" className="ui button primary">Support us <i*/}
                {/*                className="ui icon arrow right"/></Link>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="column five wide">*/}
                {/*    <div className="ui card fluid">*/}
                {/*        /!*<Image src="resources/images/55447004_590012131477236_2063544336175333376_o.jpg"/>*!/*/}
                {/*        <div className="content">*/}
                {/*            <h2 style={{textAlign: "center"}}><Translate id="home.become-a-partner">Become a partner</Translate></h2>*/}
                {/*            <p>*/}
                {/*                <Translate id="home.become-a-partner-text">Are you working on solutions for sustainable traveling? Or do you believe in*/}
                {/*                empowering a young generation to build a better future? Become a partner of*/}
                {/*                    our trip!</Translate>*/}
                {/*            </p>*/}
                {/*            <Link to="/partners/join" className="ui button primary"><Translate id="read-more">Read more</Translate> <i*/}
                {/*                className="ui icon arrow right"/></Link>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="ui vertical stripe segment team">

                    <div className="ui container">
                        <div className="ui top center aligned stackable grid container centered">
                            <div className="row">
                                <div className="column fourteen wide center aligned">
                                    <h1 className="bright">The Sail to the COP organization</h1>
                                    <p>
                                        Read more about the foundation Youth for Sustainable Travel on <a href="http://www.yfst.org">www.yfst.org</a>.
                                    </p>
                                </div>
                            </div>


                            <div className="row persons">
                                <div className="four wide column large person">
                                    <img src="resources/images/Lena-3.jpg" className="person"/><br/>
                                    <h3>Lena Hartog</h3>
                                    {/*<a href="mailto:lena@sailtothecop.com">lena@sailtothecop.com</a>*/}
                                    {/*<h5>Impact & Program</h5>*/}
                                    {/*<blockquote>*/}
                                    {/*    "The climate and ecological crises ask for a global movement and for every*/}
                                    {/*    industry*/}
                                    {/*    to*/}
                                    {/*    make bold an*/}
                                    {/*    fast changes. I believe we can build a world where sustainable travel is the*/}
                                    {/*    standard*/}
                                    {/*    and not the*/}
                                    {/*    exception. I am very excited to work together to create a shared vision, hold*/}
                                    {/*    our*/}
                                    {/*    leaders*/}
                                    {/*    accountable and take action."*/}
                                    {/*</blockquote>*/}
                                </div>
                                <div className="four wide column large person">
                                    <img src="resources/images/Mara.jpeg" className="person"/><br/>
                                    <h3>Mara de Pater</h3>
                                    {/*<a href="mailto:mara@sailtothecop.com">mara@sailtothecop.com</a>*/}
                                    {/*<h5>Partners & Grants</h5>*/}
                                    {/*<blockquote>*/}
                                    {/*    "I am personally often challenged in combining my adventurous side with my*/}
                                    {/*    ideals*/}
                                    {/*    when*/}
                                    {/*    it comes to*/}
                                    {/*    climate change. With Sail to the COP I want to explore this while taking the*/}
                                    {/*    public*/}
                                    {/*    debate about*/}
                                    {/*    unfair regulation of aviation to a global stage."*/}
                                    {/*</blockquote>*/}
                                </div>
                                <div className="four wide column large person">
                                    <img src="resources/images/Moon.jpg" className="person"/><br/>
                                    <h3>Moon Weijens</h3>
                                    {/*<a href="mailto:moon@sailtothecop.com">moon@sailtothecop.com</a>*/}
                                    {/*<h5>Marketing</h5>*/}
                                    {/*<blockquote>*/}
                                    {/*    "Always up for adventures, I'm very excited to develop a positive travel*/}
                                    {/*    movement.*/}
                                    {/*    As an*/}
                                    {/*    international water management student, I know how devastating the impact of the*/}
                                    {/*    current*/}
                                    {/*    greenhouse*/}
                                    {/*    gas emission is for our earth, and how it's largely affecting people who never*/}
                                    {/*    set*/}
                                    {/*    foot*/}
                                    {/*    on a plane.*/}
                                    {/*    Let's change that!"*/}
                                    {/*</blockquote>*/}
                                </div>
                                <div className="four wide column large person">
                                    <img src="resources/images/Jeppe-BU130.jpg" className="person"/><br/>
                                    <h3>Jeppe Bijker</h3>
                                    {/*<a href="mailto:jeppe@sailtothecop.com">jeppe@sailtothecop.com</a>*/}
                                    {/*<h5>Crowdfunding</h5>*/}
                                    {/*<blockquote>*/}
                                    {/*    "With my start-up Green Tickets I'm helping people to find the most sustainable*/}
                                    {/*    ways*/}
                                    {/*    to*/}
                                    {/*    travel*/}
                                    {/*    &mdash; but to improve the current options and stop the unfair subsidies on*/}
                                    {/*    flying*/}
                                    {/*    we*/}
                                    {/*    really have to*/}
                                    {/*    look at a global level: the COP."*/}
                                    {/*</blockquote>*/}
                                </div>
                            </div>

                            {/*<div className="row persons">*/}
                            {/*    {participants.map(participant =>*/}

                            {/*       <div className="four wide column large person">*/}
                            {/*           <h3>&nbsp;</h3>*/}
                            {/*           <img src={"resources/images/participants/" + participant.image}*/}
                            {/*                className="person"/><br/>*/}
                            {/*           <h3>{participant.name}</h3>*/}
                            {/*       </div>*/}
                            {/*    )}*/}
                            {/*</div>*/}

                            <div className="row centered">


                                <Link to="/project/participants" className="ui primary button">
                                    <Translate id="read-more">Read More</Translate>&nbsp;
                                    <i className="ui icon arrow right"/>
                                </Link>

                            </div>


                        </div>
                    </div>
                </div>


                {/*<div className="ui vertical stripe segment padded first colored-background">*/}
                {/*    <div className="ui top aligned stackable grid container centered">*/}
                {/*        <div className="row">*/}
                {/*            <div className="column fourteen wide center aligned">*/}
                {/*                <h1>We are looking for adventurous changemakers</h1>*/}
                {/*                <h2>Join our trip!</h2>*/}
                {/*                <p>*/}
                {/*                    Do you want to share your time and talents to shape the future of travel?*/}
                {/*                </p>*/}
                {/*                <p>*/}
                {/*                    For Sail to the COP we are looking for proactive, creative and adventurous people who*/}
                {/*                    like to engage with climate change issues.*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}


                {/*        <div className="row center aligned">*/}
                {/*            <Link to="/join/selection" className="ui button primary">Selection process <i*/}
                {/*                className="ui icon arrow right"/></Link>*/}
                {/*        </div>*/}

                {/*        <div className="row">*/}
                {/*            <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>*/}
                {/*        </div>*/}

                {/*        /!*<Meetups/>*!/*/}

                {/*    </div>*/}
                {/*</div>*/}


                <div className="ui inverted vertical center aligned segment parallax parallax1" id="parallax1">
                    <div className="background"></div>
                    <div className="parallax-content"></div>
                </div>


                <div className="ui vertical stripe segment padded white-background">
                    <div className="ui top center aligned stackable grid container centered">
                        <div className="row">
                            <div className="column sixteen wide center aligned">
                                <h1>Partners of Sail to the COP</h1>

                            </div>
                        </div>

                        {PartnersRow}

                        <div className="row">
                            <div className="column sixteen wide center aligned">
                                <h1>Ambassadors of Sail to the COP</h1>

                            </div>
                        </div>

                        {AmbassadorsRow}

                        <div className="row">
                            <img src="resources/images/DSC02551.jpg" className="wave"/>
                        </div>

                        {SponsorsRow}

                        <div className="row">
                            <div className="column sixteen wide center aligned">
                                <p>
                                    Become an ambassador or theme partner of Sail to the COP:
                                </p>
                                <p>
                                    <Link to="/partners/join" className="ui button primary">
                                        Read More&nbsp;
                                        <i className="ui icon arrow right"/>
                                    </Link>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="ui container">


                    <div className="ui vertical stripe segment padded white-background">
                        <div className="ui top aligned stackable grid container centered">
                            <div className="row center aligned">
                                <div className="fourteen wide column center aligned">
                                    <a href="mailto:info@sailtothecop.com"
                                       className="ui button basic primary non-capitalized">
                                        <i className="ui icon mail outline"></i>
                                        info@sailtothecop.com
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="tel:0031633149435" className="ui button basic primary">
                                        <i className="ui icon phone"></i>
                                        +31 6 33 14 94 35
                                    </a>
                                </div>
                            </div>

                            <Newsletter/>

                        </div>
                    </div>


                </div>


            </div>
        )
            ;
    }
}

export default Home;

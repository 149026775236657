import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import {Link} from "react-router-dom";
import {Button, Card, Checkbox, Embed, Form, Grid, Icon, Image, Input, Label, Loader, Message} from "semantic-ui-react";
import {FundingTimeline} from "./funding-timeline";
import {homeTeam} from "../project/participants";


let rewards = {
    handkerchief: 10,
    postcardmadrid: 20,
    postcard: 22,
    postcards: 50,
    share: 50,
    wine: 75,
    departuredinner: 100,
    video: 100,
    dinner: 500,
    sponsor: 500,
    talk: 2000,
};


const RewardItem = ({reward, currentReward, setReward, children}) => <div className="item">
        <Link className={"reward flex content" + (currentReward.indexOf(reward) !== -1 ? " selected" : "")}
              to={"/support/crowdfunding/" + reward}>
        <div className="checkbox-container">
            <Checkbox readOnly={true} checked={currentReward.indexOf(reward) !== -1}/>
        </div>
        <div className="text">
            {children}
            <div>
                {rewards[reward] > 50 &&
                <small style={{
                    float: "right",
                    lineHeight: (reward === "talk" ? '13px' : '20px'),
                    maxWidth: '80%',
                    textAlign: 'right'
                }}>
                    {reward === "talk" &&
                    <span>Includes company logo on our website</span>}
                    {/*<span>Includes departure party invite</span>}*/}
                </small>
                }
                <span className="ui label small">€ {rewards[reward]}</span>
            </div>
        </div>
        </Link>
</div>;

class SupportPage extends React.Component {

    state = {
        loading: true,
        total: 5000,
        value: 0,
        days: 0,

        fields: {
            amount: "",
            name: "",
            mail: "",
            comments: "",
            reward: [],
            method: "ideal",
            publish: false,
            newsletter: false,
        },
        disabled: false,
        url: null,

    };

    readMore = React.createRef();

    componentDidMount() {


        let _this = this;

        let ref = firebase.database().ref('crowdfunding');
        ref.on('value', function (snapshot) {
            let funding = snapshot.val();
            _this.setState({
                value: funding.value,
                backers: funding.backers,
                loading: false,
            })
        });


        if (!window.ScrollMagic) return;

        try {

            new window.ScrollMagic.Scene({triggerElement: "#parallax6"})
                .setTween("#parallax6 > div", {y: "40%"}) //, ease: Linear.easeNone
                .addTo(new window.ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: "onEnter",
                        duration: "200%"
                    }
                }));


        } catch (e) {

        }

    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }

    handleChangeCheckbox(e) {
        if (!e || !e.target || !e.target.name) return;

        let fields = this.state.fields;
        fields[e.target.name] = e.target.checked;

        this.setState({
            fields
        })
    }


    render() {
        const {total, value, loading, days, hours, fields, disabled, url, backers} = this.state;
        const {amount, name, mail, comments, reward, method, publish, newsletter} = fields;

        return <div className="sail crowdfunding-page">

            <div className="ui vertical stripe padded first colored-background-alternative no-bottom">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column twelve wide center aligned">

                            <h1>Support Sail to the COP in Madrid</h1>
                            <h2 style={{textAlign: "center"}}>Together we can change the future of travel</h2>
                        </div>

                    </div>

                    <div className="row">

                        {/*<div className="column eight wide">*/}

                        {/*    <Embed id='KC0VNuor4tg' allowfullscreen*/}
                        {/*           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*           placeholder='/resources/images/SailtothecopVideo1.jpg' source='youtube'/>*/}

                        {/*</div>*/}

                        <div className="column fourteen wide center aligned">

                            <p>
                                Help our representatives in Europe to bring our message to the COP in Madrid!
                                We feel required to raise our voices given the urgency of the climate crisis, the lack
                                of emission reduction measures in the aviation sector and the absence of an
                                international campaign on the issue.
                            </p>


                            {/*<p>*/}
                            {/*    <strong>Even if we don't reach the crowdfunding target <em>Sail to the COP</em> will still take place. However, we still very much need your support to reduce the personal financial risks of the organizing team and make the most out of this amazing trip.</strong>*/}
                            {/*</p>*/}
                            {/*{loading ? <Loader active size="small"/> :*/}
                            {/*    <div><strong>{days} day{days !== 1 && "s"} left</strong> (crowdfunding closes on 15 June*/}
                            {/*        2019)</div>*/}
                            {/*}*/}



                            <FundingTimeline value={value} total={total}/>

                            <br/>

                            {value ?
                                <div>
                                    <div className="large-number">
                                        <span className="number">{Math.round(value / total * 100)}%</span>
                                        of crowdfunding goal
                                    </div>
                                    <div className="large-number">
                                        <span className="number">{backers}</span>
                                        total Sail to the COP supporters
                                    </div>
                                </div> : <div>
                                    <br/><br/>
                                </div>}

                        </div>

                    </div>


                    <div className="row" style={{marginBottom: 0, paddingBottom: 0}}>

                        <div className="column ten wide">

                            <div className="row" ref={this.readMore}>
                                <div className="column twelve wide"><br/>
                                    <h2>Why your support is needed</h2>
                                    <p>
                                        Sail to the COP was sailing to Chile with 36 young people to call for fair and sustainable travel and to work on solutions and campaigns in our sailing think tank. However, halfway the Atlantic Ocean we heard about the COP relocation to Madrid... Unfortunately we were not able to reach Madrid in time. To still get our message across and lobby against the climate impact of the travel industry we have found representatives to go to Madrid in our place.
                                    </p>
                                    <p>
                                        We want to support our representatives in Madrid but have very limited budget left. Therefore we would like to ask you to contribute to help us change the future of travel. The money will be used for traveling, accommodation, printing, representation, organization of side events and facilitating videocalls.
                                    </p>
                                    <p>

                                        <Button as={Link} to="/support/crowdfunding" icon labelPosition="right"
                                                color="primary">
                                            Support us
                                            <Icon name="arrow right"/>
                                        </Button>

                                    </p>


                                    <Embed id='8vqwzwazX-s' allowfullscreen
                                           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                           placeholder='/resources/images/Sail to the COP Participants Video 2.jpg'
                                           source='youtube'/>
                                </div>

                                <br/><br/>

                            </div>


                            <br/>

                            <div className="row">
                                <div className="column twelve wide">
                                    <h3>As seen on</h3><br />
                                </div>
                            </div>

                            <Grid>
                                <Grid.Row columns={4}>
                                    <Grid.Column>
                                        <a href="https://www.theguardian.com/environment/2019/oct/02/activists-set-sail-across-the-atlantic-to-chile-to-demand-curbs-on-flying"
                                           target="_blank"><Image
                                            src="/resources/images/Guardian.png"/></a>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <a href="https://edition.cnn.com/2019/10/31/americas/chile-climate-voyage-canceled-scli-intl/index.html"
                                           target="_blank"><Image
                                            src="/resources/images/CNN.png"/></a>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <a href="https://mashable.com/article/climate-activists-sailing-cop25/"
                                           target="_blank"><Image
                                            src="/resources/images/Mashable.jpg"/></a>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <a href="https://www.forbes.com/sites/davidebanis/2019/06/07/this-european-travel-planner-helps-you-cut-down-the-environmental-impact-of-your-holidays/"
                                           target="_blank"><Image
                                            src="/resources/images/Forbes.png"/></a>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={4}>
                                    <Grid.Column>
                                        <a href="https://www.lemonde.fr/planete/article/2019/10/02/d-amsterdam-a-santiago-du-chili-36-jeunes-traversent-l-atlantique-en-voilier-pour-rejoindre-la-cop25_6013956_3244.html"
                                           target="_blank"><Image
                                            src="/resources/images/LeMonde.png"/></a>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <a href="https://www.zeit.de/campus/2019-08/seefahrt-uebersee-greta-thunberg-tipps#wie-sollte-man-sich-an-bord-verhalten-wenn-man-keine-ahnung-vom-segeln-hat"
                                           target="_blank"><Image
                                            src="/resources/images/ZeitCampus.png"/></a>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid><br/>

                            <br /><br />

                            <Message>
                                <Message.Header>Donate in other ways</Message.Header>
                                You can also transfer money to NL34 BUNQ 2034 8454 71, on behalf
                                of
                                <em>Stichting Youth for Sustainable Travel</em>.<br/>
                                Or donate on <a
                                href="https://bunq.me/sailtothecop"
                                target="_blank">https://bunq.me/sailtothecop</a>.
                            </Message>

                            {/*<em>Scroll down for all our publicity...</em>*/}

                            {/*<br />*/}
                            {/*<br />*/}
                            {/*    <Image src="/resources/images/Sail to the COP Team small.jpg" />*/}

                            {/*<br/>*/}

                            {/*<div className="row">*/}
                            {/*    <div className="column twelve wide">*/}
                            {/*        <h1>Updates</h1>*/}

                            {/*        <a className="ui labeled icon button facebook"*/}
                            {/*           href="https://www.facebook.com/pg/sailtothecop"*/}
                            {/*           target="_blank"*/}
                            {/*           rel="noopener noreferrer">*/}
                            {/*            <i className='ui icon facebook'/> Follow us on Facebook*/}
                            {/*        </a>*/}

                            {/*        <h3><Label size="small">11 August 2019</Label> First full team meeting coming up*/}
                            {/*        </h3>*/}
                            {/*        <p>*/}
                            {/*            As our departure date is slowly approaching we are getting ready to meet all*/}
                            {/*            the <a*/}
                            {/*            href="/project/participants">participants</a> for the first time, next weekend.*/}
                            {/*            We are*/}
                            {/*            making progress on our funding and onboard program as well! Follow us on <a*/}
                            {/*            href="https://www.facebook.com/pg/sailtothecop"*/}
                            {/*            target="_blank">Facebook</a> and <a*/}
                            {/*            href="https://www.instagram.com/sailtothecop/" target="_blank">Instagram</a> to*/}
                            {/*            meet all*/}
                            {/*            the participants and stay up to date with the latest developments.*/}
                            {/*        </p>*/}
                            {/*        <h3><Label size="small">10 July 2019</Label> Partners and preparation</h3>*/}
                            {/*        <p>*/}
                            {/*            More and more partners are getting confirmed, bringing financial support and*/}
                            {/*            expertise.*/}
                            {/*            Meanwhile we are also busy preparing the trip in other ways: with a videochat*/}
                            {/*            with all*/}
                            {/*            partcipants for example. We will also attend the <a*/}
                            {/*            href="http://www.stay-grounded.org"*/}
                            {/*            target="_blank">Stay*/}
                            {/*            Grounded</a> conference on aviation degrowth in Barcelona this weekend.*/}
                            {/*        </p>*/}
                            {/*        <h3><Label size="small">20 June 2019</Label> Ship and participant selection</h3>*/}
                            {/*        <p>*/}
                            {/*            We will be sailing with the Regina Maris and our participant selection is almost*/}
                            {/*            finalized. There are a few places still available on the ship for experts and*/}
                            {/*            partners.*/}
                            {/*        </p>*/}
                            {/*        <h3><Label size="small">5 June 2019</Label> Crowdfunding deadline changed</h3>*/}
                            {/*        <p>*/}
                            {/*            We have removed the upcoming deadline for the crowdfunding because we are*/}
                            {/*            confident our*/}
                            {/*            project we will succeed. We have acquired enough funds to sail to Chile with 10*/}
                            {/*            participants. However, because we have received so many applications we are*/}
                            {/*            doing*/}
                            {/*            everything we can to arrange a larger, and more expensive, ship. For this we are*/}
                            {/*            looking*/}
                            {/*            for additional partners <em>and</em> for your support. Every euro counts -*/}
                            {/*            together we*/}
                            {/*            can make the*/}
                            {/*            future of travel fair and sustainable!*/}
                            {/*        </p>*/}
                            {/*        <h3><Label size="small">1 June 2019</Label> Applications closed</h3>*/}
                            {/*        <p>*/}
                            {/*            An overwhelming number of people applied to join our trip to Chile! We received*/}
                            {/*            60*/}
                            {/*            applications*/}
                            {/*            of very motivated, talented and dedicated people ready to fight for fair and*/}
                            {/*            sustainable*/}
                            {/*            travel.*/}
                            {/*            We will read every single letter with great care and do our best to make a fair*/}
                            {/*            selection - sadly*/}
                            {/*            we only have 10-22 places available, depending on the funding we raise...*/}
                            {/*        </p>*/}

                            {/*        <h3><Label size="small">21 May 2019</Label> Support from Better Places & Urgenda*/}
                            {/*        </h3>*/}
                            {/*        <p>*/}
                            {/*            We have received many donations already, small and large, from many different*/}
                            {/*            people,*/}
                            {/*            and received two larger donations from Better Places & Urgenda. Thank you all!*/}
                            {/*        </p>*/}

                            {/*        <h3><Label size="small">1 May 2019</Label> Crowdfunding started</h3>*/}
                            {/*        <p>*/}
                            {/*            After many months of preparations, dreaming, writing, designing and deciding we*/}
                            {/*            have*/}
                            {/*            launched*/}
                            {/*            our crowdfunding campaign! We will continue to pursue many different options to*/}
                            {/*            get the*/}
                            {/*            required funding, but we want to go on this journey together with you! Every*/}
                            {/*            little bit*/}
                            {/*            counts,*/}
                            {/*            and you can help us call for a fair and sustainable future of travel.*/}
                            {/*        </p>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                        </div>


                        {/*<div className="column six wide" style={{paddingTop: '60px'}}>*/}



                        {/*    <Embed id='8vqwzwazX-s' allowfullscreen*/}
                        {/*           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*           placeholder='/resources/images/Sail to the COP Participants Video 2.jpg'*/}
                        {/*           source='youtube'/>*/}

                        {/*</div>*/}

                        <div className="column six wide">
                            {url ? <div style={{textAlign: 'center'}}>
                                    <br/><br/><br/><br/>
                                    <a href={url}
                                       className="ui button primary">
                                        Continue&nbsp;
                                        <i className="ui icon arrow right"/>
                                    </a>
                                    <br/><br/><br/><br/>
                                </div> :
                                <div className="ui card fluid no-border-mobile">
                                    <div className="">

                                        <div className="column seven wide ui divided list no-padding">

                                            <Link to="/support/crowdfunding/postcard">
                                                <h2 style={{padding: '10px', paddingBottom: '5px'}}>Support our team in Madrid</h2>
                                            </Link>


                                            <RewardItem reward="postcardmadrid" currentReward={reward}
                                                        >
                                                <h4>Postcard from Madrid</h4>
                                                <p>
                                                    We'll send you a handwritten postcard from COP25 in Madrid to thank
                                                    you for being part of our journey!
                                                </p>
                                            </RewardItem>
                                            <RewardItem reward="postcard" currentReward={reward}
                                                        >
                                                <h4>Postcard from Martinique</h4>
                                                <p>
                                                    We'll send you a handwritten postcard from the Sail to the COP headquarters during COP25 to thank
                                                    you for being part of our journey!
                                                </p>
                                            </RewardItem>
                                            {/*<RewardItem reward="share" currentReward={reward}*/}
                                            {/*            >*/}
                                            {/*    <h4>Invite to our departure party</h4>*/}
                                            {/*    <p>*/}
                                            {/*        Invest in our common future and come wish us a good trip at*/}
                                            {/*        our*/}
                                            {/*        departure party in Amsterdam!*/}
                                            {/*    </p>*/}
                                            {/*</RewardItem>*/}
                                            {/*<RewardItem reward="wine" currentReward={reward}*/}
                                            {/*            >*/}
                                            {/*    <h4>Bottle of Chilean Wine</h4>*/}
                                            {/*    <p>*/}
                                            {/*        We will bring a bottle of a fine organic wine back from*/}
                                            {/*        Chile.*/}
                                            {/*    </p>*/}
                                            {/*</RewardItem>*/}
                                            {/*<RewardItem reward="departuredinner" currentReward={reward}*/}
                                            {/*            >*/}
                                            {/*    <h4>Departure dinner</h4>*/}
                                            {/*    <p>*/}
                                            {/*        Get one place at our exclusive departure dinner in Amsterdam!*/}
                                            {/*    </p>*/}
                                            {/*</RewardItem>*/}

                                            <RewardItem reward="postcards" currentReward={reward}
                                                        >
                                                <h4>Set of custom designed postcards</h4>
                                                <p>
                                                    Receive a set of 5 custom designed Sail to the COP postcards.
                                                </p>
                                            </RewardItem>
                                            <RewardItem reward="video" currentReward={reward}
                                                        >
                                                <h4>Personal video message</h4>
                                                <p>
                                                    Receive a personal video message recorded on the Regina Maris! Ask us a question or dedicate the video to someone else.
                                                </p>
                                            </RewardItem>
                                            <RewardItem reward="sponsor" currentReward={reward}
                                                        >
                                                <h4>Company sponsor</h4>
                                                <p>
                                                    Your company's logo will be featured on our website and in
                                                    our
                                                    social media campaign.
                                                </p>
                                            </RewardItem>
                                            <RewardItem reward="talk" currentReward={reward}
                                                        >
                                                <h4>Workshop or talk at your organization</h4>
                                                <p>
                                                    After our trip we will come to a location of your
                                                    choice (in or near The Netherlands) to give a workshop or
                                                    talk.
                                                </p>
                                            </RewardItem>
                                            <div className="item">
                                                <Link to="/partners/join" className="reward content">

                                                    <h4>Become a partner</h4>
                                                    <p>Are you willing to support us in another way? Become a
                                                        partner of Sail to the COP!</p>
                                                </Link>
                                            </div>

                                        </div>
                                        {/*</div>*/}
                                        {/*</div>*/}

                                    </div>
                                </div>}


                            <div className="row small-persons">
                                <div className="column twelve wide center aligned">
                                    <h3>Our representatives</h3>
                                    {homeTeam.map(participant =>
                                        <Image src={"resources/images/participants/" + participant.image}
                                               alt={participant.name}
                                               size="tiny"/>)
                                    }

                                    <div className="read-more">
                                        <Button to="/project/participants" as={Link} icon labelPosition="right"
                                                size="tiny">
                                            The Participants
                                            <Icon name="right arrow"/>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <br/><br/>
                                <Button as={Link} to="/support/crowdfunding/postcard" icon labelPosition="right"
                                        color="primary">
                                    Support us
                                    <Icon name="arrow right"/>
                                </Button>
                            </div>

                        </div>

                    </div>


                    {/*<div className="row" style={{marginTop: 0, paddingTop: 0}}>*/}
                    {/*    <div className="fourteen wide column center aligned">*/}
                    {/*        <h1>Donate</h1>*/}

                    {/*        <p>*/}
                    {/*            Do you want to support our cause with a financial contribution?*/}
                    {/*        </p>*/}
                    {/*            <Card fluid>*/}

                    {/*                <Card.Content>*/}

                    {/*                    <Grid divided>*/}
                    {/*                        <Grid.Row columns={2}>*/}
                    {/*                            <Grid.Column>*/}
                    {/*                                <h3>By credit card or iDeal</h3>*/}
                    {/*                                <p>Choose an amount</p>*/}
                    {/*                                <Button as={Link} basic to={"/support/donate/20"}>€ 20</Button>*/}
                    {/*                                <Button as={Link} basic to={"/support/donate/50"}>€ 50</Button>*/}
                    {/*                                <Button as={Link} basic to={"/support/donate/100"}>€ 100</Button>*/}
                    {/*                                <br /><br />*/}
                    {/*                                <Button as={Link} basic to={"/support/donate"}>Other amount</Button>*/}
                    {/*                            </Grid.Column>*/}
                    {/*                            <Grid.Column>*/}
                    {/*                                <h3>Other ways</h3>*/}
                    {/*                                <p>*/}
                    {/*                                    Donate via <a*/}
                    {/*                                    href="https://bunq.me/sailtothecop"*/}
                    {/*                                    target="_blank">bunq.me/sailtothecop</a>, using iDeal or SOFORT.<br /><br />*/}
                    {/*                                    You can also transfer money to <br /><pre>*/}
                    {/*                                    <strong>IBAN</strong> NL34 BUNQ 2034 8454 71<br />*/}
                    {/*                                    <strong>BIC</strong> BUNQNL2A*/}
                    {/*                                </pre> on behalf*/}
                    {/*                                    of*/}
                    {/*                                    <em> Stichting Youth for Sustainable Travel</em>.*/}
                    {/*                                </p>*/}
                    {/*                            </Grid.Column>*/}
                    {/*                        </Grid.Row>*/}
                    {/*                    </Grid>*/}

                    {/*                </Card.Content>*/}

                    {/*            </Card>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row">
                        <div className="fourteen wide column center aligned">
                            <h1>Questions?</h1>
                            <p>Do you have questions or do you want to support us in a different way? Don't hesitate
                                to <Link to="/project/contact">contact us</Link>!</p>
                        </div>
                    </div>
                    <div className="row center aligned">
                        <div className="twelve wide column center aligned">
                            <a href="mailto:info@sailtothecop.com" className="ui button basic primary non-capitalized">
                                <i className="ui icon mail outline"></i>
                                info@sailtothecop.com
                            </a>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <a href="tel:0031633149435" className="ui button basic primary">
                                <i className="ui icon phone"></i>
                                +31 6 33 14 94 35
                            </a>
                        </div>
                    </div>

                    {/*<div className="row">*/}
                    {/*    <div className="fourteen wide column center aligned">*/}
                    {/*        <h1>Publicity</h1>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <br style={{clear: "both"}} />
                    <br /><br />
                    <br /><br />

                </div>
            </div>

            <div className="ui inverted vertical center aligned segment parallax parallax6" id="parallax6">
                <div className="background"></div>
                <div className="parallax-content"></div>
            </div>

            {/*<Publicity/>*/}

        </div>
    }
}

export default SupportPage;

import React from 'react';
import {Link, Route} from "react-router-dom";
import {Image} from "semantic-ui-react";

class Journey extends React.Component {

    componentDidMount() {

        if (!window.ScrollMagic) return;

        try {
            new window.ScrollMagic.Scene({triggerElement: "#parallax5"})
                .setTween("#parallax5 > div", {y: "40%"}) //, ease: Linear.easeNone
                .addTo(new window.ScrollMagic.Controller({
                    globalSceneOptions: {
                        triggerHook: "onEnter",
                        duration: "200%"
                    }
                }));
        } catch {
        }

    }

    render() {
        return (
            <div className="ui container">

                <div className="ui vertical stripe segment padded first colored-background">
                    <div className="ui top aligned stackable grid container centered">
                        <div className="row">
                            <div className="column sixteen wide">

                                <h1>The Journey</h1>

                                <div className="timeline mobile hidden">
                                    <div className="line"></div>
                                    <div className="point-container point-1">
                                        <div className="title">Pre-departure meetings</div>
                                        <div className="point"></div>
                                        <div className="description">September</div>
                                    </div>
                                    <div className="point-container point-2">
                                        <div className="title">Departure Amsterdam</div>
                                        <div className="point"></div>
                                        <div className="description">2 October</div>
                                    </div>
                                    <div className="icon ship"></div>
                                    <div className="point-container point-3">
                                        <div className="title">Arrival Rio de Janeiro</div>
                                        <div className="point"></div>
                                        <div className="description">Half November</div>
                                    </div>
                                    <div className="icon bus"></div>
                                    <div className="point-container point-4">
                                        <div className="title">Arrival Chile</div>
                                        <div className="point"></div>
                                        <div className="description">End November</div>
                                    </div>
                                </div>
                                <div className="timeline mobile only">
                                    <div className="">
                                        <div className="title">Pre-departure meetings</div>
                                        <div className="description">September</div>
                                    </div>
                                    <div className="">
                                        <div className="title"><br/>Departure</div>
                                        <div className="description">End September / begin October</div>
                                    </div>
                                    <div className="icon ship"></div>
                                    <div className="">
                                        <div className="title">Arrival Rio de Janeiro</div>
                                        <div className="description">Half November</div>
                                    </div>
                                    <div className="icon bus"></div>
                                    <div className="">
                                        <div className="title">Arrival Santiago de Chile</div>
                                        <div className="description">End November</div>
                                    </div>
                                </div>

                                {/*<div className="ui center aligned stackable grid divided">*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="twelve wide column large">*/}

                                {/*            <ul className="list">*/}
                                {/*                <li>We will travel with 24 participants bringing different skills and*/}
                                {/*                    expertises*/}
                                {/*                </li>*/}
                                {/*                <li className="wave">*/}
                                {/*                    <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>*/}
                                {/*                </li>*/}
                                {/*                <li>On board we will write our vision & demands for the future of travel*/}
                                {/*                </li>*/}
                                {/*                <li className="wave">*/}
                                {/*                    <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>*/}
                                {/*                </li>*/}
                                {/*                <li>A team of young media makers will document the experience on board in*/}
                                {/*                    writing,*/}
                                {/*                    film and cartoon illustrations*/}
                                {/*                </li>*/}
                                {/*                <li className="wave">*/}
                                {/*                    <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>*/}
                                {/*                </li>*/}
                                {/*                <li>The trip will be a personal transformative experience of a lifetime</li>*/}

                                {/*            </ul>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                            </div>
                        </div>


                        <div className="row">
                            <div className="column fourteen wide">
                                <h2>From Europe to South America</h2>
                                <p>
                                    We will depart from Amsterdam, the capital of The Netherlands at the 2nd of October. We will make four stops to get fresh food onboard and stretch our legs: Casablanca, Tenerife, Cabo Verde and Recife. Finally we will arrive in Rio de Janeiro around the 20th of November. The last part over land to Chile will be done by bus.<br />
                                    The sailing will take approximately 7 weeks. We will be sailing with an
                                    experienced and accredited crew and a certified sailing ship.
                                </p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="column eight wide">
                                <Image alt="Our route to Chile" src="resources/images/atlantic-map-line-rio.png"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column fourteen wide">

                                <h2>On board</h2>
                                <p>
                                    Participants are not required to have sailing experience but help out and learn to
                                    sail
                                    on board. We want to use the 6-8 weeks on board well:
                                    <ul>
                                        <li>During the trip we will organize a think tank.</li>
                                        <li>For 4 hours per day participants will work together on analyses and
                                            solutions for the
                                            future of travel.
                                        </li>
                                        <li>We will travel with 36 participants bringing different skills and
                                            expertises. The trip
                                            will be a personal transformative experience of a lifetime - spending time
                                            on sea in
                                            such an intense way is a chance for deep connections.
                                        </li>
                                        <li>The topics we will work on are a thought of in collaboration with our theme
                                            partners.
                                        </li>
                                        <li><Link to="partners/become-partner">Read more about partnering up here.</Link></li>
                                    </ul>
                                </p>
                                <h2>Output</h2>
                                <p>
                                    The output of the trip will be a vision with clear demands towards our political
                                    leaders
                                    and the industry. This will be in the form of a document and presentation which we
                                    will
                                    publish online and send to all of our supporters. Once in Chile we will edit the
                                    document and present it at the COP.
                                </p>
                                <h2>Media</h2>
                                <p>
                                    Next to the expert participants we are working together with a team of young media
                                    makers. They will document the experience on board in writing, film and
                                    illustrations.

                                </p>
                                <h2>The COP</h2>
                                <p>
                                    The UN Climate Conference COP25 is taking place in Santiago de Chile. We are working
                                    on arranging accommodation in Santiago and are getting in touch with the organizing
                                    team. We will organize actions in and outside the conference. Inside the conference
                                    we want to organize/join a side event in which we share our vision and start a
                                    dialogue with the political leaders. With creative actions we are aiming to draw
                                    attention to the topic of aviation.

                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="ui inverted vertical center aligned segment parallax parallax5" id="parallax5">

                    <div className="background"></div>
                    <div className="parallax-content">


                    </div>

                </div>
            </div>
        );
    }
}

export default Journey;

import React from 'react';
import {Link} from "react-router-dom";

export const PartnersRow =
    [<div className="row persons" key="partners1">
        <div className="seven wide column large person">
            <div>
                <a href="http://www.reginamaris.de" target="_blank"><img src="resources/images/Regina-Maris.jpg"
                                                                         className="logo"
                                                                         style={{maxWidth: '80%'}}/></a>
            </div>
            <h3>Regina Maris</h3>
            <a href="http://www.reginamaris.de" target="_blank">www.reginamaris.de</a>
            <blockquote style={{marginTop: '4px'}}>
                We will be sailing with the Regina Maris, a 3-mast schooner with space for 36 guests. The Regina Maris
                is inspired by the mission of Sail to the COP and therefore offers our project a generous sponsorship.
                After the journey with Sail to the COP they will start the new concept <em>Gap Year Atlantic</em>.
            </blockquote>
        </div>
        <div className="seven wide column large person">
            <div>
                <a href="http://www.gapyearatlantic.com" target="_blank"><img
                    src="resources/images/190306-gapyearatlantic_logo-transparant-1280x288.png"
                    className="logo"
                    style={{maxWidth: '80%'}}/></a>
            </div>
            <h3>Gap Year Atlantic</h3>
            <a href="http://www.gapyearatlantic.com" target="_blank">www.gapyearatlantic.com</a>
            <blockquote>
                Cross the ocean with 30 other youngsters on a 'tall ship', participate in scientific research into the
                extent and effects of nano-plastic soup in the ocean, return with the world in your backpack, speaking
                Spanish... You will have learned how to build on your strengths and you have a clear idea of where you
                want to go with your studies. Above all, you will have a lot of new experiences and you will have seen
                many new things. This journey inspires and puts you in a position of strength.
            </blockquote>
        </div>
    </div>,
        <div className="row persons" key="partners2">
            <div className="five wide column large person">
                <div style={{paddingTop: '40px'}}>
                    <a href="http://www.prorail.nl" target="_blank"><img src="resources/images/logo-prorail.png"
                                                                         className="logo"
                                                                         style={{maxWidth: '80%'}}/></a>
                </div>
                <h3>ProRail</h3>
                <a href="http://www.prorail.nl" target="_blank">www.prorail.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                </blockquote>
            </div>

            <div className="five wide column large person">
                <div>
                    <a href="https://www.rijksoverheid.nl/ministeries/ministerie-van-infrastructuur-en-waterstaat"
                       target="_blank"><img src="resources/images/top_ienw.png"
                                            className="logo"
                                            style={{maxWidth: '70%'}}/></a>
                </div>
                <h3>Ministry of Infrastructure & Water Management</h3>
                <a href="https://www.rijksoverheid.nl/ministeries/ministerie-van-infrastructuur-en-waterstaat"
                   target="_blank">www.rijksoverheid.nl</a>
                <blockquote>
                    "Sail to the COP is a great initiative to put the transition to sustainable mobility on the agenda.
                    With this innovative cooperation we are not only gaining new possibilities in terms of content, but
                    also new ways to increase impact."
                </blockquote>
            </div>

            <div className="six wide column large person">
                <div>
                    <a href="https://climate-kic.org" target="_blank"><img src="resources/images/cclogo.png"
                                                                           className="logo"
                                                                           style={{maxWidth: '80%'}}/></a>
                </div>
                <h3>Climate-KIC</h3>
                <h5>Knowledge and Innovation Community</h5>
                <a href="https://www.climate-kic.org" target="_blank">www.climate-kic.org</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Since the first ever Climathon that took place in the run up to the historic COP21 negotiations in
                    Paris, EIT Climate-KIC’s Climathon has grown to become the world’s biggest 24-hours Climate
                    hackathon
                    event. Last year, 5,000 participants in 113 cities of the world came up with over 400 climate
                    related
                    solutions. For Climathon 2019, we are proud to work with Sail to the COP to host the first ever
                    Atlantic
                    Ocean edition on a sailboat whilst traveling sustainably towards the COP25 negotiations in Chile."
                    {/*Not*/}
                    {/*only will this edition tackle the future of sustainable travel with 24 young experts but we will do so*/}
                    {/*in a completely climate-friendly way."*/}
                </blockquote>
                {/*<div style={{marginTop: '4px'}}>- </div>*/}
            </div>
        </div>,
        <div className="row persons" key="partners3">
            <div className="five wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.wur.nl" target="_blank"><img
                        src="resources/images/WUR.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Wageningen University & Research</h3>
                <a href="http://www.wur.nl" target="_blank">www.wur.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Wageningen University & Research is a knowledge partner, fully convinced of the power of knowledge and education in raising awareness and combating climate change. We stimulate and encourage the young, including our students, to play an increasingly leading role in this area."
                </blockquote>
                {/*<div style={{marginTop: '4px'}}>- Saskia Griep, Better Places</div>*/}
            </div>
            <div className="five wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.milieudefensie.nl" target="_blank"><img
                        src="resources/images/Milieudefensie.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Milieudefensie</h3>
                <a href="http://www.milieudefensie.nl" target="_blank">www.milieudefensie.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                </blockquote>
            </div>
            <div className="five wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.betterplaces.nl" target="_blank"><img
                        src="resources/images/onaangepast_Betterplaces_logo_blauw.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Better Places</h3>
                <a href="http://www.betterplaces.nl" target="_blank">www.betterplaces.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    "We fully support these ambitious changse makers. International cooperation and action is urgently
                    needed
                    for the future of the travel industry."
                </blockquote>
                {/*<div style={{marginTop: '4px'}}>- Saskia Griep, Better Places</div>*/}
            </div>
        </div>];


export const AmbassadorsRow =
    [<div className="row persons ambassadors" key="ambassadorpersons1">
        <div className="seven wide column large person">
            <div>
                <a href="http://www.jongeklimaatbeweging.nl" target="_blank"><img src="resources/images/LOG-JKB-RGB.jpg"
                                                                                  className="logo"
                                                                                  style={{maxWidth: '80%'}}/></a>
            </div>
            <h3>Jonge Klimaatbeweging</h3>
            <h5>Dutch Youth Climate Movement</h5>
            <a href="http://www.jongeklimaatbeweging.nl" target="_blank">www.jongeklimaatbeweging.nl</a>
            <blockquote style={{marginTop: '4px'}}>
                "<em>Sail to the COP</em> raises awareness for two very important topics. It makes
                sure
                sustainable
                ways
                of traveling is put on the public and political agenda, while asking attention for
                the
                representation of young people at the COP25. It's time to involve them in decision
                making about
                their own future!"
            </blockquote>
            <div style={{marginTop: '4px'}}>- Maarten Labots (Chairman)</div>
        </div>
        <div className="seven wide column large person">
            <div>
                <a href="https://stay-grounded.org" target="_blank"><img src="resources/images/Logo_StayGrounded-4.png"
                                                                         className="logo"
                                                                         style={{maxWidth: '80%'}}/></a>
            </div>
            <h3>Stay Grounded</h3>
            <h5>Global NGO Network</h5>
            <a href="https://stay-grounded.org" target="_blank">www.stay-grounded.org</a>
            <blockquote style={{marginTop: '4px'}}>
                "The Stay Grounded Network includes initiatives from all over the world,
                with different approaches to work for climate justice and to counter
                aviation growth. One approach is pointing out the alternatives and
                proving that a different way of traveling and transport is possible.
                <em>Sail to the COP</em> is a great example for this and we strongly support
                their project."
            </blockquote>
            <div style={{marginTop: '4px'}}>- Anne Kretzschmar (Conference Coordinator)</div>
        </div>
    </div>,
        <div className="row persons ambassadors" key="ambassadorpersons2">
            <div className="five wide column large person">
                <img src="resources/images/JPG%20web%20Suzanne%20card%202.jpg" className="person"/><br/>
                <h3>Suzanne Kröger</h3>
                <h5>Member of Parliament for GroenLinks</h5>
                <a href="https://groenlinks.nl/mensen/suzanne-kröger" target="_blank">www.groenlinks.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Aviation is a growing climate issue. According to the Paris Agreement we have to
                    reduce
                    greenhouse gasses but the CO<sub>2</sub> emission of aviation is growing and
                    growing. We
                    need
                    change, we
                    need fair policy and we need people to stand up. <em>Sail to the COP</em> is a
                    fantastic
                    call for
                    more
                    sustainable ways of travel."
                </blockquote>
            </div>
            <div className="five wide column large person">
                <img src="resources/images/190508-marcel-beukeboom-wit-vierkant-002.jpg" className="person"/><br/>
                <h3>Marcel Beukeboom</h3>
                <h5>Climate Envoy of The Netherlands</h5>
                <a href="https://www.nederlandwereldwijd.nl/ambassadeurs/b/beukeboom-marcel"
                   target="_blank">www.nederlandwereldwijd.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Sail to the COP is een prachtig initiatief dat past in de wereldwijde beweging van jongeren die
                    geen genoegen meer nemen met het tempo waarin we iets doen aan klimaatverandering. Per zeilboot zal
                    die boodschap aankomen bij de klimaatonderhandelaars, maar door de aandacht onderweg net zo goed bij
                    iedereen. Voor oplossingen kunnen we niet langer alleen naar diplomaten en politici kijken, iedereen
                    heeft een steentje bij te dragen. Deze groep zet zich daar voor in. Sail the talk!"
                </blockquote>
            </div>
            <div className="five wide column large person">
                <img src="resources/images/Henk-Ovink.jpg" className="person"/><br/>
                <h3>Henk Ovink</h3>
                <h5>Special Envoy International Water Affairs of The Netherlands</h5>
                <a href="https://www.government.nl/latest/news/2015/03/12/henk-ovink-appointed-as-first-special-envoy-for-international-water-affairs-for-the-kingdom-of-the-netherlands"
                   target="_blank">www.government.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Sailing for Climate, what better way than to raise awareness and call for climate action from our
                    Dutch youth. We all need to take action fast, faster than any boat can sail. And bolder than ever
                    imagined before. Join us and step up, now!"
                </blockquote>
            </div>
        </div>

    ];

export const SponsorsRow =
    [<div className="row persons sponsors" key="sponsors1">
        <div className="four wide column large person">
            <div style={{minHeight: '100px'}}>
                <a href="http://www.ns.nl" target="_blank"><img
                    src="resources/images/NS.png" className="logo"
                    style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
            </div>
            <h3>NS - Dutch Railways</h3>
            <a href="http://www.ns.nl" target="_blank">www.ns.nl</a>
            <blockquote style={{marginTop: '4px'}}>
            </blockquote>
        </div>
        <div className="four wide column large person">
            <div style={{minHeight: '100px'}}>
                <a href="http://www.urgenda.nl" target="_blank"><img
                    src="resources/images/logo-urgenda.png" className="logo"
                    style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
            </div>
            <h3>Urgenda</h3>
            <a href="http://www.urgenda.nl" target="_blank">www.urgenda.nl</a>
            <blockquote style={{marginTop: '4px'}}>
                “'<em>100% sustainability in 2030 – it is possible if we want it</em>' is our motto.
                These young people who are sailing to the Climate Summit in Chile show us that it can be done!"
            </blockquote>
            <div style={{marginTop: '4px'}}>- Hanneke van Ormondt, Urgenda</div>
        </div>
        <div className="four wide column large person">
            <div style={{minHeight: '100px'}}>
                <a href="http://www.ews-schoenau.de" target="_blank"><img
                    src="resources/images/EWS.jpg" className="logo"
                    style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
            </div>
            <h3>EWS</h3>
            <a href="http://www.ews-schoenau.de" target="_blank">www.ews-schoenau.de</a>
            <blockquote style={{marginTop: '4px'}}>
                "For a sustainable energy supply, for energy justice and climate protection, against nuclear power and coal-fired power - the cooperative EWS and their associations combine economy with civic commitment.”
            </blockquote>
        </div>
        <div className="four wide column large person">
            <div style={{minHeight: '100px'}}>
                <a href="http://www.firmofthefuture.nl" target="_blank"><img
                    src="resources/images/firmofthefurturelogo.jpg" className="logo"
                    style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
            </div>
            <h3>Firm of the Future</h3>
            <a href="http://www.firmofthefuture.nl" target="_blank">www.firmofthefuture.nl</a>
            <blockquote style={{marginTop: '4px'}}>
                "Firm of the Future is proud supporter of the ‘Sail to the COP’ initiative. Think different, use
                technology and change behavior is crucial to make it happen.<br/>
                These young changemakers are taking the lead in this journey towards a sustainable future. Good luck in
                creating this change!"
            </blockquote>
            <div style={{marginTop: '4px'}}>- Joost Brouwer, Firm of the Future</div>
        </div>
    </div>,
        <div className="row persons sponsors" key="sponsors2">
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.universiteitleiden.nl/en/governance-and-global-affairs/leiden-university-college-the-hague"
                       target="_blank"><img
                        src="resources/images/LUC.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Leiden University College<br/>The Hague</h3>
                <a href="https://www.universiteitleiden.nl/en/governance-and-global-affairs/leiden-university-college-the-hague"
                   target="_blank">www.universiteitleiden.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Leiden University College The Hague is a liberal arts and sciences college focusing on global
                    challenges, such as sustainability. LUC fosters critical, independent, and creative thinking in
                    students, and provides them with the knowledge and skills necessary to become socially responsible
                    and engaged citizens. Sail to the COP is an excellent example of this, and LUC is proud to support
                    our students and the rest of the crew with this trip."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.dreyer.at" target="_blank"><img
                        src="resources/images/Dreyer.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Dreyer Ventures & Management</h3>
                <a href="http://www.dreyer.at" target="_blank">www.dreyer.at</a>
                <blockquote style={{marginTop: '4px'}}>
                    "One sailboat alone will not save the world &mdash; but it can inspire us to rethink flying. Of
                    Dreyer Ventures‘ investments, 10% go to Social Entrepreneurs; 'Sail to the COP' sparked an intense
                    discussion on whether to expand support also to Ecological Projects."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://sgp-treuhand.de" target="_blank"><img
                        src="resources/images/SGP.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>SGP</h3>
                <a href="http://sgp-treuhand.de" target="_blank">www.sgp-treuhand.de</a>
                <blockquote style={{marginTop: '4px'}}>
                    "As a business law firm we support companies, freelancers and individuals in economic questions. Whether tax-, legal- or business advice - by exchanging ideas on an interdisciplinary basis we offer our clients various services from a single source and strive for a holistic approach.
                    We are happy to support this group of young change makers, seeking to steer our world into a more sustainable future."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://ecobusamerica.com" target="_blank"><img
                        src="resources/images/ecobusamerica.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Eco Bus America</h3>
                <a href="http://ecobusamerica.com" target="_blank">ecobusamerica.com</a>
                <blockquote style={{marginTop: '4px'}}>
                    "With my project I aim to inspire children and adults and stimulate their pro-environmental
                    behaviour. I want to strengthen people’s internal locus of control and show them that their actions
                    can bring about change. ‘Sail to the COP’ is a bottom-up movement as well which shares my vision on
                    how to tackle the deterioration of the environment and therefore I am glad that with my project 'Eco
                    Bus America' I am a partner and a participant on board!"
                </blockquote>
                <div style={{marginTop: '4px'}}>- Jorien Timmers, Eco Bus America</div>
            </div>
        </div>,
        <div className="row persons sponsors" key="sponsors3-4">
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.greentickets.app" target="_blank"><img
                        src="resources/images/Green-Tickets-logo-slogan-klein.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Green Tickets</h3>
                <a href="http://www.greentickets.app" target="_blank">www.greentickets.app</a>
                <blockquote style={{marginTop: '4px'}}>
                    "We don't need to travel less, but differently. <em>Sail to the COP</em> is exploring the future of
                    travel and bringing the discussion to a global stage."
                </blockquote>
                <div style={{marginTop: '4px'}}>- Jeppe Bijker, Green Tickets</div>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://zomerzondervliegen.be" target="_blank"><img
                        src="resources/images/Zomer-zonder-vliegen.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Zomer Zonder Vliegen</h3>
                <a href="https://zomerzondervliegen.be" target="_blank">www.zomerzondervliegen.be</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Zomer Zonder Vliegen (Summer Without Flying) wants to question the self-evidence of air travel and
                    challenges travellers to rediscover the alternatives. A fair and just international policy framework
                    is key if we want to persuade more and more people to choose for sustainable travel. The COP25 is
                    the place to make a difference on a global scale, a sailing voyage to Chile is the perfect way to go
                    there!"
                </blockquote>
                <div style={{marginTop: '4px'}}>- Peter Paul Vossepoel, Zomer Zonder Vliegen</div>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.ezs.nl" target="_blank"><img
                        src="resources/images/Zeevaartschool.jpg" className="logo"
                        style={{maxWidth: '80%', paddingTop: '20px'}}/></a>
                </div>
                <h3>Enkhuizer Zeevaartschool</h3>
                <a href="https://www.ezs.nl" target="_blank">www.ezs.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    "At the Enkhuizen Maritime College we train students from all over the world to become certified
                    sailors on sailing vessels. Sailing is in our genes; working towards clean oceans and a healthy
                    environment is our passion."
                    {/*Cosmo Wassenaar, Enkhuizer Zeevaartschool*/}
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.deceuvel.nl" target="_blank"><img
                        src="resources/images/deceuvel.png" className="logo"
                        style={{maxWidth: '40%', paddingTop: '20px'}}/></a>
                </div>
                <h3>De Ceuvel</h3>
                <a href="https://www.deceuvel.nl" target="_blank">www.deceuvel.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                </blockquote>
            </div>
        </div>,

        <div className="row persons sponsors" key="sponsors3-5">
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://dutchwavemakers.world" target="_blank"><img
                        src="resources/images/dutchwavemakers.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Dutch Wavemakers</h3>
                <a href="http://dutchwavemakers.world" target="_blank">dutchwavemakers.world</a>
                <blockquote style={{marginTop: '4px'}}>
                    Leaving a legacy is the greatest donation you can make.
                    Inspire the next generation!
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.fairclimatefund.nl/" target="_blank"><img
                        src="resources/images/FairClimateFund.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>FairClimateFund</h3>
                <a href="http://www.fairclimatefund.nl/" target="_blank">fairclimatefund.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                    FairClimateFund helps people, companies and events to make their business operations, products and/or services Fairtrade CO₂ neutral. We support this young enthusiastic group of changemakers by making their journey completely Fairtrade CO₂ neutral. We believe in their mission and think that the world needs to invest in sustainable travel today!
                </blockquote>
            </div>
        </div>,

        <div className="row" key="sponsors3">
            <a name="material-sponsors"/>
            <div className="sixteen wide column center aligned">
                <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>
                <h2>Material Sponsors</h2>
            </div>
        </div>,

        <div className="row persons sponsors" key="sponsors4">
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.twothirds.com" target="_blank"><img
                        src="resources/images/twothirds.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>TWOTHIRDS</h3>
                <a href="https://www.twothirds.com" target="_blank">twothirds.com</a>
                <blockquote style={{marginTop: '4px'}}>
                    "When the team from “Sail to the Cop” approached us, we were immediately interested in supporting
                    them.
                    They are fighting for more awareness of CO<sub>2</sub> emissions and its reduction, which is exactly
                    what we are trying with our way of producing our clothing.
                    All of us need to be aware of it and take small steps towards this goal and the “Sail to the Cop”
                    team is hopefully setting an ambitious first sign that more people will follow!"
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.drbronner.de" target="_blank"><img
                        src="resources/images/drbronner.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Dr. Bronner’s</h3>
                <a href="https://www.drbronner.de" target="_blank">drbronner.de</a>
                <blockquote style={{marginTop: '4px'}}>
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.hellyhansen.com" target="_blank"><img
                        src="resources/images/HH.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Helly Hansen</h3>
                <a href="https://www.hellyhansen.com" target="_blank">hellyhansen.com</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Founded in Norway in 1877, Helly Hansen continues to develop professional-grade apparel that helps people stay and feel alive. We are also passionate supporters of many environmental issues and work proactively to improve the overall standards in the Outdoor industry. Sailing is at the core of Helly Hansen, and we are happy to be able to support the Sail to the COP initiative. We wish the team best of luck in their endeavors."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://georganics.com/" target="_blank"><img
                        src="resources/images/Georganics.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Georganics</h3>
                <a href="http://georganics.com/" target="_blank">georganics.com</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Georganics is proud to sponsor Sail To The Cop because we believe it is vitally important to look
                    after our planet and be sustainable in as many ways as possible, whether its through travel, oral
                    care or in everyday living. We therefore are pleased to provide natural and eco oral care to the
                    sailing team, allowing them to smile all the way to Chile!"
                </blockquote>
            </div>
        </div>,

        <div className="row persons sponsors" key="sponsors5">

            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://stopmicrowaste.com" target="_blank"><img
                        src="resources/images/StopMicroWaste.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px'}}/></a>
                </div>
                <h3>Stop! Micro Waste</h3>
                <a href="https://stopmicrowaste.com" target="_blank">stopmicrowaste.com</a>
                <blockquote style={{marginTop: '4px'}}>
                    "STOP! Micro Waste is a non-profit initiative to educate about (micro)plastic pollution and initiate
                    solutions to minimise the release of (micro)plastics into nature. Plastic not only pollutes our
                    oceans, but also contributes to climate change in many ways. We support 'Sail to the Cop', because
                    we believe that humanity not only has to reinvent the ways we produce and consume towards
                    circularity, but rethink mobility patterns."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.100procenteco.com/" target="_blank"><img
                        src="resources/images/100eco.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '40px'}}/></a>
                </div>
                <h3>100%eco</h3>
                <a href="http://www.100procenteco.com/" target="_blank">100procenteco.com</a>
                <blockquote style={{marginTop: '4px'}}>
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.recolution.de" target="_blank"><img
                        src="resources/images/recolution.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '40px'}}/></a>
                </div>
                <h3>recolution</h3>
                <a href="https://www.recolution.de" target="_blank">www.recolution.de</a>
                <blockquote style={{marginTop: '4px'}}>
                    "recolution is a sustainable fashion brand from Hamburg, Germany.
                    Since the beginning of our journey our clothes are vegan, fair, organic and GOTS certificated.
                    We fight everyday for a change in the fashion industry and for the future of our children. That’s why we are happy to support anyone who does the same. Stay cozy in our shirts and confident with your goals!"
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://www.ooshi-berlin.de" target="_blank"><img
                        src="resources/images/ooshi.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '140px', paddingTop: '20px'}}/></a>
                </div>
                <h3>ooshi</h3>
                <a href="http://www.ooshi-berlin.de" target="_blank">ooshi-berlin.de</a>
                <blockquote style={{marginTop: '4px'}}>
                    "ooshi ist eine Female Health Company ans Berlin, gegründet von den Freundinnen Kati und Kristine. ooshi entwickelt, produziert und verkauft Periodenunterwäsche, die Binden und Tampons ersetzen kann, und dabei so schön und gemütlich ist wie der Lieblings-Slip. Für eine komfortable und nachhaltige Periode."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="http://beyondmeat.com/" target="_blank"><img
                        src="resources/images/Beyond Meat.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Beyond Meat</h3>
                <a href="https://beyondmeat.com" target="_blank">beyondmeat.com</a>
                <blockquote style={{marginTop: '4px'}}>
                    Beyond Meat is committed to positively impacting climate change and addressing global resource constraints through the production of plant-based meats. The production of a Beyond Burger uses 99% less water, 93% less land, 90% fewer Greenhouse Gas Emissions and 46% less energy than a beef burger. We believe there's a better way to feed the planet, and we've created one savory solution that's committed to making an impact on the planet.
                </blockquote>
            </div>
        </div>,
        <div className="row persons sponsors" key="sponsors6">
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.sonett.eu/eur" target="_blank"><img
                        src="resources/images/Sonnett.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Sonnet</h3>
                <a href="https://www.sonett.eu/eur" target="_blank">sonett.eu</a>
                <blockquote style={{marginTop: '4px'}}>
                    "Sonett was founded motivated by the sincere concern for water pollution. We are protecting the environment for over 40 years by producing ecological detergents and soaps. We regard the initiative of every individual as very important. But we think, that a healthy change comes to the world when individual growth of awareness leads to corporate action in the society. "Sail to the COP" can be an example of this, that`s why Sonett is very happy to support the team and to help that the washing and cleaning products on board are as well climate protectively."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://dutchgardenseeds.com" target="_blank"><img
                        src="resources/images/Dutch Garden Seeds.jpg" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Dutch Garden Seeds</h3>
                <a href="https://dutchgardenseeds.com" target="_blank">dutchgardenseeds.com</a>
                <blockquote style={{marginTop: '4px'}}>
                    "We of www.dutchgardenseeds.com gives the people of the "sail to the Cop" team organic sprouting seeds and microgreens seeds to grow their own food during the boot trip! By eating and growing your own food, you are working in a healthy way and you have to take less food with you. We are aware of all the poison that is sprayed to make our food grow, we are agaist this! Therefore we sponsor the team of Sail to the Cop Team."
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://www.tuinplus.nl" target="_blank"><img
                        src="resources/images/Tuinplus.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Tuinplus</h3>
                <a href="https://www.tuinplus.nl" target="_blank">www.tuinplus.nl</a>
                <blockquote style={{marginTop: '4px'}}>
                </blockquote>
            </div>
            <div className="four wide column large person">
                <div style={{minHeight: '100px'}}>
                    <a href="https://moneybird.com" target="_blank"><img
                        src="resources/images/Moneybird.png" className="logo"
                        style={{maxWidth: '80%', maxHeight: '100px', paddingTop: '10px'}}/></a>
                </div>
                <h3>Moneybird</h3>
                <a href="https://moneybird.com" target="_blank">moneybird.com</a>
                <blockquote style={{marginTop: '4px'}}>
                </blockquote>
            </div>
        </div>,
    ];


function Ambassadors() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment first padded white-background">
                <div className="ui top center aligned stackable grid container centered">
                    <div className="row">
                        <div className="column fourteen wide center aligned">
                            <h1>Partners</h1>

                            <p>
                                Sail to the COP would not be possible with our amazing partners, sponsors and
                                ambassadors who support us financially, in-kind or otherwise. Find out who is supporting
                                our project on this page or join them and support our call for sustainable travel!
                            </p>

                            <div className="row center aligned">
                                <Link to="/partners/join" className="ui button primary">Become a partner</Link>
                            </div>
                        </div>
                    </div>

                    {PartnersRow}

                    <div className="row">
                        <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>
                    </div>

                    {SponsorsRow}


                    <div className="row">
                        <div className="column sixteen wide">
                            <h1>Ambassadors</h1>

                        </div>
                    </div>

                    {AmbassadorsRow}

                    <div className="row center aligned">
                        <Link to="/partners/join" className="ui button primary">Become a partner</Link>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Ambassadors;

import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import {Link} from "react-router-dom";
import {Button, Checkbox, Embed, Form, Icon, Image, Label, Loader, Message} from "semantic-ui-react";

let rewards = {
    handkerchief: 10,
    postcardmadrid: 20,
    postcard: 22,
    postcards: 50,
    share: 50,
    wine: 75,
    departuredinner: 100,
    video: 100,
    dinner: 500,
    sponsor: 500,
    talk: 2000,
};

// const stripe = window.Stripe && window.Stripe('pk_test_8X9mAu8bSTPigf2JNB4wwxRQ00EK7inTqp');
const stripe = window.Stripe && window.Stripe('pk_live_7PUJfDBm4ayj1lsle5kLj8ty00ltDmjIJt');
const elements = stripe && stripe.elements();

const getStripeElement = (type) => {
    if (!window[type] && elements) window[type] = elements.create(type, {
        style: {
            base: {
                padding: '10px 12px',
                color: '#32325d',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                },
                // boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
            },
            invalid: {
                color: '#fa755a',
            },
        }
    });
    return window[type];
};

const RewardItem = ({reward, currentReward, setReward, children}) => <div className="item">
    <a className={"reward flex content" + (currentReward.indexOf(reward) !== -1 ? " selected" : "")}
       onClick={() => setReward(reward)}>
        <div className="checkbox-container">
            <Checkbox readOnly={true} checked={currentReward.indexOf(reward) !== -1}/>
        </div>
        <div className="text">
            {children}
            <div>
                {rewards[reward] > 50 &&
                <small style={{
                    float: "right",
                    lineHeight: (reward === "talk" ? '13px' : '20px'),
                    maxWidth: '80%',
                    textAlign: 'right'
                }}>
                    {reward === "talk" &&
                    <span>Includes company logo on our website</span>}
                    {/*<span>Includes departure party invite</span>}*/}
                </small>
                }
                <span className="ui label small">€ {rewards[reward]}</span>
            </div>
        </div>
    </a>
</div>;

class CrowdfundingPayment extends React.Component {

    state = {
        loading: true,
        total: 5000,
        value: 0,
        days: 0,
        idealBank: getStripeElement("idealBank"),
        // card: getStripeElement("card"),

        fields: {
            amount: "",
            name: "",
            mail: "",
            comments: "",
            reward: [],
            method: null,
            publish: false,
            newsletter: false,
        },
        disabled: false,
        url: null,

    };

    form = React.createRef();
    readMore = React.createRef();

    componentDidMount() {

        let _this = this;

        let ref = firebase.database().ref('crowdfunding');
        ref.on('value', function (snapshot) {
            let funding = snapshot.val();
            _this.setState({
                value: funding.value,
                loading: false,
            })
        });

        this.updateCheckoutElement();

        const {reward} = this.props.match.params;

        if (reward) {
            this.setReward(reward)
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {

        const {reward} = nextProps;

        if (reward) {
            this.setReward(reward)
        }

    }

    updateCheckoutElement() {
        const {idealBank, card} = this.state;
        const method = this.state.fields.method;

        if (method === "ideal") {
            if (idealBank) idealBank.mount('#ideal-bank-element');
        } else if (method === "card" && card) {
            // card.mount('#card-element');
            // card.addEventListener('change', function (event) {
            //     var displayError = document.getElementById('card-errors');
            //     if (event.error) {
            //         displayError.textContent = event.error.message;
            //     } else {
            //         displayError.textContent = '';
            //     }
            // });
        }
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }

    handleChangeCheckbox(e) {
        if (!e || !e.target || !e.target.name) return;

        let fields = this.state.fields;
        fields[e.target.name] = e.target.checked;

        this.setState({
            fields
        })
    }

    handleMethodChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        }, this.updateCheckoutElement);
    }

    // handleMethodChangeButton(e, method) {
    //
    //     e.preventDefault();
    //
    //     let fields = this.state.fields;
    //     fields[e.target.name] = e.target.value;
    //     this.setState({
    //         fields
    //     });
    //
    //     if (!this.state.paymentIntent) {
    //
    //     }
    // }

    // getPaymentIntent() {
    //
    //     let _this = this;
    //     let {fields} = this.state;
    //     let amountCents = Math.round(parseFloat((fields.amount + "").replace(",", ".")) * 100);
    //
    //     fetch('https://us-central1-youth-for-sustainable-travel.cloudfunctions.net/webhooks/payment-intent', {
    //         method: 'POST',
    //         body: JSON.stringify({amount: amountCents}),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     }).then(res => res.json())
    //         .then(response => {
    //             console.log('Success:', response);
    //             _this.setState({paymentIntent: response});
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //             alert("Something went wrong... Please contact us at info@sailtothecop.com")
    //         });
    //
    // }

    setReward(reward) {

        let fields = this.state.fields;
        fields["amount"] = rewards[reward];

        if (fields.reward.indexOf(reward) !== -1) {
            fields["reward"] = [];
        } else {
            fields["reward"] = [reward];
        }

        this.setState({
            fields
        }, this.checkAmount);
    }

    checkAmount() {

        let {fields} = this.state;
        let {amount, reward} = fields;
        let parsedAmount = parseFloat((amount + "").replace(",", "."));

        const formatter = new Intl.NumberFormat('nl-NL', {
            // style: 'currency',
            // currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: false,
        });


        let newRewards = [];
        if (reward && reward.length > 0) {
            for (let rewardId of reward) {
                if (parsedAmount >= rewards[rewardId]) {
                    newRewards.push(rewardId);
                }
            }
        }

        fields.amount = formatter.format(parsedAmount);
        fields.reward = [...newRewards];

        if (parsedAmount >= 50 && newRewards.indexOf("share") === -1) {
            fields.reward.push("share");
        }
        if (parsedAmount >= 1000 && newRewards.indexOf("sponsor") === -1 && newRewards.indexOf("talk") !== -1) {
            fields.reward.push("sponsor");
        }

        // let disabled = !(parsedAmount > 0);

        this.setState({
            fields,
            // disabled,
        })

    }

    async checkout() {

        this.setState({loading: true});
        let _this = this;

        const {idealBank, card, fields, sessionId} = this.state;
        const method = fields.method;

        let checkoutId = "-";

        let info = {...fields};

        info.amount = Math.round(parseFloat((fields.amount + "").replace(",", ".")) * 100);

        //@TODO minimum donation?
        if (!fields.amount || info.amount < 200) {
            alert("Sorry, we cannot accept donations under € 2.");
            this.setState({loading: false});
            return;
        }

        const source = method === "ideal" ? idealBank : card;


        let db = firebase.firestore();
        let values = {...info};
        values.createdAt = new Date();

        let description = "Donation to Sail to the COP";

        try {
            window.ga('send', 'event', {
                eventCategory: 'Donation',
                eventAction: 'checkout',
                eventLabel: info.amount / 100
            });
        } catch (e) {
        }

        db.collection("checkouts").add(values)
            .then((ref) => {
                checkoutId = ref.id;

                return ref.collection("payment").doc("status").set({completed: false});

            }).then(async () => {
            _this.setState({sent: true});

            let owner = {
                email: info.mail,
            };
            if (info.name) owner.name = info.name;


            // stripe.handleCardPayment(paymentIntent.client_secret, card, {
            //
            // })

            if (method === "card") {

                let session = await fetch('https://us-central1-youth-for-sustainable-travel.cloudfunctions.net/webhooks/checkout', {
                    method: 'POST',
                    body: JSON.stringify({
                        amount: info.amount,
                        clientReferenceId: checkoutId,
                        customerEmail: info.mail,
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                    .then(response => {
                        console.log('Success:', response);

                        let sessionId = response.id; //response.id

                        stripe.redirectToCheckout({sessionId});

                    })
                    .catch(error => {
                        console.error('Error:', error);
                        alert("Something went wrong... Please contact us at info@sailtothecop.com")
                    });



            } else {

                // iDeal only!

                stripe.createSource(source, {
                    type: method,
                    amount: info.amount,
                    currency: 'eur',
                    owner,
                    metadata: {
                        id: checkoutId,
                        description,
                    },
                    redirect: {
                        return_url: 'https://www.sailtothecop.com/support/payment/' + checkoutId,
                    },
                    statement_descriptor: description,
                }).then(function (result) {
                    // handle result.error or result.source

                    if (result.source) {

                        if (method === "ideal") {
                            window.location.href = result.source.redirect.url;

                            window.setTimeout(() => {
                                _this.setState({url: result.source.redirect.url});
                            }, 500);

                        } else {
                            window.location.href = 'https://www.sailtothecop.com/support/payment/' + checkoutId;
                            // window.location.href = "https://us-central1-youth-for-sustainable-travel.cloudfunctions.net/webhooks/stripe-card?cid=" + checkoutId + "&sid=" + result.source.id;
                            //Charge card
                        }
                    } else {
                        //Error!
                        if (result.error) {
                            _this.setState({
                                loading: false
                            });
                            alert(result.error.message);
                            // $("#submit2").removeClass("loading");
                        }
                    }

                })
                    .catch(function (error) {
                        alert("Something went wrong (e1), please let us know through info@sailtothecop.com");
                    });

            }
        })
            .catch(function (error) {
                alert("Something went wrong (e2), please let us know through info@sailtothecop.com");
            });

    }


    render() {
        const {total, value, loading, days, fields, disabled, url, paymentIntent} = this.state;
        const {amount, name, mail, comments, reward, method, publish, newsletter} = fields;

        return <div className="sail crowdfunding-page">

            <div className="ui vertical stripe padded first colored-background-alternative no-bottom">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column twelve wide center aligned">

                            <Link to="/support"><h1>Crowdfunding Sail to the COP Madrid</h1></Link>
                            <h2 style={{textAlign: "center"}}>Together we can change the future of travel</h2>

                            <Button as={Link} to="/support" icon labelPosition="left">
                                <Icon name="left arrow"/>
                                Read more
                            </Button>

                        </div>

                    </div>
                    <div className="row">
                        <div className="column twelve wide">

                            {url ? <div style={{textAlign: 'center'}}>
                                    <br/><br/><br/><br/>
                                    <a href={url}
                                       className="ui button primary">
                                        Continue&nbsp;
                                        <i className="ui icon arrow right"/>
                                    </a>
                                    <br/><br/><br/><br/>
                                </div> :
                                <div className="ui card fluid no-border-mobile">
                                    <div className="content">
                                        <div className="ui grid stackable divided">
                                            <div className="row">

                                                <div className="column nine wide">

                                                    <Form ref={this.form} loading={loading}
                                                          onSubmit={() => this.checkout()}>
                                                        <div className="field">
                                                            <label>Amount</label>
                                                            <div className="ui labeled input">
                                                                <label htmlFor="amount" className="ui label">€</label>
                                                                <input type="text" name="amount" id="amount"
                                                                       placeholder="Amount" value={amount}
                                                                       onChange={(e) => this.handleChange(e)}
                                                                       onBlur={(e) => this.checkAmount()}
                                                                       disabled={paymentIntent}
                                                                       className="crowdfunding-amount"/>
                                                            </div>
                                                        </div>

                                                        <div className="field">
                                                            <label>Name</label>
                                                            <input type="text" name="name" placeholder="Name"
                                                                   value={name}
                                                                   onChange={(e) => this.handleChange(e)}/>
                                                        </div>
                                                        {/*<div className="field">*/}
                                                        {/*    <label>Publish name</label>*/}
                                                        {/*    <Checkbox name="publish" id="publish"*/}
                                                        {/*              checked={publish}*/}
                                                        {/*              onChange={(e) => this.handleChangeCheckbox(e)}*/}
                                                        {/*              style={{float: "left"}}*/}
                                                        {/*    />*/}
                                                        {/*    <label style={{fontWeight: "normal"}} htmlFor="publish">*/}
                                                        {/*        &nbsp; Show my name in the public list of donations.*/}
                                                        {/*    </label>*/}
                                                        {/*</div>*/}
                                                        <div className="field">
                                                            <label>Newsletter</label>
                                                            <Checkbox name="newsletter" id="newsletter"
                                                                      checked={newsletter}
                                                                      onChange={(e) => this.handleChangeCheckbox(e)}
                                                                      style={{float: "left"}}
                                                            />
                                                            <label style={{fontWeight: "normal"}} htmlFor="newsletter">
                                                                &nbsp; Subscribe me to the <em>Sail to the
                                                                COP</em> newsletter.
                                                            </label>
                                                        </div>
                                                        <div className="field">
                                                            <label>E-mail address</label>
                                                            <input type="text" name="mail" placeholder="E-mail address"
                                                                   value={mail} onChange={(e) => this.handleChange(e)}/>
                                                        </div>

                                                        <div className="field">
                                                            <label>Comments</label>
                                                            <textarea rows="2" name="comments" value={comments}
                                                                      onChange={(e) => this.handleChange(e)}/>
                                                        </div>

                                                        <div className="field">
                                                            <label>Payment method:</label>
                                                            {/*<Button*/}
                                                            {/*    onClick={(e) => this.handleMethodChangeButton(e, 'ideal')}*/}
                                                            {/*    size="small"*/}
                                                            {/*    active={method === "ideal"}>iDeal</Button>*/}
                                                            {/*<Button*/}
                                                            {/*    onClick={(e) => this.handleMethodChangeButton(e, 'card')}*/}
                                                            {/*    size="small"*/}
                                                            {/*    active={method === "ideal"}>Credit Card</Button>*/}
                                                            <div className="field">
                                                                <div className="ui radio checkbox">
                                                                    <input type="radio" name="method" id="method-ideal"
                                                                           value="ideal" checked={method === "ideal"}
                                                                           onClick={(e) => this.handleMethodChange(e)}/>
                                                                    <label htmlFor="method-ideal">
                                                                        iDeal
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="field">
                                                                <div className="ui radio checkbox">
                                                                    <input type="radio" name="method" id="method-credit"
                                                                           value="card" checked={method === "card"}
                                                                           onClick={(e) => this.handleMethodChange(e)}/>
                                                                    <label htmlFor="method-credit">
                                                                        Credit card
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            {method === "ideal" &&
                                                            <div className="field">
                                                                <label>Choose your bank</label>
                                                                <div id="ideal-bank-element"/>
                                                            </div>}

                                                            <p className="small">
                                                                Your payment will be processed by Stripe on behalf
                                                                of <em>Stichting
                                                                Youth for Sustainable Travel</em>.
                                                            </p>

                                                            <button className="ui button primary" type="submit"
                                                                    disabled={disabled}>Submit
                                                            </button>
                                                    </Form>

                                                    <br/><br/>
                                                    <Message size="tiny">
                                                        <Message.Header>Donate in other ways</Message.Header>
                                                        You can also transfer money to NL34 BUNQ 2034 8454 71, on behalf
                                                        of
                                                        Stichting Youth for Sustainable Travel.<br/>
                                                        Or donate on <a
                                                        href="https://bunq.me/sailtothecop"
                                                        target="_blank">https://bunq.me/sailtothecop</a>.
                                                    </Message>

                                                </div>

                                                <div className="column seven wide ui divided list no-padding">
                                                    <h2 style={{padding: '10px', paddingBottom: 0}}>Support Us</h2>

                                                    {/*<RewardItem reward="handkerchief" currentReward={reward}*/}
                                                    {/*            setReward={this.setReward.bind(this)}>*/}
                                                    {/*    <h4>"Vaar Wel" Handkerchief</h4>*/}
                                                    {/*    <Image src="resources/images/handkerchief.png" floated="right"*/}
                                                    {/*           size="tiny"*/}
                                                    {/*           style={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}/>*/}
                                                    {/*    <p>*/}
                                                    {/*        Receive an exclusive Sail to the COP handkerchief to see us*/}
                                                    {/*        off the old fashioned way!*/}
                                                    {/*    </p>*/}
                                                    {/*</RewardItem>*/}
                                                    <RewardItem reward="postcardmadrid" currentReward={reward}
                                                                setReward={this.setReward.bind(this)}>
                                                        <h4>Postcard from Madrid</h4>
                                                        <p>
                                                            We'll send you a handwritten postcard from COP25 in Madrid to thank
                                                            you for being part of our journey!
                                                        </p>
                                                    </RewardItem>
                                                    <RewardItem reward="postcard" currentReward={reward}
                                                                setReward={this.setReward.bind(this)}>
                                                        <h4>Postcard from Martinique</h4>
                                                        <p>
                                                            We'll send you a handwritten postcard from the Sail to the COP headquarters during COP25 to thank
                                                            you for being part of our journey!
                                                        </p>
                                                    </RewardItem>

                                                    <RewardItem reward="postcards" currentReward={reward}
                                                                setReward={this.setReward.bind(this)}>
                                                        <h4>Set of custom designed postcards</h4>
                                                        <p>
                                                            Receive a set of 5 custom designed Sail to the COP postcards.
                                                        </p>
                                                    </RewardItem>
                                                    {/*<RewardItem reward="share" currentReward={reward}*/}
                                                    {/*            setReward={this.setReward.bind(this)}>*/}
                                                    {/*    <h4>Invite to our departure party</h4>*/}
                                                    {/*    <p>*/}
                                                    {/*        Invest in our common future and come wish us a good trip at*/}
                                                    {/*        our*/}
                                                    {/*        departure party in Amsterdam!*/}
                                                    {/*    </p>*/}
                                                    {/*</RewardItem>*/}
                                                    {/*<RewardItem reward="wine" currentReward={reward}*/}
                                                    {/*            setReward={this.setReward.bind(this)}>*/}
                                                    {/*    <h4>Bottle of Chilean Wine</h4>*/}
                                                    {/*    <p>*/}
                                                    {/*        We will bring a bottle of a fine organic wine back from*/}
                                                    {/*        Chile.*/}
                                                    {/*    </p>*/}
                                                    {/*</RewardItem>*/}
                                                    {/*<RewardItem reward="departuredinner" currentReward={reward}*/}
                                                    {/*            setReward={this.setReward.bind(this)}>*/}
                                                    {/*    <h4>Departure dinner</h4>*/}
                                                    {/*    <p>*/}
                                                    {/*        Get one place at our exclusive departure dinner in Amsterdam!*/}
                                                    {/*    </p>*/}
                                                    {/*</RewardItem>*/}

                                                    <RewardItem reward="video" currentReward={reward}
                                                                setReward={this.setReward.bind(this)}>
                                                        <h4>Personal video message</h4>
                                                        <p>
                                                            Receive a personal video message recorded on the Regina Maris! Ask us a question or dedicate the video to someone else.
                                                        </p>
                                                    </RewardItem>
                                                    <RewardItem reward="sponsor" currentReward={reward}
                                                                setReward={this.setReward.bind(this)}>
                                                        <h4>Company sponsor</h4>
                                                        <p>
                                                            Your company's logo will be featured on our website and in
                                                            our
                                                            social media campaign.
                                                        </p>
                                                    </RewardItem>
                                                    <RewardItem reward="talk" currentReward={reward}
                                                                setReward={this.setReward.bind(this)}>
                                                        <h4>Workshop or talk at your organization</h4>
                                                        <p>
                                                            After our trip we will come to a location of your
                                                            choice (in or near The Netherlands) to give a workshop or
                                                            talk.
                                                        </p>
                                                    </RewardItem>
                                                    <div className="item">
                                                        <Link to="/partners/join" className="reward content">

                                                            <h4>Become a partner</h4>
                                                            <p>Are you willing to support us in another way? Become a
                                                                partner of Sail to the COP!</p>
                                                        </Link>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>


                    <div className="row">
                        <div className="fourteen wide column center aligned">
                            <h1>Questions?</h1>
                            <p>Do you have questions or do you want to support us in a different way? Don't hesitate
                                to <Link to="/project/contact">contact us</Link>!</p>
                        </div>
                    </div>
                    <div className="row center aligned">
                        <div className="twelve wide column center aligned">
                            <a href="mailto:info@sailtothecop.com" className="ui button basic primary non-capitalized">
                                <i className="ui icon mail outline"></i>
                                info@sailtothecop.com
                            </a>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <a href="tel:0031633149435" className="ui button basic primary">
                                <i className="ui icon phone"></i>
                                +31 6 33 14 94 35
                            </a>
                        </div>
                    </div>

                    <br/><br/>
                    <br/><br/>

                </div>


            </div>


        </div>

    }
}

export default CrowdfundingPayment;

import React from 'react';
import {Link, Route} from "react-router-dom";
import {Card, Header, Image} from "semantic-ui-react";

function About() {
    return (
        <div className="about">


            <div className="ui vertical stripe segment padded first ">
                <div className="ui top center aligned stackable grid container centered">
                    <div className="row">
                        <div className="column fourteen wide center aligned">
                            <h1 className="bright">The Sail to the COP organization</h1>
                            <p>We're a dedicated team working on voluntary basis. Based in Amsterdam, with a large
                                national and international network and expertise in movement building, think tanks,
                                climate science, international NGOs, sustainable mobility and entrepreneurship. Want to
                                help build the sustainable future of travel? <Link to="/project/contact">Join us!</Link></p>
                            <br/>
                        </div>
                    </div>


                    <div className="row persons">
                        <div className="four wide column large person">
                            <h3>&nbsp;</h3>
                            <img src="resources/images/Lena-3.jpg" className="person"/><br/>
                            <h5>Impact & Program</h5>
                            <a href="mailto:lena@sailtothecop.com" className="mail">lena@sailtothecop.com</a>
                        </div>
                        <div className="ten wide column left aligned">
                            <h3>Lena Hartog</h3>

                            <blockquote className="large">
                                "The climate and ecological crises ask for a global movement and for every industry
                                to
                                make bold an
                                fast changes. I believe we can build a world where sustainable travel is the
                                standard
                                and not the
                                exception. I am very excited to work together to create a shared vision, hold our
                                leaders
                                accountable and take action."
                            </blockquote>

                            <p>
                                Lena is a freelance action-researcher working part-time for social enterprise <a
                                href="https://www.projectcrossover.nl/" target="_blank">CrossOver</a> in Amsterdam. For
                                this project she is building on her experience being part of the <a
                                href="https://nationale-denktank.nl/" target="_blank">National Think</a> tank in 2017,
                                where she did research on the future of the labour market.
                                As active member of the climate movement in the Netherlands she was part of the
                                organizing team of the first <a href="https://www.lcoy.nl/" target="_blank">Local
                                Conference of Youth</a> in the Netherlands in
                                2018 and she is currently board member of <a href="https://gofossilfree.org/nl/"
                                                                             target="_blank">Fossielvrij Netherlands</a>.
                                She studied sociology
                                and psychology at University College Utrecht and graduated with a masters in Sociology
                                from the University of Amsterdam.
                            </p>

                        </div>
                    </div>
                    <div className="row persons">
                        <div className="ten wide column left aligned">
                            <h3>Mara de Pater</h3>
                            <blockquote className="large">
                                "I am personally often challenged in combining my adventurous side with my ideals
                                when
                                it comes to
                                climate change. With Sail to the COP I want to explore this while taking the public
                                debate about
                                unfair regulation of aviation to a global stage."
                            </blockquote>
                            <p>
                                Mara has an MSc degree in Cultural Anthropology from Utrecht University and is currently
                                graduating for her second MSc in Environmental Science at Wageningen University &
                                Research. In her studies she focuses on linkages between (de-)democratic processes and
                                climate advocacy. For example, by researching energy democracy challenges in Colorado
                                (USA) and illiberalism and climate policy in Hungary. Besides studying she volunteers
                                for climate and nature organizations such as <a href="https://www.njn.nl/"
                                                                                target="_blank">Nederlandse Jeugdbond
                                voor Natuur</a> and
                                <a href="https://gofossilfree.org/nl/"
                                   target="_blank">Fossielvrij</a>. Recently she participated in <a
                                href="https://www.clean2antarctica.nl/en/explore/challenging-companies-and-millenials"
                                target="_blank">Quest for Change</a>, a sailing think tank on the
                                Dutch energy transition.
                            </p>

                        </div>
                        <div className="four wide column large person">
                            <h3>&nbsp;</h3>
                            <img src="resources/images/Mara.jpeg" className="person"/><br/>
                            <h5>Events & Grants</h5>
                            <a href="mailto:mara@sailtothecop.com" className="mail">mara@sailtothecop.com</a>
                        </div>
                    </div>
                    <div className="row persons">
                        <div className="four wide column large person">
                            <h3>&nbsp;</h3>
                            <img src="resources/images/Moon.jpg" className="person"/><br/>
                            <h5>Partnerships</h5>
                            <a href="mailto:moon@sailtothecop.com" className="mail">moon@sailtothecop.com</a>
                        </div>
                        <div className="ten wide column left aligned">
                            <h3>Moon Weijens</h3>
                            <blockquote className="large">
                                "Always up for adventures, I'm very excited to develop a positive travel movement.
                                As an
                                international water management student, I know how devastating the impact of the
                                current
                                greenhouse
                                gas emission is for our earth, and how it's largely affecting people who never set
                                foot
                                on a plane.
                                Let's change that!"
                            </blockquote>
                            <p>
                                Moon studies International Land and Water Management at Wageningen University & Research
                                and she is a water ambassador appointed by the Ministry of Infrastructure and Water
                                Management. Water is of vital importance for our ecosystems and society but climate
                                change is rapidly changing water systems. With enthusiasm Moon shares her
                                (water)knowledge and addresses the urgency of the climate change crisis during guest
                                lectures, her internship as a geography teacher and as a <a
                                href="https://www.dutchwavemakers.nl/" target="_blank">Dutch Wavemaker</a>. Last winter
                                Moon worked together with people from different backgrounds on innovative ideas to push
                                the textile industry towards a circular economy for the project <a
                                href="https://www.clean2antarctica.nl/en/explore/challenging-companies-and-millenials"
                                target="_blank">Quest for Change</a>. She
                                was grateful to be selected for this sailing think tank which dropped her of on the
                                other side of the Atlantic enabling her to do research on Integrated Water Resource
                                Management and climate change resilience in Suriname.
                            </p>

                        </div>
                    </div>
                    <div className="row persons">
                        <div className="ten wide column left aligned">
                            <h3>Jeppe Bijker</h3>
                            <blockquote className="large">
                                "With my start-up Green Tickets I'm helping people to find the most sustainable ways
                                to
                                travel
                                &mdash; but to improve the current options and stop the unfair subsidies on flying
                                we
                                really have to
                                look at a global level: the COP."
                            </blockquote>
                            <p>
                                Freelance designer and software developer. As founder of <a
                                href="https://www.greentickets.app" target="_blank">Green
                                Tickets</a> Jeppe is building a platform to stimulate sustainable traveling, by making
                                it easier to find, compare and book alternatives for flying.
                                To facilitate sustainable long-distance
                                traveling he recently co-founded the website <a href="https://www.sailscanner.org"
                                                                                target="_blank">Sail Scanner</a>.<br/>
                                He is campaigner and web developer at the <a href="https://www.greentickets.app"
                                                                             target="_blank">Jonge
                                Klimaatbeweging</a> and organized a trip with 35 young people to COP24 in Katowice,
                                Poland last year &mdash; by bus and train.<br/>
                                Jeppe studied Industrial Design Engineering at Delft University of Technology with an
                                MSc degree in Design for Interaction.

                            </p>

                        </div>
                        <div className="four wide column large person">
                            <h3>&nbsp;</h3>
                            <img src="resources/images/Jeppe-BU130.jpg" className="person"/><br/>
                            <h5>Communication</h5>
                            <a href="mailto:jeppe@sailtothecop.com" className="mail">jeppe@sailtothecop.com</a>
                        </div>
                    </div>
                </div>


            </div>

            <a name="media" id="media">&nbsp;</a>

            <div className="ui vertical stripe segment padded first left-right-padded">
                <div className="ui top center aligned stackable grid centered">
                    <div className="row">
                        <div className="column fourteen wide center aligned">
                            <h1 className="bright">Former Projects</h1>
                            <p>We have been working on aviation, climate activism and other sustainability topics the past years. Read more about some of our former projects!</p>
                            <br/>
                        </div>
                    </div>

                    <div className="row">


                        <div className="column four wide">
                            <Card fluid>
                                <Image src="resources/images/Parool-Lena-Jeppe.jpg" />
                                <Card.Content>
                                    <Header>Parool: Deze reizigers vermijden het vliegtuig voor een beter milieu</Header>
                                    <a href="https://www.parool.nl/nieuws/deze-reizigers-vermijden-het-vliegtuig-voor-een-beter-milieu~bc57728b/" target="_blank">www.parool.nl</a>
                                </Card.Content>
                                <Card.Content>
                                    Sail to the COP members Lena and Jeppe shared their stories why they don't fly anymore with Het Parool.
                                </Card.Content>
                            </Card>
                        </div>


                        <div className="column four wide">
                            <Card fluid>
                                <Image src="resources/images/1199_fullimage_jeppe-bijker-naar-berlijn-dt.jpg" />
                                <Card.Content>
                                    <Header>NS: Reis mee met Jeppe Bijker – Oprichter Green Tickets</Header>
                                    <a href="https://www.nsinternational.nl/nl/nieuws/relaxed-reizen?utm_source=linkedin&utm_medium=tijdlijn&utm_campaign=zak_2019_01&utm_content=zakelijk&utm_term=reizigersverhaal1_Jeppe" target="_blank">www.nsinternational.nl</a>
                                </Card.Content>
                                <Card.Content>
                                    Dutch Railway company NS featured Jeppe's startup Green Tickets in a story on the benefits of travelling by train.
                                </Card.Content>
                            </Card>
                        </div>

                        <div className="column four wide">
                            <Card fluid>
                                <Image src="resources/images/1540388647782-duurzaamheidlena.png.jpeg" />
                                <Card.Content>
                                    <Header>Lena Hartog on Vice</Header>
                                    <a href="https://www.vice.com/nl/partners/gemeente-duurzaamheid/een-klimaatactivist-vertelt-hoe-je-vanuit-je-luie-stoel-kunt-actievoeren" target="_blank">www.vice.com</a>
                                </Card.Content>
                                <Card.Content>
                                    Read Lena's story on why she became an activist and how everyone can contribute to a more sustainable world.
                                </Card.Content>
                            </Card>
                        </div>

                        <div className="column four wide">
                            <Card fluid>
                                <Image src="resources/images/Lena-Brandpunt.jpg" />
                                <Card.Content>
                                    <Header>Lena Hartog on Brandpunt</Header>
                                    <a href="https://www.npostart.nl/lichaam-in-de-strijd/14-11-2017/POMS_KN_11621757" target="_blank">www.npostart.nl</a>
                                </Card.Content>
                                <Card.Content>
                                    "Een nieuwe generatie klimaatactivisten staat op en probeert met ludieke acties en massale demonstraties de wereld te behoeden voor een catastrofe. Liesbeth Staats trekt een paar dagen op met één van die nieuwe demonstranten: Lena Hartog."
                                </Card.Content>
                            </Card>
                        </div>
                    </div>

                    <div className="row">

                        <div className="column four wide">
                            <Card fluid>
                                <Image src="resources/images/Trouw-klein.jpg" />
                                <Card.Content>
                                    <Header>Trouw: Deze site maakt trein- en bustickets boeken makkelijk én duurzaam</Header>
                                    <a href="https://www.trouw.nl/groen/deze-site-maakt-trein-en-bustickets-boeken-makkelijk-en-duurzaam~afc40a63/" target="_blank">www.trouw.nl</a>
                                </Card.Content>
                                <Card.Content>
                                    Trouw wrote about Green Tickets and how Jeppe wants to change the way we travel through Europe.
                                </Card.Content>
                            </Card>
                        </div>

                        <div className="column four wide">
                            <Card fluid>
                                <Image src="resources/images/Lena-NRC.jpg" />
                                <Card.Content>
                                    <Header>NRC: Nationale Denktank</Header>
                                    <a href="https://www.nrc.nl/nieuws/2017/12/08/zo-hadden-we-het-nog-niet-bekeken-a1584309?fbclid=IwAR2g753LhZz5Nyt1xbQj4Bv02_YT4SiYVJgYZLXdjy-KjJN6AESGk0v6T5A" target="_blank">www.nrc.nl</a>
                                </Card.Content>
                                <Card.Content>
                                    Lena Hartog was part of the Nationale Denktank in 2017.
                                </Card.Content>
                            </Card>
                        </div>


                        <div className="column four wide">
                            <Card fluid>
                                <Image src="resources/images/DSC09890-small.jpg" alt="COPop - Youth for Climate Impact" />
                                <Card.Content>
                                    <Header>COPop: trip to COP24 in Katowice</Header>
                                    <a href="https://www.copop.eu" target="_blank">www.copop.eu</a>
                                </Card.Content>
                                <Card.Content>
                                    With 35 young people we travelled to the climate conference COP24 in Katowice, Poland. We travelled by bus and train, sponsored by the NS.

                                </Card.Content>
                                <Card.Content>
                                    <a href="https://nos.nl/op3/artikel/2263604-niet-met-het-vliegtuig-zoals-de-rest-maar-met-de-trein-naar-de-top.html" target="_blank">
                                        NOS: Niet met het vliegtuig zoals de rest, maar met de trein naar de top
                                    </a><br /><br />
                                    <a href="https://www.duurzamestudent.nl/wp-content/uploads/2018/12/COP24-e1544798548748.png" target="_blank">
                                        Duurzame Student: Een kijkje bij COP24 in Katowice!
                                    </a><br /><br />
                                    <a href="https://www.facebook.com/JongerenMilieuActief/videos/2372020922870311/" target="_blank">
                                        Radio 1 interview overhandiging manifest aan staatssecretaris
                                    </a><br /><br />
                                    <a href="https://www.nu.nl/256730/video/met-de-trein-naar-klimaattop-van-de-zotte-dat-wordt-gevlogen.html" target="_blank">
                                        Nu.nl: Met de trein naar klimaattop: 'Van de zotte dat wordt gevlogen'
                                    </a><br /><br />
                                </Card.Content>
                            </Card>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;

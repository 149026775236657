import React from 'react';
import {Image} from "semantic-ui-react";

function OutputProblem() {
    return (
        <div className="output">


            <div className="ui vertical stripe padded">
                <div className="ui top stackable grid centered">

                    <div className="row">
                        <div className="column ten wide">
                            <h1>Problem Analysis</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column one wide"/>
                        <div className="column ten wide">
                            <h2>An increasing travel demand favouring the most carbon-intensive mode of transportation:
                                aviation.</h2>
                            <p>
                                The demand for travel has risen across the globe following a higher living standard.
                                People continuously desire to travel far and often, for a variety of reasons. We witness
                                this trend in numerous industries, but it culminates in tourism. Transportation, in
                                terms of public, private and commercial options, is a fundamental aspect to travel.
                                While trains were the main mode of international transportation within Europe in the mid
                                20th century, aviation has now taken the centre stage. Meanwhile, intercontinental
                                travel has skyrocketed. According to the IPCC, the transport sector was already
                                responsible for 23% of global energy related emissions in 2010; with figures said to
                                have increased since then. Low-carbon travel options are plentiful, all in varying
                                degrees more climate-friendly compared to airplanes. These include trains, buses, bikes,
                                sailboats, ferries, zeppelins, light rail, car sharing and others. Travel emissions are
                                a significant part of how we are heating our planet. Considering the severity of the
                                climate crisis, it is undeniably concerning that the travel industry is not effectively
                                reducing its emissions.
                            </p>

                        </div>

                        <div className="column five wide" style={{paddingTop: '60px'}}>
                            <Image src="/resources/images/output/2dirtyairport.jpg"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column ten wide">
                            <h2>Aviation and the damage done</h2>
                            <p>
                                Despite becoming more efficient and technologically advanced, the aviation industry has
                                doubled its emissions in the past 20 years’. Although aviation is responsible for at
                                least 2% of global CO2 emissions, it is already responsible for over 5.5% of global
                                warming, with estimates of up to 7%. Moreover, passenger numbers are expected to double
                                within the next two decades, significantly intensifying the impacts of the industry. Due
                                to its continuous growth of emissions, and the reduction of emissions in other sectors,
                                it is estimated that aviation alone will emit 22% of global carbon emissions by 2050.
                                Aviations effects on global warming are even more severe, since warming is based on much
                                more than carbon emissions. The altitude at which the combination of greenhouse gases
                                (including black carbon, water vapour, nitrogen oxides and sulphur dioxide) is emitted
                                and the formation of contrails and cirrus clouds are ways in which aeroplanes’ emissions
                                trap radiation in our atmosphere, heating up our planet. The International Panel on
                                Climate Change (IPCC) estimates that the impact of aviation is 2.7 times the impact of
                                its carbon emissions alone. While attempting to limit global warming to 1.5°C in an
                                effort to withhold major repercussions to the natural world, we cannot further ignore
                                the negative impacts of the travel industry.

                            </p>

                            <h2>Social injustices and false solutions
                            </h2>

                            <p>
                                Beyond critiques of environmental damages, the travel industry is built upon numerous
                                social injustices. Over 80% of the world’s population have never set foot on an
                                airplane. While a minority have the privilege to fly, the majority do not. Yet, it is
                                many of the world’s poorest that experience the worst consequences of climate change.
                                The very infrastructure that makes this inequality possible is founded on similar
                                dynamics of power. Countless airports have been built and expanded on the land of the
                                unheard. On top of evictions and plain disregard of land rights, airports come with
                                numerous health risks and noise pollution. These and numerous others are stories
                                shadowing the infinite growth model of the aviation industry. Damage done to the
                                environment is legitimized by unrealistic carbon offsetting schemes. Far from a
                                solution, these schemes are highly inefficient and insufficient, as well as being
                                riddled with human rights violations and cases of land grabbing. Another proposed false
                                solution is the use of biofuels, amongst other unfeasible techno-fixes, all of which do
                                not respond to the urgency of the climate crisis and distract from the conversations we
                                need to have.

                            </p>


                        </div>
                    </div>
                    <div className="row">

                        <div className="column five wide" style={{paddingTop: '20px',textAlign: 'right'}}>
                            <Image src="/resources/images/output/policyscale2.jpg" style={{height: '300px'}} floated="right"/>
                        </div>
                        <div className="column ten wide">

                            <h2>Sustainable transport modes in the background
                            </h2>

                            <p>
                                So, what makes taking an airplane so attractive compared to other modes of travel? A
                                primary reason is that aviation does not need over-land infrastructure, and therefore,
                                does not face connectivity issues across borders and between modes of transport. Missing
                                links between different modes of low-carbon transport hamper the process of creating a
                                single travel (transportation) system. Lack of data availability and data sharing by and
                                between sustainable transportation modes impairs international cooperation between
                                operating companies. Countries further hinder this cooperation by enforcing restrictions
                                on cross-border traffic or upholding incompatible infrastructure, such as rail tracks
                                that do not match. For consumers, it therefore remains an effort to choose sustainable
                                transport modes over their climate-intensive counterpart.
                            </p>

                            <h2>How aviation is favoured</h2>
                            <p>
                                Next to practicalities, decision-making on aviation regulation (or lack thereof) has
                                been short-sighted and prioritised economic benefits over environmental and social
                                impacts. European aviation has been freed from commercial restrictions since 1992 and
                                currently benefits from facilitative political measures, both nationally and
                                internationally. The industry enjoys competitive advantages over sustainable
                                transportation modes, enabling it to outcompete more climate-friendly options even for
                                short distances. The following are several systemic examples that emphasize the
                                fundamental imbalance of the playing field for the travel industry, putting aviation
                                ahead of alternatives:
                                <ul>
                                    <li>Policy measures: tax-exemptions for kerosene and VAT, emission allowances for
                                        aviation in the EU Emissions Trading System (ETS), exclusion of aviation from
                                        Nationally Determined Contributions (NDCs) in the Paris Agreement, lack of
                                        commitment to and exemption from policies on sustainable transportation.
                                    </li>
                                    <li>Lobby and legitimization of unfeasible techno-fix solutions: e.g. biofuels and
                                        carbon offsetting hailed as responsible ways to deal with travel emissions and
                                        continue unconstrained growth.
                                    </li>
                                </ul>
                            </p>

                            <h2>The effect on travel cultures </h2>
                            <p>
                                Through these and other facilitative measures, air travel has become rooted in peoples’
                                everyday lives. Widely accessible and affordable flight connections have a clear effect
                                on travel habits and mentality. Frequent flyers, for business or leisure, view flying as
                                inherent to travel, all the while disregarding the associated environmental and social
                                impacts. People often justify air travel by portraying it as a need, whether that be for
                                work, to escape stress or to have a yearly holiday. Moreover, the visibility of status
                                through the likes of social media, in the workplace and within social circles creates
                                social pressure to see more of the world or to further one's career. This perception has
                                encouraged a culture of entitlement towards flying - that it is a person’s right.
                                Regarding leisure travel, limited time incites people to fast travel and to focus on
                                destination over journey. For businesses, time is inherently linked to productivity and
                                the relatively low costs of flights make businesses barely reflect on the actual need of
                                travel. All this results in a culture of climate-intensive travel.

                            </p>

                        </div>

                        <div className="column one wide" />
                    </div>


                    <div className="row">
                        <div className="column ten wide center aligned">
                            <img src="/resources/images/output/scribble-1.jpg" /><br /><br />
                        </div>
                            <div className="column ten wide">
                            <h2>Quick summary of what we are facing
                            </h2>

                            <ul>
                                <li>The travel sector is unsustainable and unjust.</li>
                                <li>The use of air travel for both short and long distances has become a systemic
                                    problem, aggravating the climate crisis without a future prospect of reducing its
                                    impacts.
                                </li>
                                <li>The demand for cheap and fast trips continues to increase, both for leisure and
                                    business.
                                </li>
                                <li>Flying habits have become incompatible with sustainability targets and the
                                    industry’s lack of responsibility gives rise to issues of social injustice.
                                </li>
                                <li>Sustainable travel alternatives, including trains, busses, light rail and bikes, are
                                    outcompeted in demand due to poor connectivity, missing links in infrastructure and
                                    lack of data sharing.
                                </li>
                                <li>Facilitative policies aided by a strong lobby for aviation and a focus on
                                    techno-fixes have created an unbalanced playing field in terms of affordability and
                                    accessibility, favouring aviation.
                                </li>
                                <li>The highly polluting nature of aviation – impacting climate and society through much
                                    more than solely CO2 emissions – is not reflected in ticket prices and is rarely
                                    included in discussions at political level, nor highlighted in media outlets or
                                    working places.
                                </li>
                                <li>A culture of quick and fast travel has taken the stage, where a mentality of
                                    entitlement to aviation services hold travellers back from actively considering
                                    alternatives.
                                </li>
                                <li>The majority of the world population carries the environmental and social costs
                                    while only 18% of the world population have ever flown. This statistic alone should
                                    invoke a sense of climate responsibility within societies with frequent travellers.
                                </li>

                            </ul>


                        </div>
                    </div>


                </div>
            </div>

        </div>
    );
}

export default OutputProblem;

import React from 'react';
import {Link} from "react-router-dom";
import {Button, Card, Header, Label} from "semantic-ui-react";
import Newsletter from "../../newsletter";


export const Meetups = () => {
    return null;
    // [
    //
    //     <div className="row centered" key="meetups-1">
    //         <div className="column fourteen wide center aligned">
    //             <h2>Upcoming meet-ups</h2>
    //             <p>The applications to participate in Sail to the COP are open! The deadline is the 1st
    //                 of June. During the month May we will host a series of meet-ups for everyone
    //                 interested to receive information about our project.</p>
    //         </div>
    //     </div>,
    //
    //     <div className="row centered" key="meetups-2">
    //         <div className="column four wide">
    //             <Card fluid>
    //                 <Card.Content>
    //                     <Header>Online Meet-up</Header>
    //                     <Label>Monday 13 May 2019</Label>
    //                     <Label>20:00</Label>
    //                 </Card.Content>
    //                 <Card.Content>
    //                     <a href="https://www.facebook.com/events/1855740844526071/"
    //                             target="_blank">Facebook event</a>
    //                 </Card.Content>
    //             </Card>
    //         </div>
    //
    //
    //         <div className="column four wide">
    //             <Card fluid>
    //                 <Card.Content>
    //                     <Header>Amsterdam Meet-up</Header>
    //                     <Label>Tuesday 14 May 2019</Label>
    //                     <Label>20:00</Label>
    //                 </Card.Content>
    //                 <Card.Content>
    //                     <a href="https://www.facebook.com/events/2147564448654745/"
    //                             target="_blank">Facebook event</a>
    //                 </Card.Content>
    //             </Card>
    //         </div>
    //
    //
    //         <div className="column four wide">
    //             <Card fluid>
    //                 <Card.Content>
    //                     <Header>Wageningen Meet-up</Header>
    //                     <Label>Thursday 16 May 2019</Label>
    //                     <Label>20:00</Label>
    //                 </Card.Content>
    //                 <Card.Content>
    //                     <a  href="https://www.facebook.com/events/418122275687844/"
    //                             target="_blank">Facebook event</a>
    //                 </Card.Content>
    //             </Card>
    //         </div>
    //
    //
    //         <div className="column four wide">
    //             <Card fluid>
    //                 <Card.Content>
    //                     <Header>Utrecht Meet-up</Header>
    //                     <Label>Wednesday 22 May 2019</Label>
    //                     <Label>20:00</Label>
    //                 </Card.Content>
    //                 <Card.Content>
    //                     <a href="https://www.facebook.com/events/333257344050348/"
    //                             target="_blank">Facebook event</a>
    //                 </Card.Content>
    //             </Card>
    //         </div>
    //
    //     </div>
    // ];
}


function Participants() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">



                    <div className="row">
                        <div className="column fourteen wide">


                            <div className="ui segment">
                                <h3>Applications closed...</h3>
                                <p>
                                    Unfortunately our applications have closed. But don't worry, this Sail to the COP will most likely not be the last of its kind. Sign up for our newsletter for all our updates!
                                </p>
                                <p>
                                    Want to get involved in a diferent way? We can still use your help, in preparation of the trip or during the COP for example. Don't hesitate to send us an e-mail if you might be able to support us in any way!
                                </p>

                            </div>


                            <h1>We are looking for adventurous changemakers</h1>
                            <p>
                                Do you want to share your time and talents to shape the future of travel?
                            </p>
                            <p>
                                For Sail to the COP we are looking for proactive, creative and adventurous people who
                                like to engage with climate change issues.
                            </p>
                            <p>
                                We have 20 spots divided over three kinds of participants:
                                <ol>
                                    <li>Sailors joining from an organization</li>
                                    <li>Sailors for the media team</li>
                                    <li>Sailors joining individually</li>
                                </ol>
                            </p>


                        </div>
                    </div>
                    <div className="row center aligned">
                        <Link to="/join/selection" className="ui button primary">Selection process <i
                            className="ui icon arrow right"/></Link>
                    </div>


                    <div className="row">
                        <img src="resources/images/np_wave_736809_ED3E1E.png" className="wave"/>
                    </div>


                    <Newsletter />
                    {/*<Meetups />*/}

                </div>
            </div>

        </div>
    );
}

export default Participants;

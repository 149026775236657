import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';

class Tracker extends React.Component {

    state = {
        lastUpdateString: "never",
    };

    windyContainer = React.createRef();

    componentDidMount() {

        window.width = "100%";
        window.height = "400";
        window.mmsi = 246501000;

        let ref = firebase.database().ref('tracker');
        let _this = this;
        ref.on('value', function (snapshot) {
            let data = snapshot.val();
            if (data) {

                let updatedAt = new Date(data.updatedAt);
                _this.setState({
                    lastUpdateString: updatedAt.toString(),
                });

                try {

                    const options = {
                        key: 'k9lb74h6PwxE5xcq6hHV7VGIVhHn7YKc', // REPLACE WITH YOUR KEY !!!
                        lat: data.location.lat,
                        lon: data.location.lon,
                        zoom: data.location.zoom,
                    };

                    window.windyInit(options, windyAPI => {
                        const {map} = windyAPI;
                        const markers = [];

                        //windyContainer

                        const boat = "Regina Maris";
                        const marker = window.L.circle(
                            [data.location.lat, data.location.lon],
                            {
                                radius: 10000,
                                fillColor: "#ed3e1e",
                                stroke: false,
                                fillOpacity: 1,
                            }).addTo(map);

                        markers.push(marker);

                    });

                } catch (error) {
                    console.error(`Error querying boats: ${error.message}`);
                }

            }
        });

    }

    render() {

        const {lastUpdateString} = this.state;

        return (
            <div className="sail">

                <div className="ui vertical stripe segment padded first colored-background">

                    <div className="" style={{textAlign: "center"}}>
                        <h1>Sail to the COP Tracker</h1>

                        <p style={{padding: '20px'}}>
                            As long as we are near the shore you can track the live location of the ship on <a
                            href="https://www.vesselfinder.com/nl/vessels/REGINA-MARIS-IMO-7025126-MMSI-246501000"
                            target="_blank">Vesselfinder.com</a>.
                        </p>
                    </div>
                    <div className="" style={{textAlign: "center"}}>
                        {/*<script type="text/javascript" src="https://www.vesselfinder.com/aismap.js"></script>*/}
                        <div ref={this.windyContainer} id="windy"/>
                        <br/>
                        <span>
                                    Last update: <span>{lastUpdateString}</span>
                                </span>
                    </div>
                </div>

            </div>
        );
    }
}

export default Tracker;

import React from "react";
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import {FundingTimeline} from "./support/funding-timeline";
import {Button, ButtonGroup, Form, Label, Loader, Segment, Table} from "semantic-ui-react";


class ShowSupporters extends React.Component {

    state = {
        value: 0,
        total: 30000,
        loading: true,
        signedIn: false,
        supporters: [],
        newsletterSubscribers: [],
        rewards: {},
        showAll: true,

        username: "",
        password: "",
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    componentDidMount() {

        let _this = this;

        let ref = firebase.database().ref('crowdfunding');
        ref.on('value', function (snapshot) {
            let funding = snapshot.val();
            _this.setState({
                value: funding.value,
            })
        });

        // firebase.auth().signOut();

        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                let _this = this;
                _this.setState({signedIn: true});

                let db = firebase.firestore();
                db.collection("checkouts").limit(1000).orderBy("createdAt").get().then(async snapshot => {

                    let supporters = [];
                    let newsletterSubscribers = [];
                    let rewards = {};

                    let docs = snapshot.docs;

                    let promises = [];

                    for (let doc of docs) {

                        // snapshot.forEach(async doc => {
                        let data = {...doc.data()};
                        promises.push(doc.ref.collection("payment").doc("status").get().then(paymentDoc => {

                            if (paymentDoc) data.payment = paymentDoc.data();
                            // if (!data.payment || !data.payment.success) {
                            //     doc.ref.delete(); //Don't do this without deleting payment!
                            //     return;
                            // }

                            data.date = new Date(data.createdAt.seconds * 1000);
                            data.dateString = data.date && data.date.toISOString();

                            supporters.push(data);
                            if (data.newsletter) {
                                newsletterSubscribers.push(data);
                            }

                            if (data.payment && data.payment.success) {
                                for (let reward of data.reward) {
                                    if (!rewards[reward]) rewards[reward] = [];
                                    rewards[reward].push(data)
                                }
                            }
                        }));
                    } //);

                    Promise.all(promises).then(() => {

                        _this.setState({
                            loading: false,
                            supporters,
                            newsletterSubscribers,
                            rewards,
                        });
                    });
                });
            } else {
                _this.setState({signedIn: false});
            }
        }.bind(this));

    }

    signIn(e) {
        e.preventDefault();
        const {username, password} = this.state;

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(function () {
                return firebase.auth().signInWithEmailAndPassword(username, password);
            }).catch(function (error) {
            // Handle Errors here.
            if (error) {
                alert(error.message);
            }
        });

        return false;
    }

    render() {
        const {loading, total, value, supporters, signedIn, username, password, newsletterSubscribers, rewards, showAll} = this.state;

        // console.log(rewards);

        return <div>

            <div className="ui vertical stripe segment padded first colored-background-alternative">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column twelve wide center aligned">

                            <h1>Crowdfunding Supporters</h1>

                            <FundingTimeline value={value} total={total}/>

                            {loading && <Loader active size="small"/>}
                        </div>
                    </div>

                    {loading ?

                        <Loader active/> :

                        <div style={{textAlign: "left"}}>

                            {signedIn ? <div className="row">

                                    <div className="column fourteen wide">
                                        <Segment>
                                            <h2>Newsletter subscribers ({newsletterSubscribers.length})</h2>
                                            <pre>{newsletterSubscribers.map((supporter, i) => supporter.dateString + ', ' + supporter.mail).join("\n")}</pre>
                                        </Segment>
                                        <Segment>
                                            <h2>Supporter e-mails</h2>
                                            <pre>
                                            {supporters.filter(supporter => supporter.payment && supporter.payment.success && supporter.mail && supporter.mail != "")
                                                .map((supporter, i) => supporter.mail).join("\n ")}
                                            </pre>
                                        </Segment>
                                        <Segment>
                                            <h2>Rewards</h2>
                                            <em>Note: Not 100% checked if these numbers are right!</em>
                                            <Table striped>
                                                <Table.Body>
                                                    {Object.keys(rewards).map((reward) => <Table.Row key={reward}>
                                                        <Table.Cell>
                                                            <strong>{reward}</strong>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {rewards[reward].length}
                                                        </Table.Cell>
                                                    </Table.Row>)}
                                                </Table.Body>
                                            </Table>
                                        </Segment>

                                        <ButtonGroup>
                                            <Button active={showAll} onClick={() => this.setState({showAll: true})}>Show
                                                all</Button>
                                            <Button active={!showAll} onClick={() => this.setState({showAll: false})}>Hide
                                                unsuccessful</Button>
                                        </ButtonGroup>

                                        <Table striped>
                                            <Table.Body>
                                                {supporters.filter(s => showAll || (s.payment && s.payment.success)).map((supporter, i) =>
                                                    <Table.Row key={"s" + i}
                                                               disabled={!supporter.payment || !supporter.payment.success}>
                                                        <Table.Cell style={{width: '200px'}}>
                                                            <strong>{supporter.name}</strong></Table.Cell>
                                                        <Table.Cell>
                                                            {supporter.mail}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {supporter.dateString}
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '100px'}}>
                                                            € {supporter.amount / 100}<br/>
                                                            {supporter.payment && supporter.payment.canceled &&
                                                            <Label size="tiny" color="grey">canceled</Label>}
                                                            {supporter.payment && supporter.payment.failed &&
                                                            <Label size="tiny" color="failed">failed</Label>}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {supporter.reward.join(", ")}
                                                        </Table.Cell>
                                                        <Table.Cell style={{maxWidth: '300px'}}>
                                                            {supporter.comments}
                                                        </Table.Cell>
                                                    </Table.Row>)}
                                            </Table.Body>
                                        </Table>

                                        <pre style={{width: '1200px', maxWidth: '1200px'}}>

                                                {supporters.filter(s => showAll || (s.payment && s.payment.success)).map((supporter, i) =>
                                                    <>
                                                        {supporter.name};
                                                        {supporter.mail};
                                                        {supporter.dateString}
                                                        {supporter.amount / 100};
                                                        {supporter.payment && supporter.payment.success &&
                                                        <>success</>}
                                                        {supporter.payment && supporter.payment.canceled &&
                                                        <>canceled</>}
                                                        {supporter.payment && supporter.payment.failed &&
                                                        <>failed</>};
                                                        {supporter.reward.join(", ")};
                                                        {supporter.comments.replace(/\n/g, " ")}
                                                        {"\n"}
                                                    </>
                                                )}
                                        </pre>
                                    </div>


                                </div> :
                                <div className="row centered">
                                    <div className="column five wide">

                                        <Form onSubmit={(e) => this.signIn(e)}>
                                            <div className="field">
                                                <label>Username</label>
                                                <input type="text" name="username" placeholder="Username"
                                                       value={username}
                                                       onChange={this.handleChange.bind(this)}/>
                                            </div>
                                            <div className="field">
                                                <label>Password</label>
                                                <input type="password" name="password"
                                                       value={password} onChange={this.handleChange.bind(this)}/>
                                            </div>
                                            <input type="submit" onClick={(e) => this.signIn(e)}
                                                   className="ui button primary"/>
                                        </Form>

                                    </div>
                                </div>
                            }
                        </div>
                    }

                </div>
            </div>
        </div>

    }

}

export default ShowSupporters;
import React from 'react';
import {Link} from "react-router-dom";

function Selection() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column fourteen wide">
                            <h1>Selection Process</h1>

                            <p>
                                We are sailing to the COP with 36 participants, depending on the success of
                                the <Link to="/support/crowdfunding">crowdfunding</Link>. The majority of the fundraiser
                                will be done in May. On the first of June the
                                success of the fundraising will be evaluated and it will be decided how many
                                participants can join.
                            </p>
                            <p>
                                Interested participants can apply until June 1st using <a href="https://docs.google.com/forms/d/e/1FAIpQLSfi7rDBJNtuuCrH0Ot4qC2luRhl2ZHCwq0uUE7abCb06d3-3g/viewform?usp=sf_link"
                                                                                          target="_blank">this form</a>. In the first week of
                                June applicants might be approached for a (Skype) conversation to discuss the
                                application. The final selection will be based on the following aspects:
                            </p>
                            <p>
                                <ul>
                                    <li>Commitment to our mission and the topics of sustainable travel and aviation</li>
                                    <li>Motivation for a transformative on-board learning experience</li>
                                    <li>Diversity in the team in terms of occupation and professional network</li>
                                    <li>Diversity in the team in terms of perspective on the topic and expertise</li>
                                    <li>A balanced team in terms of age, gender and background</li>
                                </ul>
                            </p>

                            <p>
                                The selection process will be done by the core team and one experienced recruiter.
                            </p>

                            <p>
                                A number of interested participants has joined/will be joining the team as volunteers
                                before the selection. Volunteering does not secure a spot as participant. But it will
                                definitely play a key role in assessing the motivation of the interested participant.
                            </p>


                        </div>
                    </div>
                    <div className="row">
                        <div className="column fourteen wide">

                            <h2>What is expected from the participants</h2>

                            <p>
                                <ul>
                                    <li>You are available from Mid-September till the end of November to join the
                                        crossing.
                                    </li>
                                    <li>You will join our group to the COP25 in Santiago (2nd -13th December) and
                                        preferably also to the Conference of Youth COY15 (29th November - 1st December)
                                    </li>
                                    <li>You are able to pay or (crowd)fund the participants fee</li>
                                    <li>You are willing commit 4 - 8 hours per week in preparation of the project. This
                                        could be by helping organize or gain expertise on the topic of aviation and
                                        sustainable mobility
                                    </li>
                                    <li>You keep the weekend of the 17th and 18th of August free for the training
                                        weekend, somewhere in the Netherlands, in which we prepare for the trip and
                                        share expertise
                                    </li>
                                </ul>
                            </p>
                            <p>
                                The total costs of joining are €5000 - €7500 per person. We expect participants who join
                                without an organization to pay or (fund)raise €2500* before the 1st of August 2019.
                            </p>
                            <p>
                                These costs cover an all-inclusive sailing trip of approximately 8 weeks and traveling
                                and accommodation in Chile. The core-team will facilitate crowdfunding by setting up a
                                crowdfunding page and sharing strategies. Please get in touch if you have any questions
                                regarding the participants fee. We do realize that we might miss out on great assets to
                                the team that cannot make this commitment. So, we are open to discuss an allowance for
                                those that cannot raise or cover these costs, but comply to all other selection aspects.
                            </p>

                            <p>
                                <small>
                                    *€2500 is a reduced participant fee. Participants sponsored by their organizations
                                    pay a
                                    full fare. The reduced participant fee of €2500 is the maximum amount we’re asking
                                    people to guarantee. We will aim at lowering this number through the fundraiser
                                    during
                                    May, but cannot make promises regarding these costs. Participants are always welcome
                                    to
                                    pay a higher ‘solidarity’ fee.
                                    s
                                </small>
                            </p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="column fourteen wide">


                            <h2>What can the participants expect</h2>
                            <p>
                                <ul>
                                    <li>An approximately 8 weeks sailing trip from Europe to South America, in which
                                        accommodation, food and drinks are covered
                                    </li>
                                    <li>An on-board learning program focused on personal development and on the
                                        sustainable
                                        future of travel
                                    </li>
                                    <li>Travel costs and accommodation in Chile</li>
                                    <li>A training weekend the 17th and 18th of August, to get to know each other and
                                        learn more
                                        about aviation and sustainable travel
                                    </li>
                                </ul>
                            </p>

                            <p>
                                It is still uncertain whether we can provide you with a return trip, we do have contact
                                with different ships that will make the crossing from South America to Europe early 2020
                                and are willing to take some of our participants for a reduced price.
                            </p>


                        </div>
                    </div>

                    <div className="row center aligned">
                        <Link to="/join/apply" className="ui button primary">Apply <i
                            className="ui icon arrow right"/></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Selection;

import React, {Component} from 'react';
import {Grid, Image, Message} from "semantic-ui-react";
import Countdown from "./Countdown";
import {Translate} from "react-localize-redux";
import {Link} from "react-router-dom";

class Home extends Component {


    render() {

        return (
            <div className="page-container home">

                <Grid stackable className="ui grid stackable">

                    <Grid.Row>

                        <Grid.Column width={10}>

                            <Image src="/resources/images/share/Sail to the COP Team.jpg"/>

                            <Countdown/>

                        </Grid.Column>

                        <Grid.Column width={4}>
                            <h1>
                                <Translate id="home.title">
                                    We are sailing to the COP  to call for fair and
                                    climate-neutral
                                    travel for all</Translate>
                            </h1>


                            <p>
                                <Translate id="home.why-text">
                                    With a diverse group of changemakers we will travel to the UN Climate Conference
                                    COP25
                                     this fall.
                                </Translate>
                                &nbsp;
                                <Translate id="home.why-text-bold">Our main reason for going is that we are largely
                                    worried
                                    about the
                                    emissions of aviation - that is why we will not travel by plane but by sailing
                                    ship.</Translate>
                            </p>

                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row className="page-buttons">

                        <Grid.Column width={6} className="journey" as={Link} to={"/journey"}>
                            <div className="padding">
                                <h2>Our Journey</h2>
                                <span>
                                    Follow our journey across the ocean
                                </span>
                            </div>
                        </Grid.Column>

                        <Grid.Column width={6} className="travel" as={Link} to={"/future-of-travel"}>
                            <div className="padding">
                                <h2>The future of travel</h2>
                                <span>
                                    Read our analyses and ideas for the future of traveling
                                </span>
                            </div>
                        </Grid.Column>

                        <Grid.Column width={4} className="sail" as={Link} to={"/project"}>
                            <div className="padding">
                                <h2>Sail to the COP</h2>
                                <span>
                                    About our project and partners
                                </span>
                            </div>
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row className="media-container">

                    </Grid.Row>

                </Grid>


            </div>
        );
    }
}

export default Home;

import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column sixteen wide">
                            <h1>Terms and conditions & Privacy Policy</h1>

                            <h2 className="c5" id="h.s41pk5y8m55r"><span className="c8">Liability</span></h2>
                            <ul className="c6 lst-kix_dplww9sua9ec-0 start">
                                <li className="c0"><span className="c1">The organisers are explicitly no travel organisers in the sense of the Dutch law, in accordance with 7:500 BW and further, because organising trips is not part of the normal activities of the organisers. The organisers therefore decline all liability and responsibilities for any deficits before, during or after the trip.</span>
                                </li>
                                <li className="c0"><span className="c1">The participant acknowledges that the organisers cannot be held accountable for any shortcomings, problems or deficits before, during or after the trip.</span>
                                </li>
                                <li className="c0"><span className="c1">The participant states not to hold the organisation responsible for any such problems and accepts that the organisers are waived of any and all liability.</span>
                                </li>
                                <li className="c0"><span className="c1">The participant is strongly advised to arrange adequate (travel) insurance during the trip, failing to do so is entirely the risk of the participant.</span>
                                </li>
                            </ul>
                            <h2 className="c5" id="h.yk4ii4flgr38"><span className="c8">Privacy</span></h2>
                            <p>
                                If you donate through our website, you agree to the collection, use, and storage of information in relation with this policy. The information that we collect are used for administrative and legal purposes. We will not use or share your information with anyone except as described.
                            </p>
                            <p className="c4"><span className="c1">If you choose to join our trip, then you agree to the collection, use, and storage of information in relation with this policy. The Personal Information that we collect are used for providing and improving the trip. We will not use or share your information with anyone except as described. </span>
                            </p>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">When you join our trip, you provide us information, which we collect, use and store. The data include but are not limited to:</span>
                            </p>
                            <ul className="c6 lst-kix_bcq1majgvdyg-0 start">
                                <li className="c0"><span className="c1">Your name</span></li>
                                <li className="c0"><span className="c1">Your address</span></li>
                                <li className="c0"><span className="c1">Your date of birth</span></li>
                                <li className="c0"><span className="c1">Your passport or ID number</span></li>
                                <li className="c0"><span className="c1">Personal travel preferences</span></li>
                            </ul>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">We only require these data from you in order to organize Sail to the COP. These data are stored for a period of no longer then 12 months and deleted thereafter. We do not place cookies or log information about your visit to this website.</span>
                            </p>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">We will share your information, except date of birth and passport or ID number, with the other participants of the trip.</span>
                            </p>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">We value your trust in providing us your Personal Information, thus we strive to protect your information and use those means that will protect your data the best within commercially acceptable limits. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee the absolute security of your personal information.</span>
                            </p>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">Our Services do not address anyone of the age of 17 or younger. We do not knowingly collect personal identifiable information from children of the age of 17 or younger. In the case we discover that a child of the age of 17 or younger has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, we kindly ask you to contact us so that we will be able to do necessary actions.</span>
                            </p>
                            <h3 className="c14" id="h.1c4elt4ym7pm"><span className="c7">Service Providers</span></h3>
                            <p className="c4"><span className="c1">We employ third-party companies and individuals for the following reasons:</span>
                            </p>
                            <ol className="c6 lst-kix_3ywc2t8t8xqb-0 start" start="1">
                                <li className="c0"><span className="c1">To facilitate our Service;</span></li>
                                <li className="c0"><span className="c1">To perform Service-related services;</span></li>
                            </ol>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span>We want to inform our users that these third parties may have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</span>
                            </p>
                            <p>Payments are processed by <a href="https://stripe.com/us/privacy/" target="_blank">Stripe,
                                Inc.</a>. Payment data is not stored on our servers and you are therefore referred to
                                the <a href="https://stripe.com/us/privacy/" target="_blank">privacy statement</a> of
                                Stripe to learn more about the privacy of your payment information.</p>
                            <p>Our database is stored at <span
                                className="c2"><a className="c9"
                                                  href="https://www.google.com/url?q=https://firebase.google.com/&amp;sa=D&amp;ust=1539014596691000">Firebase by Google</a></span><span>, which you can read more about </span><span
                                className="c2"><a className="c9"
                                                  href="https://www.google.com/url?q=https://firebase.google.com/support/privacy/&amp;sa=D&amp;ust=1539014596691000">at Google</a></span><span>&nbsp;(including </span><span
                                className="c2"><a className="c9"
                                                  href="https://www.google.com/url?q=https://firebase.google.com/terms/data-processing-terms&amp;sa=D&amp;ust=1539014596691000">the data processing agreement</a></span><span
                                className="c1">).</span></p>
                            <h2 className="c5" id="h.jea8b88zsxup"><span className="c8">Contact Us</span></h2>
                            <p className="c4"><span className="c1">If you have any questions, complaints or suggestions about our Privacy Policy, do not hesitate to contact us at info@sailtothecop.com.</span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PrivacyPolicy;

import React from 'react';
import ShareTool from "./share-tool";
import {Button, Card, Divider, Header, Image, Label, Segment, Tab, Grid} from "semantic-ui-react";
import {Link} from "react-router-dom";

export const Publicity = () => <Grid stackable celled className="publicity">
    <Grid.Row centered key="pub1" stretched>

        <Grid.Column width={3} stretched>
            <a href="https://www.alldayeverydaisy.com/natuur/zeilen-naar-zuid-amerika/" target="_blank"><Image
                src="resources/images/all-day-every-daisy.png"/></a>
            <Header>8 weken zeilen om aandacht te vragen voor minder vliegen. Jeppe doet
                het!</Header>
            <a href="https://www.alldayeverydaisy.com/natuur/zeilen-naar-zuid-amerika/"
               target="_blank">www.alldayeverydaisy.com</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.duurzamestudent.nl/2019/05/28/niet-vliegen-maar-zeilen-naar-de-klimaattop/"
               target="_blank"><Image
                src="https://www.duurzamestudent.nl/wp-content/uploads/2019/02/DS_header_site-3.jpg"/></a>
            <Header>Niet vliegen, maar zeilen naar de klimaattop</Header>
            <a href="https://www.duurzamestudent.nl/2019/05/28/niet-vliegen-maar-zeilen-naar-de-klimaattop/"
               target="_blank">www.duurzamestudent.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.bartsboekje.com/eerst-crowdfunden-en-dan-zeilen-naar-de-cop-in-chili/"
               target="_blank"><Image
                src="/resources/images/Bartsboekje.png"
                style={{maxWidth: '80%'}}/></a>
            <Header>Eerst crowdfunden en dán zeilen naar de COP in Chili</Header>
            <a href="https://www.bartsboekje.com/eerst-crowdfunden-en-dan-zeilen-naar-de-cop-in-chili/"
               target="_blank">www.bartsboekje.com</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://wetskills.com/sailing-to-cop25-climate-conference-in-chili/" target="_blank"><Image
                src="https://wetskills.com/wp-content/uploads/2016/05/WetskillsLogo_website.png"/></a>
            <Header>Sailing To COP25 Climate Conference In Chili</Header>
            <a href="https://wetskills.com/sailing-to-cop25-climate-conference-in-chili/"
               target="_blank">www.wetskills.com</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://m.waterwereldwerk.nl/ambitieuze-zeiltocht-vraagt-aandacht-voor-klimaatneutraal-reizen-98e7c4bc790"
               target="_blank"><Image
                src="https://cdn-images-1.medium.com/fit/c/100/100/1*EGvqx0Q-tzMcITCk9Knw7A.png"/></a>
            <Header>Ambitieuze zeiltocht vraagt aandacht voor klimaatneutraal reizen</Header>
            <a href="https://m.waterwereldwerk.nl/ambitieuze-zeiltocht-vraagt-aandacht-voor-klimaatneutraal-reizen-98e7c4bc790"
               target="_blank">www.waterwereldwerk.nl</a>
        </Grid.Column>

    </Grid.Row>
    <Grid.Row centered key="pub2" stretched>


        <Grid.Column width={3} stretched>
            <a href="https://www.ikreisanders.nl/zeilend-naar-zuid-amerika"
               target="_blank"><Image
                src="https://www.ikreisanders.nl/static/media/globe.4cce9084.png"/></a>
            <Header>Zeilend naar Zuid-Amerika voor een eerlijke en duurzame toekomst van reizen</Header>
            <a href="https://www.ikreisanders.nl/zeilend-naar-zuid-amerika"
               target="_blank">www.ikreisanders.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://en.reset.org/blog/sail-cop-boating-amsterdam-chile-mission-change-future-travel-06062019"
               target="_blank"><Image
                src="https://en.reset.org/profiles/resetto/themes/bw/images/reset_org.png" style={{maxHeight: '40px'}}/></a>
            <Header>"Sail to the COP": Boating From Amsterdam to Chile on a Mission to Change the Future of
                Travel</Header>
            <a href="https://en.reset.org/blog/sail-cop-boating-amsterdam-chile-mission-change-future-travel-06062019"
               target="_blank">www.reset.org</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.zeilen.nl/nieuws/actueel/zeilend-naar-cop25-in-chili/"
               target="_blank"><Image
                src="https://www.zeilen.nl/app/uploads/2016/04/logo-2x.png" style={{maxHeight: '40px'}}/></a>
            <Header>Zeilend naar de VN Klimaatconferentie in Chili</Header>
            <a href="https://www.zeilen.nl/nieuws/actueel/zeilend-naar-cop25-in-chili/"
               target="_blank">www.zeilen.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://nationale-denktank.nl/blog/lena-hartog-zeilboot-chili/"
               target="_blank"><Image
                src="/resources/images/NDT-logo-horizontaal-RGB-2.jpg" style={{maxHeight: '40px'}}/></a>
            <Header>DenkTanker Lena Hartog al zeilend naar de klimaattop in Chili</Header>
            <a href="https://nationale-denktank.nl/blog/lena-hartog-zeilboot-chili/"
               target="_blank">www.nationale-denktank.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.forbes.com/sites/davidebanis/2019/06/07/this-european-travel-planner-helps-you-cut-down-the-environmental-impact-of-your-holidays/"
               target="_blank"><Image
                src="/resources/images/Forbes.png"/></a>
            <Header>This European Travel Planner Helps You Cut Down The Environmental Impact Of Your Holidays</Header>
            <a href="https://www.forbes.com/sites/davidebanis/2019/06/07/this-european-travel-planner-helps-you-cut-down-the-environmental-impact-of-your-holidays/"
               target="_blank">www.forbes.com</a>
        </Grid.Column>


    </Grid.Row>

    <Grid.Row centered key="pub3" stretched>

        <Grid.Column width={3} stretched>
            <a href="https://www.stern.de/neon/vorankommen/nachhaltigkeit/nachhaltig-reisen--dieses-start-up-segelt-fuer-das-klima-nach-chile-8774466.html?fbclid=IwAR1nvWmPwZFE-rcdyapF5qkypxnNtPj6I4rsq5k3aFg7R2-XEaMIpD4JnNk"
               target="_blank"><Image
                src="/resources/images/neon.png"/></a>
            <Header>Tschüss, Flugzeug? Wer nachhaltig leben will, muss auch seine Reisegewohnheiten überdenken</Header>
            <a href="https://www.stern.de/neon/vorankommen/nachhaltigkeit/nachhaltig-reisen--dieses-start-up-segelt-fuer-das-klima-nach-chile-8774466.html?fbclid=IwAR1nvWmPwZFE-rcdyapF5qkypxnNtPj6I4rsq5k3aFg7R2-XEaMIpD4JnNk"
               target="_blank">www.stern.de</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.giornaledellavela.com/2019/06/25/greta-thunberg-diventa-velista-raggiungera-lamerica-con-una-barca/"
               target="_blank"><Image
                src="/resources/images/vela.png"/></a>
            <Header>Greta Thunberg diventa velista. Raggiungerà l’America con una barca</Header>
            <a href="https://www.giornaledellavela.com/2019/06/25/greta-thunberg-diventa-velista-raggiungera-lamerica-con-una-barca/"
               target="_blank">www.giornaledellavela.com</a>
        </Grid.Column>


        <Grid.Column width={3} stretched>
            <a href="https://www.trouw.nl/duurzaamheid-natuur/hijs-de-zeilen-recht-zo-die-gaat-naar-chili-voor-de-klimaattop~b39f65334/"
               target="_blank"><Image
                src="/resources/images/trouw.jpg"/></a>
            <Header>Hijs de zeilen! Recht zo die gaat naar Chili voor de Klimaattop</Header>
            <a href="https://www.trouw.nl/duurzaamheid-natuur/hijs-de-zeilen-recht-zo-die-gaat-naar-chili-voor-de-klimaattop~b39f65334/"
               target="_blank">www.trouw.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://dezwijger.nl/programma/laat-je-stem-horen#_=_"
               target="_blank"><Image
                src="/resources/images/zwijger.png"/></a>
            <Header>Het Nieuwe Klimaat: Laat je stem horen</Header>
            <a href="https://dezwijger.nl/programma/laat-je-stem-horen#_=_"
               target="_blank">www.dezwijger.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.travindy.com/2019/07/plan-to-sail-to-chile-climate-conference-and-promote-sustainable-tourism-seeks-funders/"
               target="_blank"><Image
                src="https://www.travindy.com/wp-content/uploads/dynamik-gen/theme/images/travindy-large-logo.png"/></a>
            <Header>Plan to sail to Chile climate conference and promote sustainable tourism seeks funders</Header>
            <a href="https://www.travindy.com/2019/07/plan-to-sail-to-chile-climate-conference-and-promote-sustainable-tourism-seeks-funders/"
               target="_blank">www.travindy.com</a>
        </Grid.Column>

    </Grid.Row>

    <Grid.Row centered key="pub4" stretched>

        <Grid.Column width={3} stretched>
            <a href="https://resource.wur.nl/en/show/WUR-students-sail-a-three-master-to-Chile-climate-summit.htm"
               target="_blank"><Image
                src="/resources/images/Resource.jpg"/></a>
            <Header>WUR students sail a three-master to Chile climate summit</Header>
            <a href="https://resource.wur.nl/en/show/WUR-students-sail-a-three-master-to-Chile-climate-summit.htm"
               target="_blank">resource.wur.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://blog.forestfinance.de/2019/07/23/5-fragen-an-sail-to-the-cop/"
               target="_blank"><Image
                src="https://www.forestfinance.de/fileadmin/ResourcesForestFinance/Images/forestfinance-logo.svg"/></a>
            <Header>5 Fragen an: Sail to the COP</Header>
            <a href="https://blog.forestfinance.de/2019/07/23/5-fragen-an-sail-to-the-cop/"
               target="_blank">blog.forestfinance.de</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.ksta.de/region/rhein-sieg-bonn/hennef/henneferin-segelt-zur-klimakonferenz--revolutionen-fangen-immer-klein-an--32937630"
               target="_blank"><Image
                src="https://www.ksta.de/image/23419216/max/1920/1080/a480745cb278456928390eb2977f28f2/nU/headerrhein-sieg.png"/></a>
            <Header>Henneferin segelt zur Klimakonferenz „Revolutionen fangen immer klein an“</Header>
            <a href="https://www.ksta.de/region/rhein-sieg-bonn/hennef/henneferin-segelt-zur-klimakonferenz--revolutionen-fangen-immer-klein-an--32937630"
               target="_blank">ksta.de</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.ebs.edu/en/news/un-climate-change-conference"
               target="_blank"><Image
                src="https://assets.ebs.edu/assets/ebs-universitaet-passion-with-purpose-logo-weiss-1e0478aabddc06aec497f2fb0884580d.png"
                style={{backgroundColor: '#004d88', padding: '6px'}}/></a>
            <Header>Sustainable travel: EBS student Clara von Glasow will be sailing to the un climate change conference
                in Chile</Header>
            <a href="https://www.ebs.edu/en/news/un-climate-change-conference"
               target="_blank">ebs.de</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.oneworld.nl/powerswitch/wat-jongeren-ons-over-het-klimaat-leren/"
               target="_blank"><Image
                src="/resources/images/OneWorld.jpg"/></a>
            <Header>Wat jongeren ons over het klimaat leren</Header>
            <a href="https://www.oneworld.nl/powerswitch/wat-jongeren-ons-over-het-klimaat-leren/"
               target="_blank">oneworld.nl</a>
        </Grid.Column>

    </Grid.Row>
    <Grid.Row centered key="pub5" stretched>

        <Grid.Column width={3} stretched>
            <a href="https://www.jetzt.de/umwelt/clara-von-glasow-segelt-mit-sail-to-the-cop-zur-klimakonferenz-nach-chile"
               target="_blank"><Image
                src="/resources/images/Jetzt.png"/></a>
            <Header>„Die Menschheit muss sich verändern, um auf diesem Planeten leben zu können“</Header>
            <a href="https://www.jetzt.de/umwelt/clara-von-glasow-segelt-mit-sail-to-the-cop-zur-klimakonferenz-nach-chile"
               target="_blank">jetzt.de</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.yacht.de/aktuell/panorama/auch-deutsche-klimaaktivisten-segeln-ueber-den-atlantik/a121951.html"
               target="_blank"><Image
                src="https://www.yacht.de/typo3conf/sys/images/logo.png"/></a>
            <Header>Auch deutsche Klimaaktivisten segeln über den Atlantik</Header>
            <a href="https://www.yacht.de/aktuell/panorama/auch-deutsche-klimaaktivisten-segeln-ueber-den-atlantik/a121951.html"
               target="_blank">yacht.de</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.faz.net/aktuell/rhein-main/frankfurt/wieso-eine-studentin-nicht-zur-klimakonferenz-fliegt-16330572.html"
               target="_blank"><Image
                src="/resources/images/FrankfurterAllgemeine.jpg"/></a>
            <Header>Auch Wiesbadener Studentin segelt zu Klimakonferenz in Chile</Header>
            <a href="https://www.faz.net/aktuell/rhein-main/frankfurt/wieso-eine-studentin-nicht-zur-klimakonferenz-fliegt-16330572.html"
               target="_blank">faz.net</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.zeit.de/campus/2019-08/seefahrt-uebersee-greta-thunberg-tipps#wie-sollte-man-sich-an-bord-verhalten-wenn-man-keine-ahnung-vom-segeln-hat"
               target="_blank"><Image
                src="/resources/images/ZeitCampus.png"/></a>
            <Header>"Nicht jeder hängt sofort über der reling"</Header>
            <a href="https://www.zeit.de/campus/2019-08/seefahrt-uebersee-greta-thunberg-tipps#wie-sollte-man-sich-an-bord-verhalten-wenn-man-keine-ahnung-vom-segeln-hat"
               target="_blank">zeit.de</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.mo.be/nieuws/sail-cop-anuna-en-ad-la-de-zeilen-naar-klimaattop-chili"
               target="_blank"><Image
                src="/resources/images/Mo.png"/></a>
            <Header>Sail to the COP: Anuna en Adélaïde zeilen naar klimaattop in Chili</Header>
            <a href="https://www.mo.be/nieuws/sail-cop-anuna-en-ad-la-de-zeilen-naar-klimaattop-chili"
               target="_blank">mo.be</a>
        </Grid.Column>
    </Grid.Row>

    <Grid.Row centered key="pub6" stretched>

        <Grid.Column width={3} stretched>
            <a href="https://www.standaard.be/cnt/dmf20190822_04570893"
               target="_blank"><Image
                src="https://dsofront.akamaized.net/extra/assets/img/de-standaard.svg?v=20190823T213401"/></a>
            <Header>Ook Anuna De Wever neemt zeilschip naar klimaattop</Header>
            <a href="https://www.standaard.be/cnt/dmf20190822_04570893"
               target="_blank">standaard.be</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.vrt.be/vrtnws/nl/2019/08/22/ook-anuna-de-wever-neemt-zeilschip-voor-reis-naar-klimaattop-in/"
               target="_blank"><Image
                src="/resources/images/VRT.png"/></a>
            <Header>Ook Anuna De Wever neemt zeilschip voor reis naar klimaattop in Chili</Header>
            <a href="https://www.vrt.be/vrtnws/nl/2019/08/22/ook-anuna-de-wever-neemt-zeilschip-voor-reis-naar-klimaattop-in/"
               target="_blank">vrt.be</a>
        </Grid.Column>


        <Grid.Column width={3} stretched>
            <a href="https://www.brusselstimes.com/all-news/belgium-all-news/64941/belgian-youth-climate-leaders-to-sail-to-climate-summit-in-chile/"
               target="_blank"><Image
                src="https://fsyv2fdkkw2xd3knut4uab98-wpengine.netdna-ssl.com/wp-content/uploads/2019/07/Brussels-Times-Logo-574.png"/></a>
            <Header>Belgian youth climate leader to sail to climate summit in Chile</Header>
            <a href="https://www.brusselstimes.com/all-news/belgium-all-news/64941/belgian-youth-climate-leaders-to-sail-to-climate-summit-in-chile/"
               target="_blank">brusselstimes.com</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.lesoir.be/243396/article/2019-08-22/climat-anuna-de-wever-et-adelaide-charlier-iront-en-voilier-jusquau-chili-pour"
               target="_blank"><Image
                src="https://www.lesoir.be/sites/all/themes/engbootstrap_lesoir/images/lesoir-be.svg"
                style={{backgroundColor: '#003978', padding: '10px'}}/></a>
            <Header>Climat: Anuna De Wever et Adélaïde Charlier iront en voilier jusqu’au Chili pour la COP 25</Header>
            <a href="https://www.lesoir.be/243396/article/2019-08-22/climat-anuna-de-wever-et-adelaide-charlier-iront-en-voilier-jusquau-chili-pour"
               target="_blank">lesoir.be</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.grenzecho.net/19713/artikel/2019-08-22/anuna-de-wever-und-adelaide-charlier-segeln-nach-chile-zur-un-klimakonferenz"
               target="_blank"><Image
                src="https://www.grenzecho.net/sites/all/themes/engbootstrap_ge/images/logo.svg"
                style={{backgroundColor: '#043556', padding: '10px'}}/></a>
            <Header>Anuna De Wever und Adélaïde Charlier segeln nach Chile zur UN-Klimakonferenz</Header>
            <a href="https://www.grenzecho.net/19713/artikel/2019-08-22/anuna-de-wever-und-adelaide-charlier-segeln-nach-chile-zur-un-klimakonferenz"
               target="_blank">grenzecho.net</a>
        </Grid.Column>
    </Grid.Row>

    <Grid.Row centered key="pub7" stretched>

        {/*<Grid.Column width={3} stretched>*/}
        {/*    <a href="https://unfccc-sb50.streamworld.de/webcast/avd-youth4climate-and-citizen-empowerment?fbclid=IwAR1Q0bN7Judqrlwzo2l4PkThqATTFAcJePg7ddhLcYlzsbDJh8YUl-m83DU"*/}
        {/*       target="_blank"><Image*/}
        {/*        src="/resources/images/UNFCCC.jpg"/></a>*/}
        {/*    <Header>Press conference at UNFCCC SB50 in Bonn</Header>*/}
        {/*    <a href="https://unfccc-sb50.streamworld.de/webcast/avd-youth4climate-and-citizen-empowerment?fbclid=IwAR1Q0bN7Judqrlwzo2l4PkThqATTFAcJePg7ddhLcYlzsbDJh8YUl-m83DU"*/}
        {/*       target="_blank">unfccc-sb50.streamworld.de</a>*/}
        {/*</Grid.Column>*/}

        <Grid.Column width={3} stretched>
            <a href="https://www.hetkanwel.nl/2019/08/26/reisindustrie-veranderen/"
               target="_blank"><Image
                src="https://www.hetkanwel.nl/wp-content/uploads/2019/01/V2_Lichtgrijs_HKW.png?x65584"/></a>
            <Header>Deze jongeren willen de reisindustrie drastisch veranderen</Header>
            <a href="https://www.hetkanwel.nl/2019/08/26/reisindustrie-veranderen/"
               target="_blank">hetkanwel.nl</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://generationengerechtigkeit.info/volle-windkraft-voraus-im-dreimaster-zur-un-klimakonferenz-nach-chile/"
               target="_blank"><Image
                src="/resources/images/generationengerechtigkeit.jpg"/></a>
            <Header>Volle Windkraft voraus: Im Dreimaster zur UN-Klimakonferenz nach Chile</Header>
            <a href="https://generationengerechtigkeit.info/volle-windkraft-voraus-im-dreimaster-zur-un-klimakonferenz-nach-chile/"
               target="_blank">generationengerechtigkeit.info</a>
        </Grid.Column>

        <Grid.Column width={3} stretched>
            <a href="https://www.madmoizelle.com/sail-to-the-cop25-1020050"
               target="_blank"><Image  style={{backgroundColor: '#2a2e31', padding: '10px'}}
                src="https://static.mmzstatic.com/wp-content/themes/unicorn/assets/img/logo-madmoizelle.svg"/></a>
            <Header>Je fais partie des 36 jeunes qui se rendent à la COP25… en voilier !</Header>
            <a href="https://www.madmoizelle.com/sail-to-the-cop25-1020050"
               target="_blank">madmoizelle.com</a>
        </Grid.Column>


    </Grid.Row>
</Grid>;

function Share() {

    let images = [
        "Sail to the COP - Logo.png",
        "Sail to the COP - Logo white.png",
        "Sail to the COP - Map 2.jpg",
        "Sail to the COP - Map 3.png",
        "Sail to the COP - Crowdfunding Launched.jpg",
        "Sail to the COP - Long.png",
        "Sail to the COP - Future of Travel.jpg",
        "Sail to the COP - Regina Maris.jpg",
        "Regina Maris 2.jpg",
        "Regina Maris 3.jpg",
        "Sail to the COP Team.jpg",
        "Sail to the COP - Train.jpg",
        "Sail to the COP - Lena Hartog.jpg",
        "Sail to the COP - Mara de Pater.jpg",
        "Sail to the COP - Moon Weijens.jpg",
        "Sail to the COP - Jeppe Bijker.jpg",
    ];

    let photos = [
        "55447004_590012131477236_2063544336175333376_o.jpg",
        "47583854_590251874730875_8634368677895708860_n.jpg",
    ];


    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container">


                    {/*<div className="row">*/}
                    {/*    <div className="column sixteen wide" style={{textAlign: "center"}}>*/}
                    {/*        <h1>Share</h1>*/}

                    {/*        <Card style={{margin: "0 auto"}}>*/}
                    {/*            <Card.Content>*/}
                    {/*                <a href="https://www.sailtothecop.com/resources/Sail to the COP International Press Release 31-7-2019.pdf"*/}
                    {/*                   target="_blank">*/}
                    {/*                    <Image src="/resources/images/Press-Release-30-7-2019.png" size="tiny"*/}
                    {/*                           floated="left"/>*/}
                    {/*                    <strong>36 young activists join Greta Thunberg in the no-fly movement*/}
                    {/*                        by sailing to the global climate conference (COP25)</strong>*/}
                    {/*                </a><br/>*/}
                    {/*                Press release 31 July 2019*/}
                    {/*            </Card.Content>*/}
                    {/*        </Card>*/}


                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row">
                        <div className="column eight wide">
                            <ShareTool/>
                        </div>

                        <div className="column eight wide">
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header>
                                        <h2>Texts to share</h2>
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <Tab panes={[
                                        {
                                            menuItem: 'English', render: () => <Tab.Pane>
                                                Sail to the COP sailed across the Atlantic Ocean to call for a fair and sustainable future of travel - help them get their message to the relocated COP in Madrid. Go to{" "}
                                                <a href="https://www.sailtothecop.com"
                                                   target="_blank">https://www.sailtothecop.com</a> 💪
                                            </Tab.Pane>
                                            // menuItem: 'English', render: () => <Tab.Pane>
                                            //     Sail to the COP will sail 10.000km ⛵️ to the UN Climate Conference in
                                            //     Chile, for a fair and sustainable future of travel! 🌏<br/>
                                            //     Read more about the journey and the reason on
                                            //     <a href="https://www.sailtothecop.com"
                                            //        target="_blank">https://www.sailtothecop.com</a> 💪
                                            // </Tab.Pane>
                                        },
                                        {
                                            menuItem: 'Nederlands', render: () => <Tab.Pane>
                                                Sail to the COP is de Atlantische oceaan overgestoken om aandacht te vragen voor een eerlijke en duurzame toekomst van reizen! Help hun verhaal naar de verplaatste COP in Madrid te brengen. Kijk op{" "}
                                                <a href="https://www.sailtothecop.com"
                                                   target="_blank">https://www.sailtothecop.com</a> 💪
                                            </Tab.Pane>
                                        },
                                    ]}/>
                                </Card.Content>
                            </Card>
                        </div>
                    </div>

                    {/*<div className="row">*/}
                    {/*    <div className="column sixteen wide">*/}
                    {/*        <h1>Publicity</h1>*/}


                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

                {/*<Publicity/>*/}

                <div className="ui top aligned stackable grid container">


                    <div className="row">
                        <div className="column sixteen wide center aligned">
                            <h1>Media</h1>
                            <p>
                                All images are free to use and distribute, when linking to <a
                                href="https://www.sailtothecop.com" target="_blank">sailtothecop.com</a> Questions or
                                specific requests? <Link to="/project/contact">Contact us</Link>!
                            </p>
                            <p>Facebook page: <a href="https://www.facebook.com/sailtothecop"
                                                 target="_blank">https://www.facebook.com/sailtothecop</a></p>
                            <p>
                                Interested in media coverage we have had with former projects? <Link
                                to="/project/about/#media">See our about page</Link>.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        {images.map((image, i) => <div className="column four wide" key={"image" + i}>
                            <Card style={{marginBottom: '10px'}}>
                                <a href={"resources/images/share/" + image} target="_blank">
                                    <Image className={i === 1 ? ' transparent' : ''}
                                           src={"resources/images/share/" + image}/>
                                </a>
                                <Card.Content>
                                    <Label size="tiny" as="a" href={"resources/images/share/" + image} target="_blank"
                                           download>Download</Label>
                                </Card.Content>
                            </Card>
                        </div>)}
                    </div>
                    <div className="row">
                        {photos.map((image, i) => <div className="column four wide" key={"image" + i}>
                            <Card style={{marginBottom: '10px'}}>
                                <a href={"resources/images/" + image} target="_blank"><Image
                                    src={"resources/images/" + image}/></a>
                                <Card.Content>
                                    <Label size="tiny" as="a" href={"resources/images/" + image} target="_blank"
                                           download>Download</Label>
                                </Card.Content>
                            </Card>
                        </div>)}
                    </div>


                </div>
            </div>

        </div>
    );
}

export default Share;

import React from 'react';
import {Link} from "react-router-dom";

function BecomePartner() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column fourteen wide">
                            <h1>Sail with us towards a sustainable future of traveling</h1><br/>
                            <p>
                                Together with partners we want to show that the concerns about aviation are widespread
                                and to pressure international decision-makers to discuss this topic. We’ve already
                                partnered with a number of organizations that support our cause and we would love to
                                expand our network by building strong partnerships with organisations that share our
                                concerns.
                            </p>

                            <h2>How can you join?</h2>
                            <p>
                                We want to work together with organizations that have an interest in building a
                                sustainable travel industry.
                            </p>
                            <p>

                                <ul>
                                    <li>Have a strong affinity with the topic of sustainable travel and mobility</li>
                                    <li>Share our understanding of climate change as a crisis and our vision of low
                                        carbon
                                        traveling
                                    </li>
                                    <li>Want to support the realisation of the vision and solutions</li>
                                </ul>
                            </p>
                            <p>
                                There are a few options to join our cause:
                                <ul>

                                    <li>By sharing expertise through sessions organized prior to setting sail</li>
                                    <li>By having one of your young employees participate on board</li>
                                    <li>As theme partner, we will take a question or challenge of your company to tackle
                                        on our on-board think tank.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Are you curious what partnership can mean to your organization? <Link to="/contact">Contact us!</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column fourteen wide center aligned">
                            <h1>Become a partner</h1>
                            <p>
                                Are you curious what partnership can mean to your organization? <Link
                                to="/project/contact">Contact us</Link>
                            </p>
                            <p/>
                        </div>
                        <div className="twelve wide column center aligned">
                            <a href="mailto:info@sailtothecop.com" className="ui button basic primary non-capitalized">
                                <i className="ui icon mail outline"></i>
                                info@sailtothecop.com
                            </a>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <a href="tel:0031633149435" className="ui button basic primary">
                                <i className="ui icon phone"></i>
                                +31 6 33 14 94 35
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default BecomePartner;

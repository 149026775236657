import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './resources/responsive.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/database';
import {LocalizeProvider} from "react-localize-redux";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyDUaMgS3bcLMqZhtdINPF0eqF9UpAXV_a4",
    authDomain: "youth-for-sustainable-travel.firebaseapp.com",
    databaseURL: "https://youth-for-sustainable-travel.firebaseio.com",
    projectId: "youth-for-sustainable-travel",
    storageBucket: "youth-for-sustainable-travel.appspot.com",
    messagingSenderId: "711820598218"
};
firebase.initializeApp(config);

// window.db = firebase.firestore();
// window.db.settings({
//     timestampsInSnapshots: true
// });


ReactDOM.render(
    <LocalizeProvider><App /></LocalizeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const FundingTimeline = ({value, total}) => <div className="timeline crowdfunding">
    <div className="line"></div>
    <div className="gradient" />
    <div className="point-container end">
        <div className="point"></div>
        <div className="description">€ 5.000</div>
    </div>
    <div className="point-container current"
         style={{left: parseInt(value / total * 100, 10) + "%"}}>
        <div className="icon ship"></div>
        <div className="point"></div>
        <div className="description"><span className="value">
                                        {formatter.format(value)}
                                    </span></div>
    </div>
</div>;

// export const FundingTimeline = ({value, total}) => <div className="timeline crowdfunding">
//     <div className="line"></div>
//     <div className="point-container start">
//         <div className="point"></div>
//         <div className="description">€ 0</div>
//     </div>
//     <div className="point-container threshold"
//          style={{left: parseInt(10000 / total * 100, 10) + "%"}}>
//         <div className="point"></div>
//         <div className="description">€ 10.000</div>
//     </div>
//     <div className="point-container threshold"
//          style={{left: parseInt(20000 / total * 100, 10) + "%"}}>
//         <div className="point"></div>
//         <div className="description">€ 20.000</div>
//     </div>
//     <div className="point-container end">
//         <div className="point"></div>
//         <div className="description">€ 30.000</div>
//     </div>
//     <div className="point-container current"
//          style={{left: parseInt(value / total * 100, 10) + "%"}}>
//         <div className="icon ship"></div>
//         <div className="point"></div>
//         <div className="description"><span className="value">
//                                         {formatter.format(value)}
//                                     </span></div>
//     </div>
// </div>;
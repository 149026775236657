import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Button, Card, Form, Header, Icon, Input, Loader, Message} from "semantic-ui-react";
import {FundingTimeline} from "./funding-timeline";
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton} from "react-share";
import {Link} from "react-router-dom";

class ShareTool extends React.Component {

    state = {
        quote: "I've supported the Sail to the COP crowdfunding campaign - to help bring their message to Madrid, calling for a fair and sustainable future of travel.",
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        const {quote} = this.state;

        const url = "https://www.sailtothecop.com";
        let hashtag = "#sailtothecop";

        return (

            <Card fluid>
                <Card.Content>
                    <Card.Header>
                        <h2>Share Sail to the COP</h2>
                    </Card.Header>
                </Card.Content>
                <Card.Content>
                    <p><a href={url} target="_blank">{url}</a></p>
                    <div className="ui form">
                        <div className="field">
                                                <textarea value={quote} onChange={(e) => this.handleChange(e)}
                                                          name="quote" style={{height: '100px'}}/>
                        </div>
                    </div>
                    <br/>

                    <FacebookShareButton url={url} quote={quote} hashtag={hashtag}>
                        <Button color='facebook' size="small">
                            <Icon name='facebook'/> Facebook</Button>
                    </FacebookShareButton>
                    <TwitterShareButton url={url} hashtags={["ikreisanders"]}>
                        <Button color='twitter' size="small">
                            <Icon name='twitter'/> Twitter</Button>
                    </TwitterShareButton>
                    <LinkedinShareButton url={url} description={quote}>
                        <Button color='linkedin' size="small">
                            <Icon name='linkedin'/> LinkedIn</Button>
                    </LinkedinShareButton>
                    <EmailShareButton url={url} body={quote + "\n\n" + url}>
                        <Button size="small">
                            <Icon name='mail'/> E-mail
                        </Button>
                    </EmailShareButton>
                </Card.Content>
            </Card>


        );
    }
}

export default ShareTool;

import React, {Component} from 'react';
import {NavLink, Route, BrowserRouter as Router, withRouter, Switch, Link, Redirect} from "react-router-dom";
import {renderToStaticMarkup} from "react-dom/server";
import Home from "./pages/homeOld";
import Support from "./pages/support";
import Reason from "./pages/project/reason";
import Journey from "./pages/project/journey";
import Contact from "./pages/project/contact";
import About from "./pages/project/about";
import Join from "./pages/join/join";
import Selection from "./pages/join/selection";
import Participants from "./pages/join/participants";
import ParticipantsList from "./pages/project/participants";
import Partners from "./pages/partners/partners";
import BecomePartner from "./pages/partners/become-partner";
import Ambassadors from "./pages/partners/ambassadors";
import Apply from "./pages/join/apply";
import Crowdfunding from "./pages/support";
import CrowdfundingPayment from "./pages/support/crowdfunding-payment";
import Donate from "./pages/support/donate";
import Blog from "./pages/blog";
import BlogArticle from "./pages/blog/article";
import spanish from "./resources/translations/es.json"

// import CrowdfundingPreview from "./pages/support/crowdfundingPreview";
import Act from "./pages/support/act";
import Share from "./pages/support/share";
import FAQ from "./pages/project/faq";
import Project from "./pages/project/project";
import Payment from "./pages/support/payment";
import PrivacyPolicy from "./pages/privacy-policy";
import ShowSupporters from "./pages/show-supporters";
import {LocalizeProvider, Translate, withLocalize} from "react-localize-redux";
import {Dropdown, Menu} from "semantic-ui-react";
import Tracker from "./pages/project/tracker";
import Railtothecop from "./pages/railtothecop";
import Output from "./pages/output";
import OutputVision from "./pages/output/vision";
import OutputProblem from "./pages/output/problem";

class ScrollToTopComponent extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

const crowdfunding = false;

const ScrollToTop = withRouter(ScrollToTopComponent);
const ProjectMenu = () => <ul className="menu topics">
{/*//     <li>*/}
{/*//         <NavLink to="/tracker" exact>Tracker</NavLink>*/}
{/*//     </li>*/}
    <li>
        <NavLink to="/project" exact><Translate id="menu.reason">The reason</Translate></NavLink>
    </li>
    <li>
        <NavLink to="/project/journey">The journey</NavLink>
    </li>
    {/*<li>*/}
    {/*    <NavLink to="/project/message">Our message</NavLink>*/}
    {/*</li>*/}
    <li>
        <NavLink to="/project/participants">Participants</NavLink>
    </li>
    <li>
        <NavLink to="/project/about">About us</NavLink>
    </li>
    {/*<li>*/}
    {/*    <NavLink to="/support/share">Media</NavLink>*/}
    {/*</li>*/}
    {/*<li>*/}
    {/*    <NavLink to="/project/faq">FAQ</NavLink>*/}
    {/*</li>*/}
    <li>
        <NavLink to="/project/contact">Contact</NavLink>
    </li>
</ul>;


class App extends React.Component {

    state = {
        crowdfundingButtonVisible: false,
    };

    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                {name: "English", code: "en"},
                {name: "Spanish", code: "es"}
            ],
            options: {
                renderToStaticMarkup,
                defaultLanguage: "en",
            }
        });


        this.props.addTranslationForLanguage(spanish, "es");
        // this.props.setActiveLanguage("es");

        if (window.location.search.substr(1) === "es") {
            this.props.setActiveLanguage("es");
        }

    }

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll.bind(this));
    // }
    //
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll.bind(this));
    // }

    // handleScroll() {
    //     if (this.state.crowdfundingButtonVisible) return;
    //     if (window.scrollY > window.innerHeight) {
    //         window.removeEventListener('scroll', this.handleScroll.bind(this));
    //         this.setState({crowdfundingButtonVisible: true});
    //     }
    // }


    // componentDidMount() {
    //
    //     if (!window.ScrollMagic) return;
    //
    //     try {
    //
    //
    //         const controller = new window.ScrollMagic.Controller({globalSceneOptions: {duration: 100}});
    //
    //         // build scenes
    //         new window.ScrollMagic.Scene({triggerElement: "#home-content-container"})
    //             .set(this.crowdfundingButtonRef, {opacity: 0.5})
    //             .addTo(controller);
    //
    //     } catch {}
    //
    // }

    changeLanguage(e, info) {

        let selectedLanguage = info.value;
        let languages = ["en", "es"];
        this.props.setActiveLanguage(languages[selectedLanguage]);
    }

    render() {

        let languages = {
            en: "🇬🇧",
            es: "🇪🇸",
        };
        let languageValues = {
            en: 0,
            es: 1,
        };

        const activeLanguage = this.props.activeLanguage ? languages[this.props.activeLanguage.code] : "";
        const activeLanguageValue = this.props.activeLanguage ? languageValues[this.props.activeLanguage.code] : 0;

        const languageOptions = [
            {key: 0, text: '🇬🇧 English', value: 0},
            {key: 1, text: '🇪🇸 Español', value: 1},
            // { key: 3, text: '', value: 3 },
        ];

        return (
            <Router>
                <ScrollToTop>

                    {/*<Switch>*/}
                    {/*    <Route path="/output" component={Output} />*/}
                    {/*    <Route path="/" component={() =>*/}
                    {/*        <>*/}
                                <div className="menu-container">
                                    <div className="logo">
                                        {(window.location.hostname.indexOf("railtothecop.com") !== -1) ?
                                            <a href={(window.location.hostname.indexOf("railtothecop.com") !== -1) ? "https://www.sailtothecop.com" : "/"}>
                                                <img src="resources/images/logo-sailtocop-white.png"
                                                     alt="Sail to the COP"/>
                                            </a> : <NavLink to={"/"}>
                                                <img src="resources/images/logo-sailtocop-white.png"
                                                     alt="Sail to the COP"/>
                                            </NavLink>
                                        }
                                            </div>

                                    {/*{<Route path="/"*/}
                                    {/*        component={({match, location}) => !(location && location.pathname === "/support") && //!match.isExact &&*/}
                                    {/*            <div*/}
                                    {/*                className={"tablet or lower hidden crowdfunding-button"}*/}
                                    {/*                id="crowdfunding-button">*/}

                                    {/*                <Link to="/support"*/}
                                    {/*                      className={"ui button primary"}>Crowdfunding</Link>*/}

                                    {/*                /!*<Menu compact size="small" style={{marginLeft: '10px', marginTop: '-4px'}}>*!/*/}
                                    {/*                /!*    <Dropdown text={activeLanguage} options={languageOptions} value={activeLanguageValue} simple item direction="left" onChange={this.changeLanguage.bind(this)} />*!/*/}
                                    {/*                /!*</Menu>*!/*/}

                                    {/*            </div>}/>}*/}

                                    <ul className="menu actions">
                                        <li>
                                            <NavLink to="/project">Sail to the COP</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/thinktank">Think Tank</NavLink>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <NavLink to="/support">Get involved</NavLink>*/}
                                        {/*</li>*/}
                                        <li>
                                            <NavLink to="/partners">Partners</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/blog">Blog</NavLink>
                                        </li>
                                    </ul>

                                    <Route path="/" exact component={ProjectMenu}/>
                                    <Route path="/project" component={ProjectMenu}/>
                                    <Route path="/admin" component={() => {
                                        window.location.href = "https://sttc-admin.web.app";
                                        return <a href="https://sttc-admin.web.app"
                                                  target="_blank">sttc-admin.web.app</a>
                                    }}/>

                                    <Route path="/join" component={() => <ul className="menu topics">
                                        <li>
                                            <NavLink to="/join" exact>For who</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/join/selection">Selection process</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/join/apply">Apply</NavLink>
                                        </li>
                                    </ul>}/>

                                    <Route path="/support" component={() => <ul className="menu topics">
                                        <li>
                                            <NavLink to="/support" exact>Support</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/support/share">Share</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/support/act">Act</NavLink>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <NavLink to="/support/share">Share</NavLink>*/}
                                        {/*</li>*/}
                                    </ul>}/>

                                    <Route path="/thinktank" component={() => <ul className="menu topics">
                                        <li>
                                            <NavLink to="/thinktank" exact>Overview</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/thinktank/vision">Vision</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/thinktank/problem">Problem Analysis</NavLink>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <NavLink to="/support/share">Share</NavLink>*/}
                                        {/*</li>*/}
                                    </ul>}/>

                                    <Route path="/partners" component={() => <ul className="menu topics">
                                        <li>
                                            <NavLink to="/partners" exact>Ambassadors</NavLink>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <NavLink to="/partners/join">Become a partner</NavLink>*/}
                                        {/*</li>*/}
                                    </ul>}/>

                                </div>

                                <div id="content-container">
                                    <Switch>

                                        <Route path="/" exact component={() => {
                                            return (window.location.hostname.indexOf("railtothecop.com") !== -1) ? <Railtothecop /> : <Home />;
                                        }}/>

                                        <Route path="/thinktank" exact component={Output}/>
                                        <Route path="/thinktank/vision" exact component={OutputVision}/>
                                        <Route path="/thinktank/problem" exact component={OutputProblem}/>

                                        <Route path="/project" exact component={Reason}/>
                                        <Route path="/tracker" exact component={Tracker}/>
                                        <Route path="/railtothecop" exact component={Railtothecop}/>
                                        <Route path="/project/reason" component={Reason}/>
                                        <Route path="/project/journey" component={Journey}/>
                                        <Route path="/project/participants" component={ParticipantsList}/>
                                        <Route path="/project/about" component={About}/>
                                        <Route path="/project/faq" component={FAQ}/>
                                        <Route path="/project/contact" component={Contact}/>

                                        <Route path="/join" exact component={Participants}/>
                                        <Route path="/join/participants" exact component={Participants}/>
                                        <Route path="/join/selection" exact component={Selection}/>
                                        <Route path="/join/apply" exact component={Apply}/>

                                        <Route path="/support" exact component={Crowdfunding}/>
                                        <Route path="/crowdfunding" exact component={Crowdfunding}/>
                                        <Route path="/support/crowdfunding" exact component={CrowdfundingPayment}/>
                                        <Route path="/support/crowdfunding/:reward" exact
                                               component={CrowdfundingPayment}/>
                                        <Route path="/support/donate" exact component={Donate}/>
                                        <Route path="/support/donate/:amount" exact component={Donate}/>
                                        <Route path="/support/act" exact component={Act}/>
                                        <Route path="/support/share" exact component={Share}/>
                                        <Route path="/support/payment/:checkoutId" exact component={Payment}/>

                                        <Route path="/partners" exact component={Ambassadors}/>
                                        <Route path="/partners/ambassadors" component={Ambassadors}/>
                                        <Route path="/partners/join" component={BecomePartner}/>

                                        <Route path="/privacy-policy" component={PrivacyPolicy}/>
                                        <Route path="/supporters" component={ShowSupporters}/>
                                        <Route path="/onepager" component={() => window.location = "/resources/SailtotheCOP_onepager_20191203.pdf"} />
                                        <Route path="/rttcwa" component={() => window.location = "https://chat.whatsapp.com/CG7Aeic8z2HAW007qZtyP4"} />

                                        <Route path="/blog" exact component={Blog}/>
                                        <Route path="/blog/:slug" exact component={BlogArticle}/>
                                    </Switch>
                                </div>


                                <div className="ui inverted vertical footer segment">
                                    <div className="ui container">
                                        <div className="ui stackable inverted divided equal height stackable grid">
                                            <div className="row">
                                                <div className="four wide column">
                                                    <ul className="menu topics">
                                                        <li className="part">
                                                            <NavLink to="/project">Sail to the COP</NavLink>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*    <NavLink to="/tracker">Tracker</NavLink>*/}
                                                        {/*</li>*/}
                                                        <li>
                                                            <NavLink to="/project/reason"><Translate id="menu.reason">The
                                                                reason</Translate></NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/project/journey">The journey</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/project/participants">Participants</NavLink>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*    <NavLink to="/project/message">Our message</NavLink>*/}
                                                        {/*</li>*/}
                                                        <li>
                                                            <NavLink to="/project/about">About us</NavLink>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*    <NavLink to="/support/share">Media</NavLink>*/}
                                                        {/*</li>*/}
                                                        {/*<li>*/}
                                                        {/*    <NavLink to="/project/faq">FAQ</NavLink>*/}
                                                        {/*</li>*/}
                                                        <li>
                                                            <NavLink to="/project/contact">Contact</NavLink>
                                                        </li>
                                                    </ul>
                                                </div>

                                                {/*<div className="four wide column">*/}
                                                {/*    <ul className="menu topics">*/}
                                                {/*        <li className="part">*/}
                                                {/*            <NavLink to="/join">Join the trip</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*        <li>*/}
                                                {/*            <NavLink to="/join/participants">For who</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*        <li>*/}
                                                {/*            <NavLink to="/join/journey">Selection process</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*        <li>*/}
                                                {/*            <NavLink to="/join/apply">Apply</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*    </ul>*/}
                                                {/*</div>*/}

                                                {/*<div className="four wide column">*/}
                                                {/*    <ul className="menu topics">*/}
                                                {/*        <li className="part">*/}
                                                {/*            <NavLink to="/support">Get involved</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*        <li>*/}
                                                {/*            <NavLink to="/support">Support</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*        <li>*/}
                                                {/*            <NavLink to="/support/share">Share</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*        <li>*/}
                                                {/*            <NavLink to="/support/act">Act</NavLink>*/}
                                                {/*        </li>*/}
                                                {/*        /!*<li>*!/*/}
                                                {/*        /!*    <NavLink to="/support/act">Act</NavLink>*!/*/}
                                                {/*        /!*</li>*!/*/}
                                                {/*        /!*<li>*!/*/}
                                                {/*        /!*    <NavLink to="/support/share">Share</NavLink>*!/*/}
                                                {/*        /!*</li>*!/*/}
                                                {/*    </ul>*/}
                                                {/*</div>*/}

                                                <div className="four wide column">
                                                    <ul className="menu topics">
                                                        <li className="part">
                                                            <NavLink to="/partners">Partners</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/partners/ambassadors">Ambassadors</NavLink>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*    <NavLink to="/partners/join">Become a partner</NavLink>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>

                                                <div className="four wide column">
                                                    <ul className="menu topics">
                                                        <li className="part">
                                                            <NavLink to="/blog">Blog</NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ui stackable inverted equal height stackable grid">
                                            <div className="row">
                                                <div className="eleven wide column">
                                                    <h2>Sail to the COP</h2>
                                                    <a href="http://www.yfst.org"><h5 className="ui inverted header"
                                                        style={{marginTop: 0, marginBottom: 0}}>
                                                        Stichting Youth for
                                                        Sustainable
                                                        Travel</h5></a>
                                                    <em>Chamber of Commerce The Netherlands, 73667234</em><br/><br/>
                                                    Amsterdam, The Netherlands<br/>
                                                    <a href="mailto:www.yfst.org">www.yfst.org</a><br/>
                                                    <a href="mailto:info@sailtothecop.com">info@sailtothecop.com</a><br/>
                                                    +31 6 33 14 94 35<br/><br/>
                                                    <small>Website by <a href="http://www.jeppebijker.nl"
                                                                         target="_blank">Jeppe
                                                        Bijker</a> / <a href="https://www.greentickets.app"
                                                                        target="_blank">Green
                                                        Tickets</a></small>
                                                </div>
                                                <div className="five wide column right aligned">

                                                    {/*Select language: &nbsp;&nbsp; <Dropdown text={activeLanguage}*/}
                                                    {/*                                        options={languageOptions}*/}
                                                    {/*                                        value={activeLanguageValue}*/}
                                                    {/*                                        simple item direction="left"*/}
                                                    {/*                                        onChange={this.changeLanguage.bind(this)}/>*/}
                                                    {/*<br/><br/>*/}

                                                    {/*{crowdfunding && <Route path="/"*/}
                                                    {/*                        component={({match, location}) => !(location && location.pathname === "/support") &&*/}
                                                    {/*                            <Link to="/support"*/}
                                                    {/*                                  className="ui button primary">Support our*/}
                                                    {/*                                Crowdfunding</Link>}/>}*/}
                                                    <br/><br/>

                                                    <a className="ui labeled icon button facebook"
                                                       href="https://www.facebook.com/pg/sailtothecop"
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i className='ui icon facebook'/> Follow us on Facebook
                                                    </a><br/><br/>
                                                    <a className="ui labeled icon button instagram"
                                                       href="https://www.instagram.com/sailtothecop/"
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i className='ui icon instagram'/> Follow us on Instagram
                                                    </a><br/><br/>

                                                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/*</>*/}
                        {/*}/>*/}
                    {/**/}
                    {/*</Switch>*/}


                </ScrollToTop>
            </Router>
        );
    }
}

export default withLocalize(App);

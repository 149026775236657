import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Button, Card, Form, Header, Icon, Input, Loader, Message} from "semantic-ui-react";
import {FundingTimeline} from "./funding-timeline";
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton} from "react-share";
import {Link} from "react-router-dom";
import ShareTool from "./share-tool";

class Payment extends React.Component {

    state = {
        info: {},
        loading: true,
        processing: false,
        value: 0,
        total: 25000,
        canceled: false,
    };

    componentDidMount() {

        let _this = this;

        let ref = firebase.database().ref('crowdfunding');
        ref.on('value', function (snapshot) {
            let funding = snapshot.val();
            _this.setState({
                value: funding.value,
                loading: false,
            })
        });

        this.checkStatus(this.props);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        this.checkStatus(nextProps);

    }


    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    checkStatus(props) {

        let _this = this;
        const {checkoutId} = props.match.params;

        firebase.firestore().collection("checkouts")
            .doc(checkoutId).collection("payment")
            .doc("status").onSnapshot(doc => {
            let info = doc.data();

            _this.setState({
                info,
                loading: false,
                processing: info && info.processing,
            });

            if (info && info.completed) {

                _this.setState({
                    info,
                    completed: true,
                    processing: false,
                    success: info.success,
                    canceled: info.canceled,
                });

            } else if (info && info.failed) {

                _this.setState({
                    info,
                    completed: false,
                    processing: false,
                });
            }
        });

    }

    render() {
        const {value, total, loading, processing, success, completed, canceled} = this.state;

        return (
            <div className="sail">


                <div className="ui vertical stripe segment padded first colored-background">
                    <div className="ui top aligned stackable grid container centered">
                        <div className="row">
                            <div className="column sixteen wide">
                                <h1>Thank you!</h1>

                                <FundingTimeline value={value} total={total}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="column eight wide center aligned">
                                {loading ? <Loader active/> : <div>

                                    {processing ? <div>

                                        <p>Your payment is being processed...</p>
                                        <Loader active inline/>

                                    </div> : <div>

                                        {completed ? (success ? <div>

                                                <p><strong>Your payment was successful.</strong></p>
                                                <p>
                                                    Thanks for your support, this means a lot to us. Together we will
                                                    make sure the future of travel will be fair and sustainable for all!
                                                </p>
                                                <p/>

                                            </div> : (canceled ? <div>

                                                <p><strong>Your payment was canceled.</strong></p>


                                                <Link to="/support"
                                                      className="ui button primary">
                                                    <i className="ui icon arrow left"/>&nbsp;
                                                    Try Again
                                                </Link>

                                                <p/>

                                            </div> : <Message error>

                                                <p>Something seems to have gone wrong... <br/><br/>Please contact us
                                                    at <a
                                                        href="mailto:info@sailtothecop.com">info@sailtothecop.com</a>
                                                </p>

                                            </Message>)
                                        ) : <div>

                                            <p>Your payment information is being fetched...</p>
                                            <Loader active inline/>

                                        </div>
                                        }

                                    </div>}

                                </div>}

                            </div>
                        </div>


                        <div className="row">
                            <div className="column eight wide">
                                <ShareTool/>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default Payment;

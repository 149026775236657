import React, {Component} from 'react';
import {Label, Loader} from "semantic-ui-react";
import firebase from 'firebase/app';
import 'firebase/firestore';
import Markdown from "react-markdown";
import {Link} from "react-router-dom";

let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class BlogArticle extends React.Component {

    state = {
        loading: true,
        article: null,
        slug: null,
    };

    constructor(props) {
        super(props);

        const {slug} = props.match.params;
        if (slug) {
            this.state.slug = slug;
        }

    }

    componentDidMount() {
        const {slug} = this.state;
        this.fetchArticle(slug)
    }

    componentWillReceiveProps(nextProps) {

        const {slug} = nextProps.match.params;
        if (slug !== this.state.slug) {
            this.setState({slug});
            this.fetchArticle(slug)
        }

    }

    fetchArticle(slug) {
        let _this = this;
        let query = firebase.firestore().collection("articles");
        query.where("slug", "==", slug)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let article = doc.data();
                    article.id = doc.id;
                    article.date = new Date(doc.data().date.seconds * 1000);
                    // article.date = new Date(article.createdAt.seconds * 1000);
                    _this.setState({
                        article,
                        loading: false,
                    });
                });
            });
    }

    render() {

        const {loading, article} = this.state;
        const images = (article && article.images) || [];
        let imageUrls = images.map((image, nr) => "\n[image" + nr + "]: " + image.url + "\n");
        let fullContent = article && (article.content + imageUrls);

        return <div className="blogs">

                <div className="ui vertical segment first colored-background">
                    <div className="ui top aligned stackable grid container centered">
                        <div className="row">
                            <div className="column sixteen wide center aligned">
                                {/*<h1>Blog</h1>*/}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="ui vertical stripe segment first colored-background" style={{clear: "both"}}>
                    {loading ?
                        <div className="ui top aligned stackable grid container centered">
                            <div className="row" style={{height: '200px'}}>

                                <Loader active={loading}/>
                            </div>
                        </div> :
                        <div className="ui top aligned stackable grid container centered">
                            <div className="row">
                                <div className="column fourteen wide center aligned">
                                    <h1>{article.title}</h1>
                                    <Label size="large"
                                        basic>{article.date.getDate()} {months[article.date.getMonth()]} {article.date.getFullYear()}</Label>
                                    <Label size="large" basic>{article.author}</Label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column fourteen wide left aligned">

                                    <Markdown source={fullContent} className={"markdown-container"}
                                              linkTarget={"_blank"}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>

    }
}

export default BlogArticle;
